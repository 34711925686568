import { Component, OnInit, EventEmitter, Output, HostListener } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-restoration-phone',
  templateUrl: './restoration-phone.component.html',
  styleUrls: ['./restoration-phone.component.scss']
})
export class RestorationPhoneComponent implements OnInit {
  @Output() showRegistration: EventEmitter<void>;
  @Output() phoneNumberSet: EventEmitter<string>;

  phoneNumber: string;

  formBuilder: FormBuilder;
  formFields: FormGroup;
  formError: string;

  spinnerVisible: boolean;

  constructor(
    private http: HttpClient,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
    this.showRegistration = new EventEmitter();
    this.phoneNumberSet = new EventEmitter();
    const phoneNumber = this.activatedRoute.snapshot.queryParams.phoneNumber;
    this.formBuilder = new FormBuilder();
    this.formFields = this.formBuilder.group({
      phoneNumber: new FormControl(
        phoneNumber ? phoneNumber : null,
        [
          Validators.required,
          Validators.minLength(11),
          Validators.maxLength(12),
          Validators.pattern(/^(\+7|7|8)?\(?[489][0-9]{2}\)?[0-9]{3}[0-9]{2}[0-9]{2}$/)
        ]
      )
    // }, {
    //   validator: this.checkLogin
    });
    this.spinnerVisible = false;
  }

  ngOnInit() {
  }

  getCode() {
    if (this.formFields.invalid) {
      return;
    }

    this.spinnerVisible = true;

    this.formError = undefined;
    this.phoneNumber = this.formFields.get('phoneNumber').value;

    this.http.get(`/api/Account/ResetPassword/GetCode?phoneNumber=${this.phoneNumber}`)
      .toPromise()
      .then((response: any) => {
        this.spinnerVisible = false;
        this.phoneNumberSet.emit(this.phoneNumber);
      })
      .catch((errorResponse: any) => {
        this.spinnerVisible = false;
        this.formError = errorResponse.error ? errorResponse.error.errorMessage : errorResponse.errorMessage;
      });
  }

  registration() {
    this.showRegistration.emit();
  }

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (event.keyCode === 13) {
      this.getCode();
    }
  }

  private checkLogin(group: FormGroup) {
    const loginControl = group.controls.phoneNumber;

    const value: string = loginControl.value;

    if (!value) {
      return loginControl.setErrors({ required: true });
    }

    if (value.indexOf('+') > 0) {
      return loginControl.setErrors({ error: true });
    }

    if (value.replace('+', '').length !== 11) {
      return loginControl.setErrors({ error: true });
    }

    loginControl.setValidators([Validators.required, Validators.pattern('^[+0-9]+$')]);

    return loginControl.valid ? loginControl.setErrors(null) : loginControl.setErrors({ error: true });
  }

}
