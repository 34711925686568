import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import * as ApiModels from '../../../api';
import { WorkplaceService } from '../../../api';

@Component({
  selector: 'app-workplace-adding',
  templateUrl: './workplace-adding.component.html',
  styleUrls: ['./workplace-adding.component.scss']
})
export class WorkplaceAddingComponent implements OnInit {
  @Input() organisationId: string;
  @Input() workCenterId: string;
  @Input() areaId: string;
  @Output() closed: EventEmitter<string>;

  startFormVisible: boolean;
  countFormVisible: boolean;
  endFormVisible: boolean;

  formBuilder: FormBuilder;
  formFields: FormGroup;
  formError: string;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private workplaceService: WorkplaceService
  ) {
    this.closed = new EventEmitter();
  }

  ngOnInit() {
    this.formBuilder = new FormBuilder();
    this.formFields = this.formBuilder.group({
      count: new FormControl(null, [Validators.required]),
    });

    this.onShowStartForm();
  }

  onShowStartForm() {
    this.startFormVisible = true;
    this.countFormVisible = false;
    this.endFormVisible = false;
  }

  onShowCountForm() {
    this.startFormVisible = false;
    this.countFormVisible = true;
    this.endFormVisible = false;
  }

  onShowEndForm() {
    this.startFormVisible = false;
    this.countFormVisible = false;
    this.endFormVisible = true;
  }

  onAddWorkplaces(count: number) {
    if (!count) {
      count = this.formFields.get('count').value;
    }

    const workplacesAdd: ApiModels.WorkplaceForAreaAdd = {
      areaId: this.areaId,
      workplacesAmount: count
    };
    this.workplaceService.createWorkplacesForArea(workplacesAdd).toPromise().then(response => {
      this.onShowEndForm();
    });
    this.onShowEndForm();
  }

  onClickCloseButton() {
    this.closed.emit();
  }

  onClickAddNewAreaButton() {
    // this.router.navigateByUrl(`/profile/organisation/${this.organisationId}/workcenter/${this.workCenterId}/area/add`);
    const url = `/profile/organisation/${this.organisationId}/workcenter/${this.workCenterId}/area/add`;
    this.closed.emit(url);
  }

  onClickAddNewWorkCenterButton() {
    // this.router.navigateByUrl(`/profile/organisation/${this.organisationId}/workcenter/add`);
    const url = `/profile/organisation/${this.organisationId}/workcenter/add`;
    this.closed.emit(url);
  }

}
