import * as ApiModels from '../../api';
import { ScheduleGrouped, ScheduleGroupedNumber } from '../workcenter';

export class Utils {
  static getSchedulesGrouped(schedules: ApiModels.ScheduleInfo[]): ScheduleGrouped[] {
    const tempSchedules: ScheduleGrouped[] = [];

    schedules
      .sort((prev, next) => prev.dayOfWeek - next.dayOfWeek)
      .forEach((schedule, i) => {
        if (i === 0) {
          tempSchedules.push({ daysOfWeek: [schedule.dayOfWeek], startHour: schedule.startHour, endHour: schedule.endHour });
        } else {
          const lastTempSchedule = tempSchedules[tempSchedules.length - 1];
          if (
            ((schedule.dayOfWeek - 1) === lastTempSchedule.daysOfWeek[lastTempSchedule.daysOfWeek.length - 1]) &&
            schedule.startHour === lastTempSchedule.startHour &&
            schedule.endHour === lastTempSchedule.endHour
          ) {
            lastTempSchedule.daysOfWeek.push(schedule.dayOfWeek);
          } else {
            tempSchedules.push({ daysOfWeek: [schedule.dayOfWeek], startHour: schedule.startHour, endHour: schedule.endHour });
          }
        }
      });

    return tempSchedules;
  }
  static getSchedulesGroupedNumber(schedules: ApiModels.ScheduleInfo[]): ScheduleGroupedNumber[] {
    const tempSchedules: ScheduleGroupedNumber[] = [];

    schedules
      .sort((prev, next) => prev.dayOfWeek - next.dayOfWeek)
      .forEach((schedule, i) => {
        const startHour = +schedule.startHour.slice(0, 2);
        const endHour = +schedule.endHour.slice(0, 2);
        if (i === 0) {
          tempSchedules.push({ daysOfWeek: [schedule.dayOfWeek], times: [startHour, endHour] });
        } else {
          const lastTempSchedule = tempSchedules[tempSchedules.length - 1];
          if (
            ((schedule.dayOfWeek - 1) === lastTempSchedule.daysOfWeek[lastTempSchedule.daysOfWeek.length - 1]) &&
            startHour === lastTempSchedule.times[0] &&
            endHour === lastTempSchedule.times[1]
          ) {
            lastTempSchedule.daysOfWeek.push(schedule.dayOfWeek);
          } else {
            tempSchedules.push({ daysOfWeek: [schedule.dayOfWeek], times: [startHour, endHour] });
          }
        }
      });

    return tempSchedules;
  }

  static getSchedulesDisplayValue(schedules: ApiModels.ScheduleInfo[]): string[] {
    const tempSchedules = this.getSchedulesGrouped(schedules);
    const daysDictionary = ['ПН', 'ВТ', 'СР', 'ЧТ', 'ПТ', 'СБ', 'ВС'];
    const displayValues: string[] = [];

    tempSchedules.forEach(schedule => {
      let days: string;

      const daysOfWeek = schedule.daysOfWeek;
      if (daysOfWeek.length === 1) {
        days = daysDictionary[daysOfWeek[0] - 1]; // ПН
      } else if (daysOfWeek.length === 2) {
        days = daysOfWeek.map(item => daysDictionary[item - 1]).join(', '); // ПН, СР
      } else if (daysOfWeek.length > 2) {
        days = `${daysDictionary[daysOfWeek[0] - 1]} - ${daysDictionary[daysOfWeek[daysOfWeek.length - 1] - 1]}`;  // ПН - СР
      }

      const startHour = schedule.startHour.slice(0, -3);
      const endHour = schedule.endHour.slice(0, -3);

      displayValues.push(`${days} с ${startHour} до ${endHour}`);
    });

    return displayValues;
  }

  static mergeObject(object1: any, object2: any) {
    const resultObject: any = {};

    Object.keys(object1).forEach(key1 => {
      if (object2[key1] === undefined) {
        resultObject[key1] = object1[key1];
      } else if (typeof object2[key1] !== 'object') {
        resultObject[key1] = object2[key1];
      } else {
        resultObject[key1] = {};
        Object.keys(object1[key1]).forEach(key2 => {
          if (object2[key1][key2] === undefined) {
            resultObject[key1][key2] =  object1[key1][key2];
          } else {
            resultObject[key1][key2] = this.mergeObject(object1[key1][key2], object2[key1][key2]);
          }
        });
      }
    });

    return resultObject;
  }

  static getRandom(max: number = 1000000): number {
    return Math.floor(Math.random() * Math.floor(max));
  }
}
