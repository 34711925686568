import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { BreakpointObserver } from '@angular/cdk/layout';

import * as ApiModels from '../../../api';
import { UserService, OrganisationService } from '../../../api';

import { AuthService } from '../../auth';

declare const $: any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})

export class HeaderComponent implements OnInit {
  isHomePage: boolean;
  userLoggined: boolean;
  user: ApiModels.UserView;
  loginVisible: boolean;
  currentCity: ApiModels.City;

  registrationOrganisationOnly: boolean;

  constructor(
    public breakpointObserver: BreakpointObserver,
    private router: Router,
    private authService: AuthService,
    private userService: UserService,
    private organisationService: OrganisationService
  ) {
    this.registrationOrganisationOnly = false;
    this.loginVisible = false;
    // TODO
    this.currentCity = {
      id: '12535b0b-f292-42eb-bc29-c252871e6147',
      city: 'Москва'
    };
  }

  ngOnInit() {
    this.isLoggedIn();

    this.isHomePage = this.router.url === '/';
    this.router.events.subscribe(e => {
      if (e instanceof NavigationEnd) {
        this.isHomePage = e.url === '/';
      }
    });

    $('.ui.modal.registration-modal').modal({
      onHidden: () => {
        this.userLoggined = this.authService.isLoggedIn();
    }});
  }

  showLogin() {
    this.loginVisible = true;
    this.hideRegistration();
    $('.ui.modal.login-modal').modal('show');
  }

  hideLogin() {
    this.isLoggedIn();
    this.loginVisible = false;
    $('.ui.modal.login-modal').modal('hide');
  }

  sendLoginResult(value: boolean) {
    this.hideLogin();
    this.userLoggined = value;
  }

  logout() {
    this.authService.logout();
    this.userLoggined = false;
  }

  showCityPicker() {
    $('.ui.modal.city-picker-modal').modal('show');
  }

  hideCityPicker() {
    $('.ui.modal.city-picker-modal').modal('hide');
  }

  updateCurrentCity(city) {
    this.currentCity = city;
    this.hideCityPicker();
  }

  showRestoration() {
    this.hideLogin();
    $('.ui.modal.restoration-modal').modal('show');
  }

  hideRestoration() {
    $('.ui.modal.restoration-modal').modal('hide');
  }

  showRegistration() {
    this.hideLogin();
    $('.ui.modal.registration-modal').modal('show');
  }

  hideRegistration() {
    $('.ui.modal.registration-modal').modal('hide');
    this.registrationOrganisationOnly = false;
  }

  gotoOrganisationProfile() {
    if (!this.authService.isLoggedIn()) {
      return this.showLogin();
    }

    this.organisationService.getOrganisationByUserId().toPromise().then(response => {
      if (response.length) {
        this.router.navigate([ '/profile/organisation', response[0].id ]);
      } else {
        this.registrationOrganisationOnly = true;
        this.showRegistration();
      }
    });
  }

  onShowNavigationModal() {
    $('.ui.modal.mob-navigation-modal').modal('show');
  }

  onHideNavigationModal() {
    $('.ui.modal.mob-navigation-modal').modal('hide');
  }

  private isLoggedIn() {
    if (this.authService.isLoggedIn()) {
      this.userService.get().toPromise().then(user => {
        this.user = user;
        this.userLoggined = true;
      });
    } else {
      this.userLoggined = false;
    }
  }
}
