import { Component, OnInit, HostListener, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import * as ApiModels from '../../../../api';
import { OrganisationService, OrganisationTypeService } from '../../../../api';

declare const $: any;

@Component({
  selector: 'app-registration-organisation',
  templateUrl: './registration-organisation.component.html',
  styleUrls: ['./registration-organisation.component.scss']
})
export class RegistrationOrganisationComponent implements OnInit {
  @Input() organisationProfile: ApiModels.OrganisationAdd;
  @Input() registrationOnly: boolean;
  @Output() back: EventEmitter<void>;
  @Output() organisationSet: EventEmitter<ApiModels.OrganisationAdd>;
  @Output() showLogin: EventEmitter<void>;

  formBuilder: FormBuilder;
  formFields: FormGroup;
  formError: string;

  organisationTypes: ApiModels.OrganisationType[];

  constructor(
    private router: Router,
    private organisationService: OrganisationService,
    private organisationTypeService: OrganisationTypeService
  ) {
    this.back = new EventEmitter();
    this.organisationSet = new EventEmitter();
    this.showLogin = new EventEmitter();
  }

  ngOnInit() {
    setTimeout(() => {
      $('.ui.dropdown').dropdown();
      $('.ui.checkbox').checkbox();
    }, 0);

    this.getOrganisationTypes();
    this.formBuilder = new FormBuilder();
    this.formFields = this.formBuilder.group({
      organisationType: new FormControl(this.organisationProfile ? this.organisationProfile.organisationType : null, [Validators.required]),
      legalName: new FormControl(this.organisationProfile ? this.organisationProfile.legalName : null, [Validators.required]),
      numberINN: new FormControl(
        this.organisationProfile ? this.organisationProfile.numberINN : null,
        [Validators.required, Validators.pattern(/[0-9]/), Validators.minLength(10), Validators.maxLength(12)]
      ),
      contactPerson: new FormControl(this.organisationProfile ? this.organisationProfile.contactPerson : null, [Validators.required]),
      numberOGRN: new FormControl(this.organisationProfile ? this.organisationProfile.numberOGRN : null,
        [Validators.required, Validators.pattern(/[0-9]/), Validators.minLength(13), Validators.maxLength(15)]
      ),
      legalAddress: new FormControl(this.organisationProfile ? this.organisationProfile.legalAddress : null, [Validators.required]),
      agreement: new FormControl(false, Validators.required)
    });
  }

  private getOrganisationTypes() {
    this.organisationTypeService.getOrganisationTypeList().toPromise().then(response => {
      this.organisationTypes = response;
      if (this.organisationTypes) {
        this.formFields.controls.organisationType.setValue(this.organisationTypes[0].code);
      }
    });
  }

  onBackButtonClick() {
    this.back.emit();
  }

  onNextButtonClick() {
    if (this.formFields.invalid) {
      return;
    }

    this.setOrganisationProfile();
    this.organisationSet.emit(this.organisationProfile);
  }

  onRegisterButtonClick() {
    if (this.formFields.invalid) {
      return;
    }

    this.setOrganisationProfile();
    this.organisationService.createOrganisation(this.organisationProfile).toPromise().then(organisationResponse => {
      this.router.navigate([ '/profile/organisation', organisationResponse.id ]);
      this.organisationSet.emit(this.organisationProfile);
    }).catch(errorResponse => {
      this.formError = errorResponse.error ? errorResponse.error.errorMessage : errorResponse.errorMessage;
    });
  }

  onLoginShow() {
    this.showLogin.emit();
  }

  private setOrganisationProfile() {
    this.organisationProfile = {
      organisationType: this.formFields.get('organisationType').value,
      legalName: this.formFields.get('legalName').value,
      numberINN: this.formFields.get('numberINN').value,
      contactPerson: this.formFields.get('contactPerson').value,
      numberOGRN: this.formFields.get('numberOGRN').value,
      legalAddress: this.formFields.get('legalAddress').value
    };
  }
}
