import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MenuModule     } from '../shared/menu';
import { ReviewsModule  } from '../shared/reviews';

import { MyReviewsComponent } from './my-reviews.component';

@NgModule({
  declarations: [MyReviewsComponent],
  imports: [
    CommonModule,
    MenuModule,
    ReviewsModule
  ]
})
export class MyReviewsModule { }
