import { Component, OnInit, Input } from '@angular/core';

import { ReservationCardOptions} from '../reservation-card.model';

import * as ApiModels from '../../../../api';
import { RentService } from '../../../../api';

@Component({
  selector: 'app-reservation-cards',
  templateUrl: './reservation-cards.component.html',
  styleUrls: ['./reservation-cards.component.scss']
})
export class ReservationCardsComponent implements OnInit {
  @Input() width: number | string;
  @Input() items: ApiModels.RentCurrentWithWorkcenter[];
  @Input() options: ReservationCardOptions;

  top = 5;
  startFrom = 5;
  showMoreVisible = true;

  constructor(
    private rentService: RentService
  ) { }

  ngOnInit() {
    if (!this.width) {
      this.width = '100%';
    }
  }

  loadNextPage() {
    this.rentService.currentWithCenter(this.top, this.startFrom).toPromise().then(data => {
      data.forEach(card => {
        this.items.push(card);
      });
      if (data.length === 0) {
        this.showMoreVisible = false;
      }
      this.startFrom += this.top;
    });
  }
}
