import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { WorkplaceTypeService } from '../../../api';

import { WorkplaceTypesSelectComponent } from './workplace-types-select.component';

@NgModule({
  declarations: [WorkplaceTypesSelectComponent],
  imports: [
    CommonModule
  ],
  exports: [
    WorkplaceTypesSelectComponent
  ],
  providers: [
    WorkplaceTypeService
  ]
})
export class WorkplaceTypesSelectModule { }
