import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RatingModule } from '../rating';

import { MapComponent } from './map.component';

@NgModule({
  declarations: [MapComponent],
  imports: [
    CommonModule,
    RatingModule
  ],
  exports: [
    MapComponent
  ]
})
export class MapModule { }
