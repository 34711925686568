import { Component, Input, OnInit } from '@angular/core';
import { OwlOptions, SlidesOutputData } from 'ngx-owl-carousel-o';
import { BreakpointObserver } from '@angular/cdk/layout';

import * as ApiModels from '../../../api';
import { WorkplaceTypesInfoService } from '../../../api';

import { CarouselItem } from './custom-carousel.model';
import {
  advicesCarouselItems,
  articlesCarouselItems,
  masterClassesCarouselItems,
  objectGalleryCarouselItems,
  partnersClassesCarouselItems,
  recommendationsCarouselItems
} from './mocks';

@Component({
  selector: 'app-custom-carousel',
  templateUrl: './custom-carousel.component.html',
  styleUrls: ['./custom-carousel.component.scss']
})
export class CustomCarouselComponent implements OnInit {
  @Input() items: CarouselItem[] | string[] | ApiModels.WorkplaceTypesInfoWithWTName[];
  @Input() type: 'advices' | 'articles' | 'filters' | 'master-classes' |
                        'object-gallery' | 'partners' | 'recommendations';

  config: OwlOptions;
  isDragging: boolean;

  constructor(
    public breakpointObserver: BreakpointObserver,
    private workplaceTypesInfoService: WorkplaceTypesInfoService
  ) { }

  ngOnInit() {
    this.initConfig();
    this.initData();
  }

  getPassedData(data: SlidesOutputData) {
  }

  getInitData(data: SlidesOutputData) {
  }

  private initConfig() {
    this.config = {
      loop: false,
      autoHeight: false,
      autoWidth: true,
      margin: this.breakpointObserver.isMatched(['(min-width: 1024px)']) ? 40 : 20, // TODO не работает при изменении размера экрана
      mouseDrag: true,
      touchDrag: true,
      pullDrag: true,
      dots: false,
      nav: true,
      navText: ['<span class="nav-shadow"></span>', '<span class="nav-shadow"></span>'],
      responsive: {
        0:    { items: 1 },
        480:  { items: 3 },
        678:  { items: 4 },
      },
      center: false,
    };
  }

  // mocks
  private initData() {
    switch (this.type) {
      case ('advices') :
        this.items = advicesCarouselItems;
        break;
      case ('articles') :
        this.items = articlesCarouselItems;
        break;
      case ('master-classes') :
        this.items = masterClassesCarouselItems;
        break;
      case ('partners') :
        this.items = partnersClassesCarouselItems;
        break;
      case ('recommendations') :
        this.items = recommendationsCarouselItems;
        break;
      case ('filters') :
        this.workplaceTypesInfoService.getWorkplaceTypesInfoWithWTName().toPromise().then(response => {
          this.items = response.sort((prev, next) => prev.sortOrder - next.sortOrder);
        });
        break;
    }
  }
}
