import { Component, OnInit } from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';

declare const $: any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  constructor(
    public breakpointObserver: BreakpointObserver
  ) {}

  ngOnInit() {
    this.initTabs();
  }

  private initTabs() {
    $('.menu .item').tab();
  }

}
