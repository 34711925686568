import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { ReservationCardModule } from '../shared/reservation-card';

import { ReservationCancellationComponent } from './reservation-cancellation.component';

@NgModule({
  declarations: [ReservationCancellationComponent],
  imports: [
    CommonModule,
    RouterModule,
    ReservationCardModule
  ]
})
export class ReservationCancellationModule { }
