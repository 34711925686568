import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { formatDate } from '@angular/common';

import * as ApiModels from '../../api';

import { WorkCenterWithMinPrice } from '../shared/search-result';
import { WorkplaceType } from '../shared/workplace-types-select';

declare const $: any;

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {
  filters: ApiModels.SearchParamsForWC;
  filtersForMap: ApiModels.SearchParamsForWC;
  tempFilters: ApiModels.SearchParamsForWC;
  selectedFilters: ApiModels.SearchParamsForWC;
  searchResults: WorkCenterWithMinPrice[];
  pinResult: WorkCenterWithMinPrice;

  selectedWorkplaceTypes: WorkplaceType[];
  selectedWorkplaceTypeIds: string[];

  searchMapResultsVisible: boolean;
  searchPopupVisible: boolean;

  selectedFilters1: ApiModels.SearchParamsForWC;
  selectedFilters2: ApiModels.SearchParamsForWC;
  tempFilters1: ApiModels.SearchParamsForWC;
  tempFilters2: ApiModels.SearchParamsForWC;
  workplaceTypesSelectedCount: number;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {
    this.searchPopupVisible = false;
    this.tempFilters1 = {};
    this.tempFilters2 = {};
    // this.selectedFilters1 = {};
    // this.selectedFilters2 = {};
    // this.tempFilters = {
    //   workplaceTypes: [],
    //   date: null,
    //   timeFrom: null,
    //   timeTo: null
    // };
    // this.selectedFilters = { ...this.tempFilters };
    // this.filtersForMap = { ...this.tempFilters };
  }

  ngOnInit() {
    this.searchMapResultsVisible = true;
  }

  changeFilters(newFilters: ApiModels.SearchParamsForWC) {
    this.filters = newFilters;
    this.buildSelectedFilter();
  }
  changeFilters1(newFilters: ApiModels.SearchParamsForWC) {
    this.selectedFilters1 = newFilters;
    this.workplaceTypesSelectedCount = this.selectedFilters1.workplaceTypes.length;
    // this.buildSelectedFilter();
  }
  changeFilters2(newFilters: ApiModels.SearchParamsForWC) {
    this.selectedFilters2 = newFilters;
    this.workplaceTypesSelectedCount = this.selectedFilters2.workplaceTypes.length;
    // this.buildSelectedFilter();
  }

  changeTempFilters(newFilters: ApiModels.SearchParamsForWC) {
    this.tempFilters = newFilters;
  }
  changeTempFilters1(newFilters: ApiModels.SearchParamsForWC) {
    this.tempFilters2 = newFilters;
  }
  changeTempFilters2(newFilters: ApiModels.SearchParamsForWC) {
    this.tempFilters1 = newFilters;
  }

  changeFiltersForMap(newFilters: ApiModels.SearchParamsForWC) {
    this.filtersForMap = { ...newFilters };
    this.filters = { ...newFilters };
  }

  changeWorkplaceTypes(newWorkplaceTypes: WorkplaceType[]) {
    this.selectedWorkplaceTypes = newWorkplaceTypes;
    this.selectedWorkplaceTypeIds = this.selectedWorkplaceTypes.map(selectedWorkplaceType => selectedWorkplaceType.id);
  }

  showSearchMapAction() {
    this.searchPopupVisible = true;
    setTimeout(() => {
      this.filtersForMap = this.filters;
      $('.ui.modal.search-map-modal').modal('show');
    }, 0);
  }

  hideSearchMapAction() {
    $('.ui.modal.search-map-modal').modal('hide');
    this.searchPopupVisible = false;
  }

  hideSearchMapFilters() {
    this.searchMapResultsVisible = !this.searchMapResultsVisible;
  }

  buildSelectedFilter() {
    this.tempFilters = { ...this.filters };
    this.selectedFilters = { ...this.tempFilters };
    this.filtersForMap = { ...this.tempFilters };
  }

  getWorkplaceTypesForTempFilter() {
    let workplaceTypes: ApiModels.WPType[];
    if (this.tempFilters && this.tempFilters.workplaceType && this.selectedWorkplaceTypeIds) {
      workplaceTypes = this.selectedWorkplaceTypeIds.map(id => {
        const item = this.tempFilters.workplaceType.find(workplaceType => workplaceType.id === id);
        if (item) {
          return item;
        } else {
          return { id };
        }
      });
    } else if (this.selectedWorkplaceTypeIds) {
      workplaceTypes = this.selectedWorkplaceTypeIds.map(id => {
        return { id };
      });
    } else {
      workplaceTypes = [];
    }

    return workplaceTypes;
  }

  // TODO refactoring (возможно лучше это делать в search-result и search-result-for-map)
  goToWorkCenterSearch(workCenterId, emittedFromMapModal: boolean = false) {
    const filters = emittedFromMapModal ? this.selectedFilters2 : this.selectedFilters1;

    if (emittedFromMapModal) {
      this.hideSearchMapAction();
    }

    const queryParams = {
      workplaceTypes: this.selectedFilters1.workplaceTypes ? JSON.stringify(this.selectedFilters1.workplaceTypes) : undefined,
      date: filters.date ? formatDate(filters.date, 'MM-dd-yyyy', 'ru') : undefined,
      timeFrom: filters.timeFrom ? filters.timeFrom : undefined,
      timeTo: filters.timeTo ? filters.timeTo : undefined,
      tariffType: filters.tariffType ? 1 : 0
    };

    this.router.navigate([ '/workcenter-search', workCenterId ], { queryParams });
  }

  resultsChanged(searchResults) {
    this.searchResults = searchResults;
  }

  onPinClicked(pinResult) {
    this.pinResult = pinResult;
  }
}
