import { Component, OnInit, Input } from '@angular/core';
import { formatDate } from '@angular/common';

import * as ApiModels from '../../../api';
import { RentService } from '../../../api';

import { RentCurrentWithWorkcenterAndEquipment, ReservationCardOptions } from './reservation-card.model';

declare const $: any;

@Component({
  selector: 'app-reservation-card',
  templateUrl: './reservation-card.component.html',
  styleUrls: ['./reservation-card.component.scss']
})
export class ReservationCardComponent implements OnInit {
  @Input() item: RentCurrentWithWorkcenterAndEquipment;
  @Input() options: ReservationCardOptions;

  noteEditing: boolean;
  modalVisible: boolean;

  dateDisplayValue: string;
  timeDisplayValue: string;
  amountTime: string;

  currentStatus: any;

  basicStatuses = [{
    id: 1,
    name: 'Забронировано',
    color: '#41BBC9',
    bgcolor: '#FFFFFF'
  }, {
    id: 2,
    name: 'Бронирование завершено',
    color: '#0AB21B',
    bgcolor: '#FFFFFF'
  }, {
    id: 3,
    name: 'Бронирование отменено',
    color: '#EB595E',
    bgcolor: '#E8F0F3'
  }];

  constructor(
    private rentService: RentService
  ) { }

  ngOnInit() {
    this.item.noteVisible = !!this.item.userNotes;

    this.noteEditing = false;
    this.modalVisible = false;

    this.currentStatus = this.basicStatuses.find(status => status.id === this.item.status);

    this.dateDisplayValue = formatDate(this.item.dateStart, 'dd MMMM yyyy', 'ru') + ' года';
    this.timeDisplayValue = formatDate(this.item.dateStart, 'HH:mm', 'ru') + ' - ' + formatDate(this.item.dateEnd, 'HH:mm', 'ru');

    const hourDiff = new Date(this.item.dateEnd).getTime() - new Date(this.item.dateStart).getTime();
    const diffHrs = Math.floor((hourDiff % 86400000) / 3600000);
    // TODO
    // let diffMins = Math.floor(((hourDiff % 86400000) % 3600000) / 60000);
    // diffMins = diffMins + (diffHrs * 60);
    this.amountTime = diffHrs ? `${ diffHrs } час${ diffHrs === 1 ? '' : (diffHrs < 5 ? 'а' : 'ов')}` : '';
  }

  addNewNote() {
    this.item.noteVisible = true;
    this.noteEditing = true;
  }

  noteStartEdit() {
    this.noteEditing = true;
  }

  noteSave() {
    this.item.userNotes = $('#noteText').val();
    if (!this.item.userNotes) {
      this.item.noteVisible = false;
    } else {
      const note: ApiModels.NoteAdd = { rentId: this.item.id, userNotes: this.item.userNotes };
      this.rentService.addNote(note).toPromise().then(response => {
      });
    }
    this.noteEditing = false;
  }

  addReview() {
    $('.ui.modal.review-modal').modal('show');
  }

}
