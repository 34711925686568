import { Component, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import * as ApiModels from '../../api';
import { AreaService, WorkCenterService, PhotoService } from '../../api';

import { WorkCenterForManager } from './workcenter.model';

@Component({
  selector: 'app-workcenter',
  templateUrl: './workcenter.component.html',
  styleUrls: ['./workcenter.component.scss']
})
export class WorkCenterComponent implements OnInit, OnChanges {
  workCenter: WorkCenterForManager;
  workCenterId: string;
  workCenterPhotosIds: string[];
  workCenterPhotos: ApiModels.PhotoInfo[];

  areas: ApiModels.AreaWithWorkplacesAndTariffs[];
  schedules: ApiModels.ScheduleInfo[];

  cardVisible: boolean;
  viewFormVisible: boolean;
  editFormVisible: boolean;

  constructor(
    private activatedRoute: ActivatedRoute,
    private areaService: AreaService,
    private workCenterService: WorkCenterService,
    private photoService: PhotoService
  ) {
    this.workCenterPhotosIds = [];
    this.cardVisible = true;
    this.viewFormVisible = false;
    this.editFormVisible = false;
  }

  ngOnInit() {
    this.activatedRoute.params.subscribe(routeParams => {
      this.workCenterId = this.activatedRoute.snapshot.paramMap.get('id');
      this.onUpdateData();
    });
  }

  ngOnChanges(changes: SimpleChanges) {

  }

  onUpdateData() {
    this.getWorkCenterBriefInfo().then(() => {
      const areaIds = this.workCenter.areaIds;
      if (areaIds && areaIds.length) {
        this.getAreasInfo(areaIds).then(() => {
          this.schedules = this.workCenter.schedules;
        });
      } else {
        this.areas = [];
      }
    });
  }

  onShowViewForm() {
    this.getWorkCenterCompleteInfo().then(res => {
      this.cardVisible = false;
      this.viewFormVisible = true;
      this.editFormVisible = false;
    });
  }

  onShowEditForm() {
    this.cardVisible = false;
    this.viewFormVisible = false;
    this.editFormVisible = true;
  }

  private getWorkCenterPhotos() {
    this.photoService.getPhotosForGUID(this.workCenterId).toPromise().then((photos) => {
      this.workCenterPhotos = photos;
      this.workCenterPhotosIds = this.workCenterPhotos.map(photo => photo.id);
    });
  }

  private getWorkCenterBriefInfo() {
    return this.workCenterService.getWorkCenterAreaIdsById(this.workCenterId).toPromise().then(response => {
      this.workCenter = response;
    });
  }

  private getWorkCenterCompleteInfo() {
    this.getWorkCenterPhotos();

    return this.workCenterService.getWorkCenterDataById(this.workCenterId).toPromise().then(response => {
      this.workCenter = {
        ...this.workCenter,
        ...response
      };
    });
  }

  private getAreasInfo(areaIds: string[]) {
    return this.areaService.getAreaWithWorkplaceNumbersandTariffsByAreaIds(areaIds).toPromise().then(response => {
      this.areas = response;
    });
  }
}
