import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';

// import { AuthService } from '../../../auth/auth.service';

declare const $: any;

@Component({
  selector: 'app-restoration',
  templateUrl: './restoration.component.html',
  styleUrls: ['./restoration.component.scss']
})
export class RestorationComponent implements OnInit {
  @Output() showRegistration: EventEmitter<void>;

  phoneFormVisible: boolean;
  codeFormVisible: boolean;
  passwordFormVisible: boolean;
  endFormVisible: boolean;

  phoneNumber: string;
  secretCode: string;

  constructor() {
    this.showRegistration = new EventEmitter();
  }

  ngOnInit() {
    this.initStateForms();
  }

  registrationShow() {
    this.showRegistration.emit();
  }

  codeFormShow(phoneNumber: string) {
    this.phoneNumber = phoneNumber;
    this.phoneFormVisible = false;
    this.codeFormVisible = true;
  }

  passwordFormShow(secretCode: string) {
    this.secretCode = secretCode;
    this.codeFormVisible = false;
    this.passwordFormVisible = true;
  }

  endFormShow(passwordChange: boolean) {
    this.passwordFormVisible = false;
    this.endFormVisible = true;
  }

  onCloseButtonClick() {
    $('.ui.modal').modal('hide');
    this.initStateForms();
  }

  private initStateForms() {
    this.phoneFormVisible = true;
    this.codeFormVisible = false;
    this.passwordFormVisible = false;
    this.endFormVisible = false;
  }
}
