import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { AreaService, ScheduleService, TariffService, WorkCenterService } from '../../api';

import { DatepickerModule } from '../shared/datepicker';
import { TimepickerModule } from '../shared/timepicker';
import { ToggleModule } from '../shared/toggle';

import { TariffBoardComponent } from './tariff-board/tariff-board.component';
import { TariffComponent } from './tariff.component';
import { TariffUpsertComponent } from './tariff-upsert/tariff-upsert.component';
import { TariffViewComponent } from './tariff-view/tariff-view.component';

@NgModule({
  declarations: [
    TariffBoardComponent,
    TariffComponent,
    TariffUpsertComponent,
    TariffViewComponent
  ],
  imports: [
    CommonModule,
    DatepickerModule,
    FormsModule,
    TimepickerModule,
    ToggleModule
  ],
  exports: [
    TariffBoardComponent,
    TariffComponent
  ],
  providers: [
    AreaService,
    ScheduleService,
    TariffService,
    WorkCenterService
  ]
})
export class TariffModule { }
