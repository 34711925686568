import { Component, OnInit, HostListener, Output, EventEmitter, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';

import { AuthService } from '../../auth';

import * as ApiModels from '../../../api';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  @Input() visible: boolean;

  @Output() showRegistration: EventEmitter<void>;
  @Output() showRestoration: EventEmitter<string>;
  @Output() sendResult: EventEmitter<boolean>;
  @Output() closeClicked: EventEmitter<void>;

  formBuilder: FormBuilder;
  formFields: FormGroup;
  formError: string;

  hidePassword: boolean;

  spinnerVisible: boolean;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService
  ) {
    this.showRegistration = new EventEmitter();
    this.showRestoration = new EventEmitter();
    this.sendResult = new EventEmitter();
    this.closeClicked = new EventEmitter();
    this.hidePassword = true;
    this.spinnerVisible = false;
  }

  ngOnInit() {
    this.formBuilder = new FormBuilder();
    this.formFields = this.formBuilder.group({
      userType: new FormControl(undefined),
      login: new FormControl(null, Validators.required),
      password: new FormControl(null, [Validators.required]),
      // rememberMe: new FormControl(null)
    // }, {
    //   validator: this.checkLogin
    });
  }

  close() {
    this.visible = false;
    this.closeClicked.emit();
  }

  userTypeChange(value: boolean) {
    // this.formFields.setValue({userType: value});
  }

  login() {
    if (this.formFields.invalid) {
      return;
    }

    this.formError = undefined;
    this.spinnerVisible = true;

    this.authService.login(
      this.formFields.get('login').value,
      this.formFields.get('password').value
    ).toPromise().then(response => {
      // const returnUrl = this.route.snapshot.queryParamMap.get('returnUrl');
      // this.router.navigateByUrl(returnUrl ? returnUrl : '');
      // this.spinnerVisible = false;
      this.sendResult.emit(true);
    }).catch((errorResponse: any) => {
      // this.spinnerVisible = false;
      this.formError = errorResponse.error ? errorResponse.error.errorMessage : errorResponse.errorMessage;
    });
  }

  registration() {
    this.showRegistration.emit();
  }

  restoration() {
    this.showRestoration.emit(this.formFields.get('login').value);
  }

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (event.keyCode === 13 && !this.spinnerVisible) {
      this.login();
    }
  }

  private checkLogin(group: FormGroup) {
    const loginControl = group.controls.login;

    const value: string = loginControl.value;

    if (!value) {
      return loginControl.setErrors({ required: true });
    }

    if (value.indexOf('@') !== -1) {
      loginControl.setValidators([Validators.required, Validators.email]);
    } else {
      if (value.indexOf('+') > 0) {
        return loginControl.setErrors({ error: true });
      }

      if (value.replace('+', '').length !== 11) {
        return loginControl.setErrors({ error: true });
      }

      loginControl.setValidators([Validators.required, Validators.pattern('^[+0-9]+$')]);
    }

    return loginControl.valid ? loginControl.setErrors(null) : loginControl.setErrors({ error: true });
  }
}
