import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { PhotoInfo } from './photo-loader.model';

@Component({
  selector: 'app-photo-loader',
  templateUrl: './photo-loader.component.html',
  styleUrls: ['./photo-loader.component.scss']
})
export class PhotoLoaderComponent implements OnInit {
  @Input() photos: PhotoInfo[];
  @Output() changeListPhotos: EventEmitter<PhotoInfo[]>;

  constructor() {
    this.changeListPhotos = new EventEmitter<PhotoInfo[]>();
  }

  ngOnInit() {
    if (!this.photos) {
      this.photos = [];
    }
  }

  addPhoto(event: any) {
    const files: Blob[] = Object.values(event.target.files);
    files.forEach(file => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const newFile: PhotoInfo = {
          id: null,
          isCover: false,
          file,
          base64: reader.result
        };

        this.photos.push(newFile);
        this.changeListPhotos.emit(this.photos);
      };
    });
  }

  removePhoto(deletedPhoto: PhotoInfo) {
    if (deletedPhoto.id) {
      this.photos = this.photos.filter(photo => photo.id !== deletedPhoto.id);
    } else {
      this.photos = this.photos.filter(photo => photo.base64 !== deletedPhoto.base64);
    }
    this.changeListPhotos.emit(this.photos);
  }

  setMainPhoto(mainPhoto: PhotoInfo) {
    this.photos = this.photos.map(photo => {
      photo.isCover = false;
      if (
        (photo.id && photo.id === mainPhoto.id) ||
        (photo.base64 && photo.base64 === mainPhoto.base64)
      ) {
        photo.isCover = true;
      }

      return photo;
    });

    this.changeListPhotos.emit(this.photos);
  }
}
