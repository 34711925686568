import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges, OnChanges } from '@angular/core';

import * as ApiModels from '../../../api';

import { WorkCenterWithMinPrice } from '../search-result';

import { WCArea } from './map.model';

declare let jQuery: any;
declare const $: any;

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})
export class MapComponent implements OnInit, OnChanges {
  @Input() tariffType: number;
  @Input() results: WorkCenterWithMinPrice[];
  @Input() workplaceTypesSelectedCount: number;
  @Output() pinClicked: EventEmitter<WorkCenterWithMinPrice>;

  ymaps: any;
  ymap: any;
  mapCenter = [55.749765, 37.624285];
  pinContentLayout: any;
  hintLayout: any;
  hintContentLayout: any;
  balloonLayout: any;
  balloonContentLayout: any;

  constructor() {
    this.pinClicked = new EventEmitter();
  }

  ngOnInit() {
    this.mapInit();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.results) {
      if (this.results) {
        this.reloadMapObjects();
      }
    }
  }

  mapInit() {
    const self = this;
    (function ($) {
      $(document).ready(function() {
        const script = document.createElement('script');
        script.src = 'https://api-maps.yandex.ru/2.1/?lang=ru_RU&amp;apikey=bb144529-4637-4f90-b747-fed3bfdbbca2';
        document.getElementsByTagName('head')[0].appendChild(script);

        script.onload = function() {
          globalThis.ymaps.ready().then((ymaps: any) => {
            globalThis.map = new ymaps.Map('map', {
              center: self.mapCenter,
              zoom: 10
            });
            globalThis.map.container.fitToViewport();
            self.ymaps = ymaps;
            self.ymap = globalThis.map;
            self.initMapLayouts();
            self.reloadMapObjects();

            globalThis.map.events.add('click', function (e) {
              if (globalThis.map.balloon.isOpen()) {
                globalThis.map.balloon._balloon._data.geoObject.options._options.iconImageHref = 'assets/images/pinViewed.png';
                globalThis.map.balloon.close();
              }
            });
          });
        }
      });
    })(jQuery);
  }

  initMapLayouts() {
    // TODO try to move styles code into classes
    this.pinContentLayout = this.ymaps.templateLayoutFactory.createClass(
      '<div style="width: 126px; height: 15px; margin-top: 6px; color: #596275; text-align: center; vertical-align: middle; font-family: Montserrat; font-weight: regular; font-size: 12px;">$[properties.countLabel]</div>' +
      '<div style="width: 126px; height: 28px; color: #596275; text-align: center; vertical-align: middle; font-family: Montserrat; font-weight: regular; font-size: 14px;">$[properties.priceLabel]</div>'
    );
    this.hintLayout = this.ymaps.templateLayoutFactory.createClass(
      '<div style="top: -30px; left: 10px; padding-left: 32px; padding-right: 20px; padding-top: 11px; padding-bottom: 11px; position: absolute; background-color: white; border-radius: 20px;">' +
        '<div style="width: 180px; height: 30px auto; margin: 0px; color: #596275; text-align: left; vertical-align: middle; font-family: Montserrat; font-weight: bold; font-size: 14px;">$[properties.object.name]</div>' +
        '<ul>' +
          '{% for workplace in properties.object.areas %}' + // TODO тут берется массив areas, а его элементы почему-то называются workplace. наверно тут должно быть area
            '<li><div style="height: 19px; color: #596275; font-family: Montserrat; font-weight: normal; font-size: 12px;">{{ workplace.workplaceType.name }}</div></li>' +
            '<li><div style="height: 19px; color: #7A8EA7; font-family: Montserrat; font-weight: normal; font-size: 12px;">от {{ workplace.workplaceType.minPrice }} ₽ в час</div></li>' +
          '{% endfor %}' +
        '</ul>' +
      '</div>'
    );
    this.balloonLayout = this.ymaps.templateLayoutFactory.createClass(
      '<div style="top: -30px; left: 45px; padding-left: 32px; padding-right: 20px; padding-top: 11px; padding-bottom: 11px; position: absolute; background-color: white; border-radius: 20px;">' +
        '<div style="width: 180px; height: 30px auto; margin: 0px; color: #596275; text-align: left; vertical-align: middle; font-family: Montserrat; font-weight: bold; font-size: 14px;">$[properties.object.name]</div>' +
        '<ul>' +
          '{% for workplace in properties.object.areas %}' + // TODO тут берется массив areas, а его элементы почему-то называются workplace. наверно тут должно быть area
            '<li><div style="height: 19px; color: #596275; font-family: Montserrat; font-weight: normal; font-size: 12px;">{{ workplace.workplaceType.name }}</div></li>' +
            '<li><div style="height: 19px; color: #7A8EA7; font-family: Montserrat; font-weight: normal; font-size: 12px;">от {{ workplace.workplaceType.minPrice }} ₽ в час</div></li>' +
          '{% endfor %}' +
        '</ul>' +
      '</div>'
    );
  }

  reloadMapObjects() {
    this.ymap.geoObjects.removeAll();
    const self = this;
    this.results.forEach(object => {
      // object.areas.forEach(area => {
      //   area.workplaceType.minPrice = area.tariffs[0].price; // TODO тут точно нужна минимальная цена workplaceType? может все-таки для area?
      //   area.tariffs.forEach((tariff) => {
      //     if (tariff.price < object.minPrice) {
      //       object.minPrice = tariff.price;
      //     }
      //     if (tariff.price < area.workplaceType.minPrice) {
      //       area.workplaceType.minPrice = tariff.price;
      //     }
      //   });
      // });

      const pin = new this.ymaps.Placemark([
        object.latitude, object.longitude
      ], {
        object,
        countLabel: object.areas.length > 1 && this.workplaceTypesSelectedCount > 0 ? `${object.areas.length} из ${this.workplaceTypesSelectedCount}` : '',
        priceLabel: (object.areas.length > 1 ? 'от ' : '') + `${object.minPrice} ₽ в ${this.tariffType ? 'день' : 'час'}`
      }, {
        iconLayout: 'default#imageWithContent',
        iconImageHref: 'assets/images/pinDefault.png',
        iconImageSize: [130, 70],
        iconImageOffset: [-65, -70],
        hideIconOnBalloonOpen: false,
        iconContentLayout: self.pinContentLayout,
        hintLayout: self.hintLayout,
        balloonLayout: self.balloonLayout,
      });
      pin.events
      .add('mouseenter', function (e) {
      })
      .add('mouseleave', function (e) {
      })
      .add('click', function (e) {
        const workcenterObject = e.originalEvent.target.properties._data.object;
        self.onPinClicked(workcenterObject);

      })
      .add('balloonopen', function (e) {
        globalThis.map.balloon._balloon._data.geoObject.options._options.iconImageHref = 'assets/images/pinViewed.png';
      })
      .add('balloonclose', function (e) {
        e.originalEvent.currentTarget.options._options.iconImageHref = 'assets/images/pinViewed.png';
      });
      this.ymap.geoObjects.add(pin);
    });
  }

  onPinClicked(object) {
    this.pinClicked.emit(object);
  }
}
