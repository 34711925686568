import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { ReviewsModule } from '../reviews';

import { ReservationCardComponent } from './reservation-card.component';
import { ReservationCardsComponent } from './reservation-cards/reservation-cards.component';

@NgModule({
  declarations: [ReservationCardComponent, ReservationCardsComponent],
  imports: [
    ReviewsModule,
    CommonModule,
    RouterModule
  ],
  exports: [
    ReservationCardComponent,
    ReservationCardsComponent
  ]
})
export class ReservationCardModule { }
