import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { RentService } from '../../api';

import { CustomCarouselModule } from '../shared/custom-carousel';
import { DatepickerModule } from '../shared/datepicker';
import { ReservationPickerModule } from '../shared/reservation-picker';
import { MapModule } from '../shared/map';
import { RatingModule } from '../shared/rating';
import { ReviewsModule } from '../shared/reviews';
import { ToggleModule } from '../shared/toggle';

import { AreaModule } from '../area';

import { WorkCenterSearchComponent } from './workcenter-search.component';

@NgModule({
  declarations: [WorkCenterSearchComponent],
  imports: [
    AreaModule,
    CommonModule,
    CustomCarouselModule,
    DatepickerModule,
    MapModule,
    RatingModule,
    ReservationPickerModule,
    ReviewsModule,
    RouterModule,
    ToggleModule,
  ],
  providers: [
    RentService
  ]
})
export class WorkCenterSearchModule { }
