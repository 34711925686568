import { Component, OnInit, Input, Output, OnChanges, EventEmitter } from '@angular/core';
import { formatDate } from '@angular/common';

import * as clone from 'clone';

import * as ApiModels from '../../../../api';

import { HeaderLabel, SelectedItem } from './reservation-picker-board.model';

declare const $: any;

@Component({
  selector: 'app-reservation-picker-board',
  templateUrl: './reservation-picker-board.component.html',
  styleUrls: ['./reservation-picker-board.component.scss']
})
export class ReservationPickerBoardComponent implements OnInit, OnChanges {
  @Input() mode: 'renter' | 'organisation';
  @Input() areas: ApiModels.AreaWithEqupmentsTariffsWorkplacesAndRents[];
  @Input() tariffType: number;
  @Input() dateStart: Date;
  @Input() schedules: ApiModels.ScheduleInfo[];
  @Output() changeSelectedItems: EventEmitter<SelectedItem[]>;

  timeStep = 1;
  headerLabels: HeaderLabel[];
  currentDayOfWeek: number;
  selectedItems: SelectedItem[];

  constructor() {
    this.changeSelectedItems = new EventEmitter<SelectedItem[]>();
    this.selectedItems = [];
  }

  ngOnInit() {
    this.UpdateData();
  }

  ngOnChanges(changes: any) {
    if (changes.tariffType) {
      this.UpdateData();
    }

    if (changes.dateStart) {
      this.UpdateData();
    }
  }

  isDisabledItem(tariffs: ApiModels.TariffInfo[], workplace: ApiModels.WorkplaceWithRents, headerLabel: HeaderLabel) {
    if (!this.existsTariff(tariffs, headerLabel)) {
      return true;
    }

    if (!workplace.rents || !workplace.rents.length) {
      return false;
    }

    return this.includeRents(workplace, headerLabel);
  }

  existsTariff(tariffs: ApiModels.TariffInfo[], headerLabel: HeaderLabel) {
    const tariffIndex = tariffs.findIndex(tariff => {
      if (this.tariffType) {
        return tariff.weekDay === this.getDayOfWeek(headerLabel.value as Date);
      } else {
        return tariff.hour === headerLabel.value;
      }
    });

    return tariffIndex > -1;
  }

  findRent(workplace: ApiModels.WorkplaceWithRents, headerLabel: HeaderLabel): ApiModels.RentView {
    let date: Date;
    if (this.tariffType) {
      date = new Date(headerLabel.value);
    } else {
      date = new Date(this.dateStart);
      date.setHours(headerLabel.value as number);
    }

    const findRent = workplace.rents.find(rent => {
      return new Date(rent.dateStart) <= date && date < new Date(rent.dateEnd);
    });

    return findRent;
  }

  isEndRent(workplace: ApiModels.WorkplaceWithRents, headerLabel: HeaderLabel): boolean {
    const findRent = this.findRent(workplace, headerLabel);
    if (findRent) {
      const today = new Date();
      return new Date(findRent.dateEnd) < today;
    }

    return false;
  }

  isCurrentRent(workplace: ApiModels.WorkplaceWithRents, headerLabel: HeaderLabel): boolean {
    const findRent = this.findRent(workplace, headerLabel);
    if (findRent) {
      const today = new Date();
      return new Date(findRent.dateStart) <= today && today <= new Date(findRent.dateEnd);
    }

    return false;
  }

  isNewRent(workplace: ApiModels.WorkplaceWithRents, headerLabel: HeaderLabel): boolean {
    const findRent = this.findRent(workplace, headerLabel);
    if (findRent) {
      const today = new Date();
      return new Date(findRent.dateStart) > today;
    }

    return false;
  }

  includeRents(workplace: ApiModels.WorkplaceWithRents, headerLabel: HeaderLabel): boolean {
    const findRent = this.findRent(workplace, headerLabel);
    if (findRent) {
      return true;
    }

    return false;
  }

  onSelectItem(event: MouseEvent, tariffs: ApiModels.TariffInfo[], workplace: ApiModels.WorkplaceWithRents, headerLabel: HeaderLabel) {
    const workplaceId = workplace.id;
    const workplaceNumber = workplace.workplaceNumber;
    const headerValue = headerLabel.value;
    const price = this.getItemPrice(tariffs, headerLabel.value);
    const index = this.selectedItems.findIndex(item => {
      return item.workplaceId === workplaceId && item.headerValue === headerValue;
    });

    if (index > -1) {
      (event.target as HTMLAnchorElement).classList.remove('selected');
      this.selectedItems.splice(index, 1);
    } else {
      (event.target as HTMLAnchorElement).classList.add('selected');
      this.selectedItems.push({
        workplaceId,
        workplaceNumber,
        headerValue,
        price
      });
    }

    this.changeSelectedItems.emit(this.selectedItems);
  }

  onSelectRent(event: MouseEvent, tariffs: ApiModels.TariffInfo[], workplace: ApiModels.WorkplaceWithRents, headerLabel: HeaderLabel) {

  }

  getItemPrice(tariffs: ApiModels.TariffInfo[], itemValue: number | Date): number {
    let price = 0;
    const itemTariff = tariffs.find(tariff => {
      if (this.tariffType) {
        return tariff.weekDay === this.getDayOfWeek(itemValue as Date);
      } else {
        return tariff.hour === itemValue;
      }
    });
    if (itemTariff) {
      price = itemTariff.price;
    }

    return price;
  }

  getItemDisplayPrice(tariffs: ApiModels.TariffInfo[], itemValue: number | Date): string {
    if (!tariffs) {
      return;
    }

    const price = this.getItemPrice(tariffs, itemValue);
    const displayPrice = price ? (price + ' ₽') : '—';

    return displayPrice;
  }

  private UpdateData() {
    if (!this.schedules || !this.areas || !this.dateStart) {
      return;
    }

    this.schedules = this.schedules.sort((prev, next) => prev.dayOfWeek - next.dayOfWeek);
    this.headerLabels = this.getHeaderLabels(this.dateStart, this.schedules);

    const currentDayOfWeek = this.getDayOfWeek(this.dateStart); // TODO 1 - ПН, 2 - ВТ, ..., 7 - ВС
    this.areas = this.areas.map(area => {
      if (area.workplaces && area.workplaces.length) {
        area.workplaces.sort((prev, next) => prev.workplaceNumber - next.workplaceNumber);
      }
      if (this.tariffType === 0 && area.tariffs && area.tariffs.length) {
        area.tariffs = area.tariffs.filter(tariff => tariff.weekDay === currentDayOfWeek);
      }
      return area;
    });
  }

  private getHeaderLabels(dateStart: Date, schedules: ApiModels.ScheduleInfo[]): HeaderLabel[] {
    const labels: HeaderLabel[] = [];
    const date = new Date(dateStart);

    if (this.tariffType) {
      for (let i = 0; i <= 7; i++) {
        labels.push({
          value: clone(date),
          displayValue: {
            date: formatDate(date, 'dd', 'ru'),
            month: formatDate(date, 'MMMM', 'ru'),
            dayOfWeek: formatDate(date, 'EE', 'ru')
          }
        });
        date.setDate(date.getDate() + 1);
      }
    } else {
      const currentDayOfWeek = this.getDayOfWeek(dateStart); // TODO 1 - ПН, 2 - ВТ, ..., 7 - ВС
      const currentSchedule = schedules.find(item => item.dayOfWeek === currentDayOfWeek);
      const start = +currentSchedule.startHour.split(':').shift();
      const end = +currentSchedule.endHour.split(':').shift();

      for (let i = start; i <= end; i++) {
        date.setHours(i);
        date.setMinutes(0);
        labels.push({
          value: i,
          displayValue: {
            hour: formatDate(date, 'HH:mm', 'ru')
          }
        });
      }
    }

    return labels;
  }

  private getDayOfWeek(value: Date) {
    let dayOfWeek = value.getDay();

    if (dayOfWeek === 0) {
      dayOfWeek = 7;
    }

    return dayOfWeek;
  }
}
