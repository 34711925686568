import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';

import { RentService } from '../../api';

import { ReservationCardModule } from '../shared/reservation-card';

import { ReservationConfirmationComponent } from './reservation-confirmation.component';

@NgModule({
  declarations: [ReservationConfirmationComponent],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    ReservationCardModule
  ],
  providers: [
    RentService
  ]
})
export class ReservationConfirmationModule { }
