import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';

import * as ApiModels from '../../../api';
import { AreaService, RentService } from '../../../api';

@Component({
  selector: 'app-reservations-workcenter-adding-form',
  templateUrl: './reservations-workcenter-adding-form.component.html',
  styleUrls: ['./reservations-workcenter-adding-form.component.scss']
})
export class ReservationsWorkCenterAddingFormComponent implements OnInit, OnChanges {
  @Input() workplaces: ApiModels.Workplace[];
  @Input() dateStart: Date;
  @Input() tariffType: number;
  @Output() addRent: EventEmitter<void>;

  formBuilder: FormBuilder;
  formFields: FormGroup;
  formError: string;

  timeFrom: number;
  timeTo: number;
  modePrice: 'view' | 'edit';

  constructor(
    private areaService: AreaService,
    private rentService: RentService
  ) {
    this.addRent = new EventEmitter();
    this.modePrice = 'view';
    this.timeFrom = undefined;
    this.timeTo = undefined;
  }

  ngOnInit() {
    this.formBuilder = new FormBuilder();
    this.formFields = this.formBuilder.group({
      workplaceType: new FormControl(null, [Validators.required]),
      contactPerson: new FormControl(null, [Validators.required]),
      contactPhone: new FormControl(null, [Validators.required]),
      cost: new FormControl(undefined, [Validators.required])
    });
    this.formFields.controls.cost.setValue(undefined);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.tariffType && this.tariffType !== undefined) {
      if (this.tariffType === 1) {
        this.timeFrom = undefined;
        this.timeTo = undefined;
      }

      this.makePriceRequest();
    }
  }

  changeDate(newDate: Date) {
    this.dateStart = newDate;
    this.makePriceRequest();
  }

  changeTimes(newTime: number[]) {
    this.timeFrom = newTime[0];
    this.timeTo = newTime[1];
    this.makePriceRequest();
  }

  onClickEditPriceButton() {
    this.modePrice = 'edit';
  }

  onClickSavePriceButton() {
    if (this.formFields.get('cost').invalid) {
      return;
    }

    this.modePrice = 'view';
  }

  makePriceRequest() {
    if (!this.formFields) {
      return;
    }

    if (
      this.formFields.get('workplaceType').invalid ||
      !this.dateStart ||
      (this.tariffType === 0 && (this.timeFrom === undefined || this.timeTo === undefined))
    ) {
      return this.formFields.controls.cost.setValue(undefined);
    }

    const workplaceAreaId = this.formFields.get('workplaceType').value.areaId;

    this.areaService.getAreaByIdExtended(workplaceAreaId, this.dateStart, this.tariffType).toPromise().then(response => {
      let dayOfWeek = this.dateStart.getDay();
      if (dayOfWeek === 0) {
        dayOfWeek = 7;
      }
      if (this.tariffType === 1) {
        const findTariff = response.tariffs.find(tariff => tariff.weekDay === dayOfWeek);
        if (findTariff) {
          return this.formFields.controls.cost.setValue(findTariff.price);
        }
      } else {
        const findTariffs = response.tariffs.filter(tariff => {
          return tariff.weekDay === dayOfWeek && tariff.hour >= this.timeFrom && tariff.hour < this.timeTo;
        });

        if (findTariffs && findTariffs.length) {
          let totalCost = 0;
          findTariffs.forEach(tariff => {
            totalCost += tariff.price;
          });

          return this.formFields.controls.cost.setValue(totalCost);
        }
      }

      this.formFields.controls.cost.setValue(undefined);
    }).catch(err => {
      this.formFields.controls.cost.setValue(undefined);
    });
  }

  onAddButtonClick() {
    const dateStart = new Date(
      this.dateStart.getFullYear(),
      this.dateStart.getMonth(),
      this.dateStart.getDate(),
      this.timeFrom ? this.timeFrom : 0
    );
    const dateEnd = new Date(
      dateStart.getFullYear(),
      dateStart.getMonth(),
      dateStart.getDate(),
      this.timeTo ? this.timeTo : 0
    );
    if (this.tariffType === 1) {
      dateEnd.setDate(dateEnd.getDate() + 1);
    }
    const newRent: ApiModels.RentAddByOwner = {
      workplaceId: this.formFields.get('workplaceType').value.id,
      contactPerson: this.formFields.get('contactPerson').value,
      contactPhone: this.formFields.get('contactPhone').value,
      cost: +this.formFields.get('cost').value,
      dateStart,
      dateEnd,
      tariffType: this.tariffType
    };

    this.rentService.rentCreateByOwner(
      [newRent]
    ).toPromise().then(response => {
      this.addRent.emit();
    });
  }
}
