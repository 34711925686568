import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import {
  OrganisationService,
  RegisterUserService,
  RentService
} from '../../api';

import { DatepickerModule } from '../shared/datepicker';
import { ReservationPickerModule } from '../shared/reservation-picker';
import { TimepickerModule } from '../shared/timepicker';
import { ToggleModule } from '../shared/toggle';

import { ReservationsWorkCenterAddingFormComponent } from './reservations-workcenter-adding-form/reservations-workcenter-adding-form.component';
import { ReservationsWorkCenterComponent } from './reservations-workcenter.component';
import { ReservationsWorkCenterListComponent } from './reservations-workcenter-list/reservations-workcenter-list.component';

@NgModule({
  declarations: [
    ReservationsWorkCenterAddingFormComponent,
    ReservationsWorkCenterComponent,
    ReservationsWorkCenterListComponent
  ],
  imports: [
    CommonModule,
    DatepickerModule,
    FormsModule,
    ReactiveFormsModule,
    ReservationPickerModule,
    TimepickerModule,
    ToggleModule
  ],
  exports: [
    ReservationsWorkCenterComponent
  ],
  providers: [
    OrganisationService,
    RegisterUserService,
    RentService
  ]
})
export class ReservationsWorkCenterModule { }
