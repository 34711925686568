import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { EquipmentsForWorkplaceTypeService } from '../../api';

import { DatepickerModule } from '../shared/datepicker';
import { MapModule } from '../shared/map';
import { SearchFilterModule } from '../shared/search-filter';
import { SearchResultModule } from '../shared/search-result';
import { TimepickerModule } from '../shared/timepicker';
import { WorkplaceTypesSelectModule } from '../shared/workplace-types-select';

import { SearchComponent  } from './search.component';

@NgModule({
  declarations: [SearchComponent],
  imports: [
    CommonModule,
    DatepickerModule,
    MapModule,
    SearchFilterModule,
    SearchResultModule,
    TimepickerModule,
    WorkplaceTypesSelectModule
  ],
  providers: [
    EquipmentsForWorkplaceTypeService
  ]
})
export class SearchModule { }
