import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { debounce } from 'lodash';

import * as ApiModels from '../../../api';
import { CityService } from '../../../api';

@Component({
  selector: 'app-city-picker',
  templateUrl: './city-picker.component.html',
  styleUrls: ['./city-picker.component.scss']
})
export class CityPickerComponent implements OnInit {
  @Input() currentCity: ApiModels.City;
  @Output() сurrentCityChanged: EventEmitter<ApiModels.City>;

  сities: ApiModels.CityItem[];
  citiesList: ApiModels.CityItem[];
  citiesListVisible = true;
  okButtonEnabled = true;

  constructor(
    private cityService: CityService
  ) {
    this.сurrentCityChanged = new EventEmitter<ApiModels.City>();
    this.search = debounce(this.search, 500);
  }

  ngOnInit() {
    this.cityService.get().toPromise().then(cities => {
      this.сities = cities;
      this.citiesDefaultPreset();
    });
  }

  search(cityName: string) {
    this.okButtonEnabled = false;
    if (!cityName || cityName && cityName.trim() === '') {
      this.citiesDefaultPreset();
      return;
    }
    this.citiesList = this.getCities(cityName);
    this.citiesListVisible = true;
  }

  setCurrentCity(city: ApiModels.CityItem) {
    this.currentCity = {
      id: city.id,
      city: city.city
    };
    this.citiesListVisible = false;
    this.citiesList = [];
    this.okButtonEnabled = true;
  }

  updateCurrentCity() {
    if (this.okButtonEnabled) {
      this.сurrentCityChanged.emit(this.currentCity);
    }
  }

  citiesDefaultPreset() {
    this.citiesList = this.сities.filter(city => +city.population > 1000000);
  }

  getCities(cityName: string): ApiModels.CityItem[] {
    this.citiesList = this.сities.filter(city => city.city.toLowerCase().startsWith(cityName.toLowerCase()));
    return this.citiesList;
  }
}
