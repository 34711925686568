import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AboutServiceComponent                      } from './about-service';
import { AreaComponent, AreaUpsertComponent         } from './area';
import { ContactsComponent                          } from './contacts';
import { HomeComponent                              } from './home';
import { MyReservationsComponent                    } from './my-reservations';
import { MyReviewsComponent                         } from './my-reviews';
import { PageNotFoundComponent                      } from './page-not-found';
import { ProfileUserComponent                       } from './profile-user';
import { ProfileOrganisationComponent               } from './profile-organisation';
import { ProfileLayoutComponent                     } from './profile-layout';
import { ReservationCancellationComponent           } from './reservation-cancellation';
import { ReservationConfirmationComponent           } from './reservation-confirmation';
import { ReservationsWorkCenterComponent            } from './reservations-workcenter';
import { SearchComponent                            } from './search';
import { WorkCenterComponent, WorkCenterUpsertComponent } from './workcenter';
import { WorkCenterSearchComponent                  } from './workcenter-search';

const routes: Routes = [
  { path: '',                 component: HomeComponent,           data: { title: 'Воркчейн центр'             } },
  { path: 'about-service',    component: AboutServiceComponent,   data: { title: 'О сервисе'                  } },
  { path: 'contacts',         component: ContactsComponent,       data: { title: 'Контакты'                   } },
  {
    path: 'profile/user',
    component: ProfileLayoutComponent,
    children: [
      { path: '',                 component: ProfileUserComponent,    data: { title: 'Профиль'                    } },
      { path: 'my-reservations',  component: MyReservationsComponent, data: { title: 'Мои бронирования'           } },
      { path: 'my-reviews',       component: MyReviewsComponent,      data: { title: 'Мои отзывы'                 } },
    ]
  },
  {
    path: 'profile/organisation/:id',
    component: ProfileLayoutComponent,
    children: [
      { path: '',                 component: ProfileOrganisationComponent,      data: { title: 'Профиль организации'        } },
      { path: 'workcenter/add',   component: WorkCenterUpsertComponent,         data: { title: 'Добавление объекта'         } },
      {
        path: 'workcenter/:id',
        children: [
          { path: '',             component: WorkCenterComponent,             data: { title: 'Объект'                     } },
          { path: 'area/add',     component: AreaUpsertComponent,             data: { title: 'Добавление рабочего места'  } },
          { path: 'area/:id',     component: AreaComponent,                   data: { title: 'Рабочее место'              } },
          { path: 'reservations', component: ReservationsWorkCenterComponent, data: { title: 'Бронирования объекта'       } },
        ]
      },
    ]
  },
  {
    path: 'reservation/cancellation/:id',
    component: ReservationCancellationComponent,
    data: { title: 'Отмена бронирования'        }
  },
  {
    path: 'reservation/confirmation',
    component: ReservationConfirmationComponent,
    data: { title: 'Подтверждение бронирования'        }
  },
  { path: 'search',                component: SearchComponent,            data: { title: 'Поиск'                      } },
  { path: 'workcenter-search/:id', component: WorkCenterSearchComponent,  data: { title: 'Страница объекта'           } },
  { path: '**',                    component: PageNotFoundComponent,      data: { title: '404 - Страница не найдена'  } }

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
