import { Component, OnInit } from '@angular/core';
import { ActivatedRoute} from '@angular/router';

import * as ApiModels from '../../../api';
import { OrganisationService, OrganisationTypeService } from '../../../api';

@Component({
  selector: 'app-profile-organisation-info',
  templateUrl: './profile-organisation-info.component.html',
  styleUrls: ['./profile-organisation-info.component.scss']
})
export class ProfileOrganisationInfoComponent implements OnInit {
  organisation: ApiModels.Organisation;
  organisationTypes: ApiModels.OrganisationType[];

  organisationId: string;

  viewFormVisible: boolean;
  editFormVisible: boolean;

  constructor(
    private route: ActivatedRoute,
    private organizationService: OrganisationService,
    private organisationTypeService: OrganisationTypeService
  ) {
    this.viewFormVisible = true;
    this.editFormVisible = false;
  }

  ngOnInit() {
    this.organisationId = this.route.snapshot.paramMap.get('id');
    this.getOrganisationTypes();
    this.getOrganisationInfo();
  }

  onShowViewForm() {
    this.getOrganisationInfo().then(response => {
      this.viewFormVisible = true;
      this.editFormVisible = false;
    });
  }

  onShowEditForm() {
    this.viewFormVisible = false;
    this.editFormVisible = true;
  }

  private getOrganisationTypes() {
    this.organisationTypeService.getOrganisationTypeList().toPromise().then(response => {
      this.organisationTypes = response;
    });
  }

  private getOrganisationInfo() {
    return this.organizationService.getOrganisationById(this.organisationId).toPromise().then(response => {
      this.organisation = response;
    });
  }
}
