import { Component, EventEmitter, OnInit, Input, Output } from '@angular/core';
import { FeedbackForm } from './feedback-form.model';

declare const $: any;

@Component({
  selector: 'app-feedback-form',
  templateUrl: './feedback-form.component.html',
  styleUrls: ['./feedback-form.component.scss']
})
export class FeedbackFormComponent implements OnInit {
  @Input() public textPlaceholder: string = 'Напишите свои пожелания';
  @Output() public onSubmit: EventEmitter<FeedbackForm> = new EventEmitter<FeedbackForm>();

  constructor() { }

  public ngOnInit() {
    this.initForm();
  }

  public onSubmitFormHandler() {
    const form = $('.ui.form');

    if( form.form('is valid') ) {
      $('.ui.modal').modal('show');
      this.onSubmit.emit( form.form('get values') );
    }
  }

  private initForm() {
    $('.ui.form').form({
      fields: {
        userName: {
          identifier: 'userName',
          rules: [
            {
              type: 'empty',
            }
          ]
        },
        text: {
          identifier: 'userText',
          rules: [
            {
              type: 'empty',
            }
          ]
        },
        phone: { identifier: 'userPhone' },
        email: { identifier: 'userEmail' },
      }
    });
  }
}
