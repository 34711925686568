import { Component, OnInit, Output, EventEmitter, Input, OnChanges, SimpleChanges } from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { Observable } from 'rxjs';

import * as ApiModels from '../../../api';
import { WorkCenterService } from '../../../api';

import { WorkCenterWithMinPrice } from './search-result.model';

@Component({
  selector: 'app-search-result',
  templateUrl: './search-result.component.html',
  styleUrls: ['./search-result.component.scss']
})
export class SearchResultComponent implements OnInit, OnChanges {
  @Input() filters: ApiModels.SearchParamsForWC;
  @Output() showSearchMapAction: EventEmitter<boolean>;
  @Output() seeAvailableAreasClicked: EventEmitter<string>;

  results$: Observable<WorkCenterWithMinPrice[]>;
  sorting: ApiModels.Sort;

  constructor(
    public breakpointObserver: BreakpointObserver,
    private workCenterService: WorkCenterService
  ) {
    this.seeAvailableAreasClicked = new EventEmitter();
    this.showSearchMapAction = new EventEmitter();
    this.sorting = {
      sortBy: 'price',
      desc: false
    };
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.filters) {
      this.searchStart();
    }
  }

  showHideMapSearch() {
    this.showSearchMapAction.emit(true);
  }

  sortByPrice() {
    this.sorting = {
      sortBy: 'price',
      desc: !this.sorting.desc
    };
    this.searchStart();
  }

  sortByRating() {
    this.sorting = {
      sortBy: 'rating',
      desc: !this.sorting.desc
    };
    this.searchStart();
  }

  onSeeAvailableAreasClicked(workCenterId) {
    this.seeAvailableAreasClicked.emit(workCenterId);
  }

  private searchStart() {
    if (!this.filters) {
      return;
    }

    this.filters.sortBy = [this.sorting];

    this.results$ = this.workCenterService.searchWithBodyParamsQuick(this.filters);
  }
}
