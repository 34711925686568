import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { formatDate } from '@angular/common';
import { BreakpointObserver } from '@angular/cdk/layout';

import { WorkplaceType } from '../../shared/workplace-types-select';

declare const $: any;

@Component({
  selector: 'app-search-form',
  templateUrl: './search-form.component.html',
  styleUrls: ['./search-form.component.scss']
})
export class SearchFormComponent implements OnInit {

  workplaceTypeIds: string[];
  date: Date;
  times: number[];

  constructor(
    public breakpointObserver: BreakpointObserver,
    private router: Router
  ) {
  }

  ngOnInit() {
  }

  changeWorkplaceTypes(workplaceTypes: WorkplaceType[]) {
    this.workplaceTypeIds = workplaceTypes.map(type => type.id);
  }

  changeDate(value: Date) {
    this.date = value;
  }

  changeTimes(value: number[]) {
    this.times = value;
  }

  search() {
    if (!this.workplaceTypeIds) {
      return;
    }

    const queryParams = {
      workplaceTypeIds: this.workplaceTypeIds,
      date: this.date ? formatDate(this.date, 'MM-dd-yyyy', 'ru') : undefined,
      timeFrom: this.times ? this.times[0] : undefined,
      timeTo: this.times ? this.times[1] : undefined
    };
    this.router.navigate(['/search'], { queryParams });
  }
}
