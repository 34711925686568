import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import * as ApiModels from '../../api';
import { AreaService, EquipmentService } from '../../api';

import { Area } from './area.model';

@Component({
  selector: 'app-area',
  templateUrl: './area.component.html',
  styleUrls: ['./area.component.scss']
})
export class AreaComponent implements OnInit {
  @Input() areaId: string;
  @Input() area: Area;
  @Input() schedules: ApiModels.ScheduleInfo[];
  @Input() size: 'small' | 'big';
  @Output() areaDeleted: EventEmitter<void>;

  equipments: ApiModels.Equipment[];

  viewFormVisible: boolean;
  editFormVisible: boolean;

  constructor(
    private activatedRoute: ActivatedRoute,
    private areaService: AreaService,
    private equipmentService: EquipmentService
  ) {
    this.size = 'big';
    this.viewFormVisible = true;
    this.editFormVisible = false;
    this.areaDeleted = new EventEmitter();
  }

  ngOnInit() {
    if (this.area) {
      this.areaId = this.area.id;
    }

    if (!this.areaId) {
      this.areaId = this.activatedRoute.snapshot.paramMap.get('id');
    }

    if (!this.area) {
      this.getAreaInfo().then(() => {
        this.getAreaEquipments();
      });
    }
  }

  onShowViewForm() {
    this.getAreaInfo().then(() => {
      this.getAreaEquipments().then(() => {
        this.viewFormVisible = true;
        this.editFormVisible = false;
      });
    });
  }

  onShowEditForm(area: ApiModels.AreaWithWorkplaceTypeName) {
    this.area = area;
    this.viewFormVisible = false;
    this.editFormVisible = true;
  }

  onAreaDeleted() {
    this.areaDeleted.emit();
  }

  private getAreaInfo() {
    return this.areaService.getAreaById(this.areaId).toPromise().then(areaInfo => {
      this.area = areaInfo;
    });
  }

  private getAreaEquipments() {
    return this.equipmentService.getByAreaId(this.areaId).toPromise().then(areaEquipments => {
      this.area.equipments = areaEquipments;
      this.equipments = areaEquipments;
    });
  }
}
