import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-rating',
  templateUrl: './rating.component.html',
  styleUrls: ['./rating.component.scss']
})
export class RatingComponent implements OnInit, OnChanges {
  @Input() value: number;
  @Input() reviewsCount: number;
  @Input() nameVisible: boolean;
  @Input() reviewsVisible: boolean;
  @Input() allRatingsVisible: boolean;
  @Input() bottomVisible: boolean;

  nameRating: string;

  constructor() { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    this.nameRating = this.getNameRating(this.value);
  }

  normalizeValue(value: number) {
    if (!value) {
      return;
    }
    return value.toString().replace('.', ',');
  }

  private getNameRating(value: number) {
    // 9+ Великолепно
    // 8+ Отлично
    // 7+ Хорошо
    // 6+ Достаточно хорошо
    // 5+ Удовлетворительно
    // 3+ Приемлемо
    // 1+ Плохо
    let name: string;

    if (!value) {
      return;
    }

    if (value >= 9) {
      name = 'Великолепно';
    } else if (value >= 8) {
      name = 'Отлично';
    } else if (value >= 7) {
      name = 'Хорошо';
    } else if (value >= 6) {
      name = 'Достаточно хорошо';
    } else if (value >= 5) {
      name = 'Удовлетворительно';
    } else if (value >= 3) {
      name = 'Приемлемо';
    } else {
      name = 'Плохо';
    }

    return name;
  }

}
