import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CustomCarouselModule } from '../shared/custom-carousel';
import { FeedbackFormModule } from '../shared/feedback-form';

import { AboutServiceComponent } from './about-service.component';

@NgModule({
  declarations: [AboutServiceComponent],
  imports: [
    CommonModule,
    CustomCarouselModule,
    FeedbackFormModule,
  ],
  exports: [
    AboutServiceComponent
  ]
})
export class AboutServiceModule { }
