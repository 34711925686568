import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import * as ApiModels from '../../../api';
import { AreaService, PhotoService, WorkplaceService } from '../../../api';

import { Area, AreaStatus } from '../area.model';

declare const $: any;

@Component({
  selector: 'app-area-view',
  templateUrl: './area-view.component.html',
  styleUrls: ['./area-view.component.scss']
})
export class AreaViewComponent implements OnInit {
  @Input() area: Area;
  @Input() schedules: ApiModels.ScheduleInfo[];
  @Input() equipments: ApiModels.Equipment[];
  @Input() size: 'small' | 'big';
  @Output() showEditForm: EventEmitter<ApiModels.AreaWithWorkplaceTypeName>;
  @Output() areaDeleted: EventEmitter<void>;

  workplaces: ApiModels.WorkplaceIdAndNumber[];
  photoIds: string[];
  hasActiveRents: boolean;

  currentStatus: AreaStatus;
  basicStatuses: AreaStatus[] = [
    {
      id: 0,
      name: 'Удалено',
      color: '#BDBDBD',
    },
    {
      id: 1,
      name: 'Активно',
      color: '#0AB21B',
    },
    {
      id: 2,
      name: 'На модерации',
      color: '#41BBC9',
    },
    {
      id: 3,
      name: 'Отклонено',
      color: '#EB595E',
    },
    {
      id: 4,
      name: 'Изменено',
      color: '#FFDB72',
    },
    {
      id: 5,
      name: 'Не активно',
      color: '#BDBDBD',
    },
  ];

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private areaService: AreaService,
    private photoService: PhotoService,
    private workplaceService: WorkplaceService
  ) {
    this.hasActiveRents = false;
    this.size = 'big';
    this.showEditForm = new EventEmitter<ApiModels.AreaWithWorkplaceTypeName>();
    this.areaDeleted = new EventEmitter<void>();
  }

  ngOnInit() {
    this.getWorkplacesAndTariffs();
    this.setCurrentStatus();
  }

  onDeleteWorkplace(id: string) {
    this.workplaceService.deleteWorkplace(id).toPromise().then(response => {
      this.workplaces = this.workplaces.filter(workplace => workplace.id !== id);
    });
  }

  onAddWorkplace() {
    const newWorkplace: ApiModels.WorkplaceForAreaAdd = {
      areaId: this.area.id,
      workplacesAmount: 1
    };
    this.workplaceService.createWorkplacesForArea(newWorkplace).toPromise().then(response => {
      this.getWorkplacesAndTariffs();
    });
  }

  onShowAreaPage() {
    const organisationId = this.activatedRoute.snapshot.parent.parent.paramMap.get('id');
    const workCenterId = this.activatedRoute.snapshot.parent.paramMap.get('id');
    this.router.navigateByUrl(`/profile/organisation/${organisationId}/workcenter/${workCenterId}/area/${this.area.id}`);
  }

  onShowBidSize() {
    this.getAreaInfo().then(() => {
      this.getAreaPhotos().then(() => {
        this.size = 'big';
      });
    });
  }

  onShowEditForm() {
    this.showEditForm.emit(this.area);
  }

  onClickDeleteAreaButton() {
    // TODO добавить получение рент и выставление значения переменной hasActiveRents
    if (this.hasActiveRents) {
      this.showModal(`delete-denied-area-modal-${this.area.id}`);
    } else {
      this.showModal(`delete-allowed-area-modal-${this.area.id}`);
    }
  }

  showModal(idModal: string) {
    $(`#${idModal}`).modal('show');
  }

  hideModal() {
    $(`.ui.modal`).modal('hide');
  }

  publishArea() {
    this.areaService.areaActivate({id: this.area.id}).toPromise().then(response => {
      this.area.status = response.status;
      this.setCurrentStatus();
      this.hideModal();
    });
  }

  unpublishArea() {
    this.areaService.areaDeactivate({id: this.area.id}).toPromise().then(response => {
      this.area.status = response.status;
      this.setCurrentStatus();
      this.hideModal();
    });
  }

  deleteArea() {
    this.areaService.deleteAreaById(this.area.id).toPromise().then(() => {
      this.hideModal();
      this.areaDeleted.emit();
    });
  }

  goToWorkCenterRents() {
    // TODO реализовать переход на ренты
    console.log('goToWorkCenterRents');
    // this.router.navigate(['/profile/organisation', this.organisationId, 'reservations']);
  }

  private setCurrentStatus() {
    this.currentStatus = this.basicStatuses.find((basicStatus) => basicStatus.id === this.area.status);
  }

  private getAreaInfo() {
    return this.areaService.getAreaById(this.area.id).toPromise().then(response => {
      this.area = {
        ...this.area,
        ...response
      };
    });
  }

  private getWorkplacesAndTariffs() {
    return this.areaService.getAreaWithWorkplaceNumbersandTariffsByAreaIds([this.area.id]).toPromise().then(response => {
      this.area = {
        ...this.area,
        ...response[0]
      };
      this.workplaces = this.area.workplaces;
    });
  }

  private getAreaPhotos() {
    return this.photoService.getPhotosForGUID(this.area.id).toPromise().then(response => {
      this.area.photos = response;
      this.photoIds = response.map(item => item.id);
    });
  }

}
