import { Component, OnInit, OnChanges, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { formatDate } from '@angular/common';

import * as ApiModels from '../../../api';
import { TariffService, WorkCenterService } from '../../../api';

import { TariffGroupedByDateAndType } from '../tariff.model';

declare const $: any;

@Component({
  selector: 'app-tariff-upsert',
  templateUrl: './tariff-upsert.component.html',
  styleUrls: ['./tariff-upsert.component.scss']
})
export class TariffUpsertComponent implements OnInit, OnChanges {
  @Input() mode: 'add' | 'edit';
  @Input() areaId: string;
  @Input() tariffs: TariffGroupedByDateAndType;
  @Input() schedules: ApiModels.ScheduleInfo[];
  @Output() showViewForm: EventEmitter<void>;

  tariffType: number;

  dateStartByHours: Date;
  dateEndByHours: Date;
  daysByHours: number[];
  timesByHours: number[];
  minTimeByHours: number;
  maxTimeByHours: number;
  priceByHours: number;
  tariffsByHours: ApiModels.TariffInfo[];
  dateEndByHoursVisible: boolean;

  dateStartByDays: Date;
  dateEndByDays: Date;
  daysByDays: number[];
  timesByDays: number[];
  minTimeByDays: number;
  maxTimeByDays: number;
  priceByDays: number;
  tariffsByDays: ApiModels.TariffInfo[];
  dateEndByDaysVisible: boolean;

  daysOfWeek = [{
    id: 1,
    name: 'Понедельник',
    shortName: 'ПН'
  }, {
    id: 2,
    name: 'Вторник',
    shortName: 'ВТ'
  }, {
    id: 3,
    name: 'Среда',
    shortName: 'СР'
  }, {
    id: 4,
    name: 'Четверг',
    shortName: 'ЧТ'
  }, {
    id: 5,
    name: 'Пятница',
    shortName: 'ПТ'
  }, {
    id: 6,
    name: 'Суббота',
    shortName: 'СБ'
  }, {
    id: 7,
    name: 'Воскресенье',
    shortName: 'ВС'
  }];

  constructor(
    private tariffService: TariffService,
    private workCenterService: WorkCenterService
  ) {
    this.showViewForm = new EventEmitter();
    this.tariffType = 0;

    this.dateEndByHoursVisible = false;
    this.timesByHours = [];
    this.minTimeByHours = 0;
    this.maxTimeByHours = 24;
    this.tariffsByHours = [];

    this.dateEndByDaysVisible = false;
    this.timesByDays = [];
    this.minTimeByDays = 0;
    this.maxTimeByDays = 24;
    this.tariffsByDays = [];
  }

  ngOnInit() {
    setTimeout(() => {
      $('#tariffTabs .item').tab();
      $('#daysOfWeekByHours').dropdown({
        placeholder: 'auto',
        clearable: true
      });
      $('#daysOfWeekByDays').dropdown({
        placeholder: 'auto',
        clearable: true
      });
    }, 0);

    if (this.mode === 'edit' && this.tariffs && this.tariffs.tariffs && this.tariffs.tariffs.length) {
      this.tariffType = this.tariffs.tariffs[0].tariffType;
    }

    this.initTariffs();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.tariffs && this.tariffs && this.mode === 'edit') {
      this.initTariffs();
    }

    if (changes.schedules && this.schedules && this.schedules.length) {
      this.getMinMaxTimeByHours(this.schedules);
    }
  }

  setTariffType(value: number) {
    this.tariffType = value;
  }

  onClickCancelButton() {
    this.showViewForm.emit();
  }

  onClickAddDateEndButtonByHours() {
    this.dateEndByHoursVisible = true;
  }

  onClickDeleteDateEndButtonByHours() {
    this.dateEndByHoursVisible = false;
    this.dateEndByHours = undefined;
    this.tariffsByHours.map(tariff => tariff.dateEnd = this.dateEndByHours);
  }

  onClickAddTariffButtonByHours() {
    if (
      !this.daysByHours || !this.daysByHours.length ||
      !this.timesByHours || !this.timesByHours.length
    ) {
      return;
    }

    this.daysByHours.forEach(day => {
      const findSchedule = this.schedules.find(schedule => schedule.dayOfWeek === day);
      if (findSchedule) {
        const findScheduleStartHour = +findSchedule.startHour.split(':').shift();
        const findScheduleEndHour = +findSchedule.endHour.split(':').shift();

        const startHour = (this.timesByHours[0] < findScheduleStartHour) ? findScheduleStartHour : this.timesByHours[0];
        const endHour = (this.timesByHours[1] > findScheduleEndHour) ? findScheduleEndHour : this.timesByHours[1];
        for (let hour = startHour; hour <= endHour; hour++) {
          const existingIndex = this.tariffsByHours.findIndex(tariff => {
            return tariff.tariffType === this.tariffType &&
                   tariff.weekDay === day &&
                   tariff.hour === hour;
          });

          if (existingIndex > -1) {
            if (this.priceByHours !== null && this.priceByHours !== undefined) {
              this.tariffsByHours[existingIndex].price = this.priceByHours;
            } else {
              this.tariffsByHours[existingIndex].price = null;
            }
          } else if (this.priceByHours) {
            this.tariffsByHours.push({
              price: this.priceByHours,
              dateStart: this.dateStartByHours,
              dateEnd: this.dateEndByHours,
              hour,
              weekDay: day,
              tariffType: this.tariffType,
              areaId: this.areaId
            });
          }
        }
      }
    });
  }

  onClickSaveButtonByHours() {
    if (
      !this.dateStartByHours ||
      !this.tariffsByHours || !this.tariffsByHours.length
    ) {
      return;
    }

    this.tariffsByHours.sort((prev, next) => prev.weekDay - next.weekDay);
    // TODO add backend for save tariffs
    if (this.mode === 'add') {
      this.tariffService.createTariffs(this.tariffsByHours).toPromise().then(response => { // TODO проверить
        this.showViewForm.emit();
      });
    }

    if (this.mode === 'edit') {
      // TODO refactoring - tariffsByHours as ApiModels.TariffEdit[]
      this.tariffsEdit(this.tariffsByHours as ApiModels.TariffEdit[]);
    }
  }

  onChangeDateStartByHours(value: Date) {
    this.dateStartByHours = value;
    this.tariffsByHours.map(tariff => tariff.dateStart = this.dateStartByHours);
  }

  onChangeDateEndByHours(value: Date) {
    this.dateEndByHours = value;
    this.tariffsByHours.map(tariff => tariff.dateEnd = this.dateEndByHours);
  }

  onChangeTimesByHours(value: number[]) {
    this.timesByHours = value;
  }



  onClickAddDateEndButtonByDays() {
    this.dateEndByDaysVisible = true;
  }

  onClickDeleteDateEndButtonByDays() {
    this.dateEndByDaysVisible = false;
    this.dateEndByDays = undefined;
    this.tariffsByDays.map(tariff => tariff.dateEnd = this.dateEndByDays);
  }

  onClickAddTariffButtonByDays() {
    if (!this.daysByDays || !this.daysByDays.length) {
      return;
    }

    this.daysByDays.forEach(day => {
      const findSchedule = this.schedules.find(schedule => schedule.dayOfWeek === day);
      if (findSchedule) {
        const existingIndex = this.tariffsByDays.findIndex(tariff => {
          return tariff.tariffType === this.tariffType &&
                  tariff.weekDay === day;
        });

        if (existingIndex > -1) {
          if (this.priceByDays !== null && this.priceByDays !== undefined) {
            this.tariffsByDays[existingIndex].price = this.priceByDays;
          } else {
            this.tariffsByDays[existingIndex].price = null;
          }
        } else if (this.priceByDays) {
          this.tariffsByDays.push({
            price: this.priceByDays,
            dateStart: this.dateStartByDays,
            dateEnd: this.dateEndByDays,
            weekDay: day,
            tariffType: this.tariffType,
            areaId: this.areaId
          });
        }
      }
    });
  }

  onClickSaveButtonByDays() {
    if (
      !this.dateStartByDays ||
      !this.tariffsByDays || !this.tariffsByDays.length
    ) {
      return;
    }

    this.tariffsByDays.sort((prev, next) => prev.weekDay - next.weekDay);
    // TODO add backend for save tariffs
    if (this.mode === 'add') {
      this.tariffService.createTariffs(this.tariffsByDays).toPromise().then(response => { // TODO проверить
        this.showViewForm.emit();
      });
    }

    if (this.mode === 'edit') {
      // TODO refactoring - tariffsByDays as ApiModels.TariffEdit[]
      this.tariffsEdit(this.tariffsByDays as ApiModels.TariffEdit[]);
    }

  }

  onChangeDateStartByDays(value: Date) {
    this.dateStartByDays = value;
    this.tariffsByDays.map(tariff => tariff.dateStart = this.dateStartByDays);
  }

  onChangeDateEndByDays(value: Date) {
    this.dateEndByDays = value;
    this.tariffsByDays.map(tariff => tariff.dateEnd = this.dateEndByDays);
  }

  private initTariffs() {
    if (!this.tariffs) {
      return;
    }

    const tariffDateStart = this.tariffs.dateStart;

    this.tariffsByHours = this.tariffs.tariffs.filter(tariff => {
      return tariff.tariffType === 0 && formatDate(tariff.dateStart, 'dd.MM.yyyy', 'ru') === tariffDateStart;
    });
    if (this.tariffsByHours && this.tariffsByHours.length) {
      this.dateStartByHours = new Date(this.tariffsByHours[0].dateStart);
      this.dateEndByHours = new Date(this.tariffsByHours[0].dateEnd);
      this.dateEndByHoursVisible = !!this.dateEndByHours;
    }

    this.tariffsByDays = this.tariffs.tariffs.filter(tariff => {
      return tariff.tariffType === 1 && formatDate(tariff.dateStart, 'dd.MM.yyyy', 'ru') === tariffDateStart;
    });
    if (this.tariffsByDays && this.tariffsByDays.length) {
      this.dateStartByDays =  new Date(this.tariffsByDays[0].dateStart);
      this.dateEndByDays =  new Date(this.tariffsByDays[0].dateEnd);
      this.dateEndByDaysVisible = !!this.dateEndByDays;
    }
  }

  private getMinMaxTimeByHours(schedules: ApiModels.ScheduleInfo[]) {
    this.minTimeByHours = 24;
    this.maxTimeByHours = 0;
    this.schedules.forEach(schedule => {
      const startHour = +schedule.startHour.split(':').shift();
      const endHour = +schedule.endHour.split(':').shift();
      if (startHour < this.minTimeByHours) {
        this.minTimeByHours = startHour;
      }
      if (endHour > this.maxTimeByHours) {
        this.maxTimeByHours = endHour;
      }
    });
  }

  private tariffsEdit(tariffs: ApiModels.TariffEdit[]) {
    const addTariffs = tariffs.filter((tariff) => !tariff.id);
    const editTariffs = tariffs.filter((tariff) => tariff.id && tariff.price !== null);
    const deleteTariffs = tariffs.filter(tariff => tariff.price === null).map((tariff) => tariff.id);

    if (addTariffs && addTariffs.length) {
      this.tariffService.createTariffs(addTariffs).toPromise().then(response => { // TODO проверить
        this.showViewForm.emit();
      });
    }

    if (editTariffs && editTariffs.length) {
      this.tariffService.updateTariffList(editTariffs).toPromise().then(response => {
        this.showViewForm.emit();
      });
    }

    if (deleteTariffs && deleteTariffs.length) {
      this.tariffService.deleteTariffList(deleteTariffs).toPromise().then(response => { // TODO проверить
        this.showViewForm.emit();
      });
    }
  }
}
