import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { MenuModule } from '../shared/menu';
import { ReservationCardModule } from '../shared/reservation-card';
import { RestorationModule } from '../shared/restoration';
import { ToggleModule } from '../shared/toggle';

import { NotificationsComponent } from './notifications/notifications.component';
import { ProfileUserComponent } from './profile-user.component';
import { ProfileUserInfoComponent } from './profile-user-info/profile-user-info.component';
import { ProfileUserInfoViewComponent } from './profile-user-info/profile-user-info-view/profile-user-info-view.component';
import { ProfileUserInfoEditComponent } from './profile-user-info/profile-user-info-edit/profile-user-info-edit.component';

@NgModule({
  declarations: [
    NotificationsComponent,
    ProfileUserComponent,
    ProfileUserInfoComponent,
    ProfileUserInfoViewComponent,
    ProfileUserInfoEditComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    MenuModule,
    ReactiveFormsModule,
    ReservationCardModule,
    RestorationModule,
    RouterModule,
    ToggleModule
  ],
  exports: [
    ProfileUserInfoComponent
  ]
})
export class ProfileUserModule { }
