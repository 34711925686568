import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {

  basicNotifications = [{
    type: 'reservation',
    status: 1,
    header: 'Напоминание о бронировании!',
    color: '#EB595E'
  }, {
    type: 'reservation',
    status: 2,
    header: 'Бронирование подтверждено',
    color: '#00FFCA'
  }, {
    type: 'reservation',
    status: 3,
    header: 'Бронирование завершено',
    color: '#41BBC9'
  }, {
    type: 'review',
    status: 1,
    header: 'Опубликован',
    color: '#0AB21B',
    description: 'Ваш отзыв прошел модерацию и опубликован',
    icon: 'published'
  }, {
    type: 'review',
    status: 2,
    header: 'На рассмотрении',
    color: '#41BBC9',
    description: 'Спасибо за ваш отзыв.',
    icon: 'moderation'
  }, {
    type: 'review',
    status: 3,
    header: 'Не опубликован',
    color: '#EB595E',
    description: 'Ваш отзыв нарушает п. 4.4 Правил Сервиса. Пожалуйста, отредактируйте Ваш отзыв и опубликуйте снова.',
    icon: 'unpublished'
  }, {
  }];

  notifications = [{
    type: 'reservation',
    status: 1,
    read: true,
    title: 'Рабочее место в ателье Эталон',
    description: 'Санкт-Петербург, Средний пр. д. 85'
  }, {
    type: 'review',
    status: 3,
    read: false,
    title: 'Отзыв об ателье Осинка',
    description: 'Ваш отзыв нарушает п. 4.4 Правил Сервиса. Пожалуйста, отредактируйте Ваш отзыв и опубликуйте снова.'
  }, {
    type: 'reservation',
    status: 1,
    read: true,
    title: 'Рабочее место в ателье Эталон',
    description: 'Санкт-Петербург, Средний пр. д. 85'
  }, {
    type: 'review',
    status: 3,
    read: false,
    title: 'Отзыв об ателье Осинка',
    description: 'Ваш отзыв нарушает п. 4.4 Правил Сервиса. Пожалуйста, отредактируйте Ваш отзыв и опубликуйте снова.'
  }, {
    type: 'reservation',
    status: 1,
    read: true,
    title: 'Рабочее место в ателье Эталон',
    description: 'Санкт-Петербург, Средний пр. д. 85'
  }, {
    type: 'review',
    status: 3,
    read: false,
    title: 'Отзыв об ателье Осинка',
    description: 'Ваш отзыв нарушает п. 4.4 Правил Сервиса. Пожалуйста, отредактируйте Ваш отзыв и опубликуйте снова.'
  }, {
    type: 'reservation',
    status: 1,
    read: true,
    title: 'Рабочее место в ателье Эталон',
    description: 'Санкт-Петербург, Средний пр. д. 85'
  }, {
    type: 'review',
    status: 3,
    read: false,
    title: 'Отзыв об ателье Осинка',
    description: 'Ваш отзыв нарушает п. 4.4 Правил Сервиса. Пожалуйста, отредактируйте Ваш отзыв и опубликуйте снова.'
  }, {
    type: 'reservation',
    status: 1,
    read: true,
    title: 'Рабочее место в ателье Эталон',
    description: 'Санкт-Петербург, Средний пр. д. 85'
  }, {
    type: 'review',
    status: 3,
    read: false,
    title: 'Отзыв об ателье Осинка',
    description: 'Ваш отзыв нарушает п. 4.4 Правил Сервиса. Пожалуйста, отредактируйте Ваш отзыв и опубликуйте снова.'
  }, {
    type: 'reservation',
    status: 1,
    read: true,
    title: 'Рабочее место в ателье Эталон',
    description: 'Санкт-Петербург, Средний пр. д. 85'
  }, {
    type: 'review',
    status: 3,
    read: false,
    title: 'Отзыв об ателье Осинка',
    description: 'Ваш отзыв нарушает п. 4.4 Правил Сервиса. Пожалуйста, отредактируйте Ваш отзыв и опубликуйте снова.'
  }, {
    type: 'reservation',
    status: 1,
    read: true,
    title: 'Рабочее место в ателье Эталон',
    description: 'Санкт-Петербург, Средний пр. д. 85'
  }, {
    type: 'review',
    status: 3,
    read: false,
    title: 'Отзыв об ателье Осинка',
    description: 'Ваш отзыв нарушает п. 4.4 Правил Сервиса. Пожалуйста, отредактируйте Ваш отзыв и опубликуйте снова.'
  }, {
    type: 'reservation',
    status: 1,
    read: true,
    title: 'Рабочее место в ателье Эталон',
    description: 'Санкт-Петербург, Средний пр. д. 85'
  }, {
    type: 'review',
    status: 3,
    read: false,
    title: 'Отзыв об ателье Осинка',
    description: 'Ваш отзыв нарушает п. 4.4 Правил Сервиса. Пожалуйста, отредактируйте Ваш отзыв и опубликуйте снова.'
  }, {
    type: 'reservation',
    status: 1,
    read: true,
    title: 'Рабочее место в ателье Эталон',
    description: 'Санкт-Петербург, Средний пр. д. 85'
  }, {
    type: 'review',
    status: 3,
    read: false,
    title: 'Отзыв об ателье Осинка',
    description: 'Ваш отзыв нарушает п. 4.4 Правил Сервиса. Пожалуйста, отредактируйте Ваш отзыв и опубликуйте снова.'
  }, {
    type: 'reservation',
    status: 1,
    read: true,
    title: 'Рабочее место в ателье Эталон',
    description: 'Санкт-Петербург, Средний пр. д. 85'
  }, {
    type: 'review',
    status: 3,
    read: false,
    title: 'Отзыв об ателье Осинка',
    description: 'Ваш отзыв нарушает п. 4.4 Правил Сервиса. Пожалуйста, отредактируйте Ваш отзыв и опубликуйте снова.'
  }];

  constructor() { }

  ngOnInit() {
  }

}
