import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MenuModule } from '../shared/menu';
import { ReservationCardModule } from '../shared/reservation-card';

import { MyReservationsComponent } from './my-reservations.component';

@NgModule({
  declarations: [MyReservationsComponent],
  imports: [
    CommonModule,
    MenuModule,
    ReservationCardModule
  ]
})
export class MyReservationsModule { }
