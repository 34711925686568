import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { formatDate } from '@angular/common';

import * as ApiModels from '../../../../api';

@Component({
  selector: 'app-review-card-user',
  templateUrl: './review-card-user.component.html',
  styleUrls: ['./review-card-user.component.scss']
})
export class ReviewCardUserComponent implements OnInit, OnChanges {
  @Input() item: ApiModels.ReviewInWorkCenter;

  dateDisplayValue: string;

  constructor() { }

  ngOnInit() {

  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.items && this.item) {
      this.dateDisplayValue = formatDate(this.item.dateCreated, 'dd MMMM yyyy', 'ru');
    }
  }

}
