import { Component, OnInit, Input, HostListener, ElementRef } from '@angular/core';

import * as ApiModels from '../../../../api';

@Component({
  selector: 'app-reservation-picker-board-card',
  templateUrl: './reservation-picker-board-card.component.html',
  styleUrls: ['./reservation-picker-board-card.component.scss']
})
export class ReservationPickerBoardCardComponent implements OnInit {
  @Input() reservation: ApiModels.RentView;

  contentVisible: boolean;

  constructor(
    private element: ElementRef
  ) { }

  ngOnInit() {
    this.contentVisible = false;
  }

  isOldReservation(): boolean {
    const today = new Date();
    return new Date(this.reservation.dateEnd) < today;
  }

  isCurrentReservation(): boolean {
    const today = new Date();
    return new Date(this.reservation.dateStart) <= today && today <= new Date(this.reservation.dateEnd);
  }

  isNewReservation(): boolean {
    const today = new Date();
    return new Date(this.reservation.dateStart) > today;
  }

  onChangeContentVisible() {
    this.contentVisible = !this.contentVisible;
  }

  onSetContentVisible(value: boolean) {
    this.contentVisible = value;
  }

  @HostListener('document:click', ['$event'])
    onClick(event: Event) {
      if (!this.element.nativeElement.contains(event.target)) {
        this.contentVisible = false;
      }
    }

}
