import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { CityService, EquipmentsForWorkplaceTypeService } from '../../../api';

import { DatepickerModule } from '../datepicker';
import { TimepickerModule } from '../timepicker';
import { WorkplaceTypesSelectModule } from '../workplace-types-select';

import { SearchFilterComponent } from './search-filter.component';

@NgModule({
  declarations: [SearchFilterComponent],
  imports: [
    CommonModule,
    DatepickerModule,
    FormsModule,
    ReactiveFormsModule,
    TimepickerModule,
    WorkplaceTypesSelectModule
  ],
  exports: [
    SearchFilterComponent
  ],
  providers: [
    CityService,
    EquipmentsForWorkplaceTypeService
  ]
})
export class SearchFilterModule { }
