import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { OrganisationService, PhotoService, WorkCenterService } from '../../api';

import { CustomCarouselModule } from '../shared/custom-carousel';
import { PhotoLoaderModule } from '../shared/photo-loader';
import { RatingModule } from '../shared/rating';
import { TimepickerModule } from '../shared/timepicker';

import { AreaModule } from '../area';

import { WorkCenterComponent } from './workcenter.component';
import { WorkCenterCardComponent } from './workcenter-card/workcenter-card.component';
import { WorkCenterUpsertComponent } from './workcenter-upsert/workcenter-upsert.component';
import { WorkCenterViewComponent } from './workcenter-view/workcenter-view.component';

@NgModule({
  declarations: [
    WorkCenterComponent,
    WorkCenterCardComponent,
    WorkCenterUpsertComponent,
    WorkCenterViewComponent
  ],
  imports: [
    AreaModule,
    CommonModule,
    CustomCarouselModule,
    FormsModule,
    PhotoLoaderModule,
    RatingModule,
    ReactiveFormsModule,
    TimepickerModule
  ],
  exports: [
    WorkCenterComponent
  ],
  providers: [
    OrganisationService,
    PhotoService,
    WorkCenterService
  ]
})
export class WorkCenterModule { }
