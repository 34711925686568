import { Component, OnInit, HostListener, Input, Output, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';

import { ToggleOptions } from '../../../shared/toggle';

import * as ApiModels from '../../../../api';
import { AccountService, RegisterUserService } from '../../../../api';

import { CreateProfileModel } from '../registration.model';

declare const $: any;

@Component({
  selector: 'app-registration-user',
  templateUrl: './registration-user.component.html',
  styleUrls: ['./registration-user.component.scss']
})
export class RegistrationUserComponent implements OnInit {
  @Input() userProfile: CreateProfileModel;
  @Output() back: EventEmitter<void>;
  @Output() userProfileSet: EventEmitter<CreateProfileModel>;
  @Output() showLogin: EventEmitter<void>;

  formBuilder: FormBuilder;
  formFields: FormGroup;
  formError: string;

  sexToggleOptions: ToggleOptions;

  constructor(
    private accountService: AccountService,
    private registerService: RegisterUserService
  ) {
    this.back = new EventEmitter();
    this.userProfileSet = new EventEmitter();
    this.showLogin = new EventEmitter();
  }

  ngOnInit() {
    setTimeout(() => {
      $('.ui.checkbox').checkbox();
    }, 0);

    this.sexToggleOptions = {
      size: 'big',
      labels: {
        left: {
          text: 'М',
          value: 'M'
        },
        right: {
          text: 'Ж',
          value: 'F'
        }
      }
    };
    this.formBuilder = new FormBuilder();
    this.formFields = this.formBuilder.group({
      firstname: new FormControl(this.userProfile ? this.userProfile.firstName : null, [Validators.required]),
      lastname: new FormControl(this.userProfile ? this.userProfile.lastName : null, [Validators.required]),
      phoneNumber: new FormControl(
        this.userProfile ? this.userProfile.phoneNumber : null,
        [
          Validators.required,
          Validators.minLength(11),
          Validators.maxLength(12),
          Validators.pattern(/^(\+7|7|8)?\(?[489][0-9]{2}\)?[0-9]{3}[0-9]{2}[0-9]{2}$/)
          // Validators.pattern(/^(\+7|7|8)?[\s\-]?\(?[489][0-9]{2}\)?[\s\-]?[0-9]{3}[\s\-]?[0-9]{2}[\s\-]?[0-9]{2}$/)
        ]
      ),
      sex: new FormControl(this.userProfile ? this.userProfile.sex : undefined),
      email: new FormControl(this.userProfile ? this.userProfile.email : null, [Validators.required, Validators.email]),
      agreement: new FormControl(false, [Validators.required])
    });
  }

  onChangeSexValue(value: CreateProfileModel['sex']) {
    this.formFields.patchValue({ sex: value });
  }

  onBackButtonClick() {
    this.back.emit();
  }

  onNextButtonClick() {
    if (this.formFields.invalid) {
      return;
    }

    this.formError = undefined;

    this.userProfile = {
      firstName: this.formFields.get('firstname').value,
      lastName: this.formFields.get('lastname').value,
      email: this.formFields.get('email').value,
      sex: this.formFields.get('sex').value,
      phoneNumber: this.formFields.get('phoneNumber').value,
      password: '',
      secretCode: ''
    };

    const phoneNumber = this.formFields.get('phoneNumber').value;
    this.accountService.isEmailExist({ email: this.userProfile.email }).toPromise().then(emailExists => {
      if (!emailExists) {
        this.registerService.getCode(phoneNumber, 1).toPromise().then(response => {
          this.userProfileSet.emit(this.userProfile);
        }).catch(errorResponse => {
          this.formError = errorResponse.error ? errorResponse.error.errorMessage : errorResponse.errorMessage;
        });
      } else {
        this.formError = 'Пользователь с таким email уже зарегистрирован';
      }
    });
  }

  onLoginShow() {
    this.showLogin.emit();
  }
}
