import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';

import * as ApiModels from '../../../../api';
import { OrganisationService } from '../../../../api';

@Component({
  selector: 'app-profile-organisation-info-edit',
  templateUrl: './profile-organisation-info-edit.component.html',
  styleUrls: ['./profile-organisation-info-edit.component.scss']
})
export class ProfileOrganisationInfoEditComponent implements OnInit {
  @Input() organisation: ApiModels.Organisation;
  @Input() organisationTypes: ApiModels.OrganisationType[];
  @Output() saved: EventEmitter<void>;

  formBuilder: FormBuilder;
  formFields: FormGroup;
  formError: string;

  constructor(
    private organisationService: OrganisationService
  ) {
    this.saved = new EventEmitter();
  }

  ngOnInit() {
    this.formBuilder = new FormBuilder();
    this.formFields = this.formBuilder.group({
      legalName: new FormControl(this.organisation.legalName, [Validators.required]),
      organisationType: new FormControl(this.organisation.organisationType, [Validators.required]),
      numberINN: new FormControl(this.organisation.numberINN, [Validators.required]),
      numberOGRN: new FormControl(this.organisation.numberOGRN, [Validators.required]),
      contactPerson: new FormControl(this.organisation.contactPerson, [Validators.required]),
      legalAddress: new FormControl(this.organisation.legalAddress, [Validators.required])
    });
  }

  onSave() {
    if (this.formFields.invalid) {
      // this.errors = [];
      // Object.keys(this.formFields.controls).forEach(key => {
      //   if (this.formFields.controls[key].status === 'INVALID') {
      //     let errorText: string;
      //     if (this.formFields.controls[key].errors.required) {
      //       errorText = 'Это поле обязательно для заполнения';
      //     } else if (this.formFields.controls[key].errors.pattern) {
      //       errorText = 'Это поле некорректно заполнено';
      //     } else if (this.formFields.controls[key].errors.email) {
      //       errorText = 'Это поле некорректно заполнено';
      //     } else if (this.formFields.controls[key].errors.minLength) {
      //       errorText = 'Это поле слишком короткое';
      //     }
      //     this.errors.push({ key: errorText });
      //   }
      // });
      return;
    }
    const editOrganisation: ApiModels.OrganisationEdit = {
      id: this.organisation.id,
      legalName: this.formFields.get('legalName').value,
      organisationType: this.formFields.get('organisationType').value,
      numberINN: this.formFields.get('numberINN').value,
      numberOGRN: this.formFields.get('numberOGRN').value,
      contactPerson: this.formFields.get('contactPerson').value,
      legalAddress: this.formFields.get('legalAddress').value
    };
    this.organisationService.patchOrganisation(editOrganisation).toPromise().then(response => {
      this.saved.emit();
    });
  }
}
