import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import * as ApiModels from '../../api';
import { TariffService, WorkCenterService, WorkplaceService } from '../../api';

import { ToggleOptions } from '../shared/toggle';
import { formatDate } from '@angular/common';

declare const $: any;

@Component({
  selector: 'app-reservations-workcenter',
  templateUrl: './reservations-workcenter.component.html',
  styleUrls: ['./reservations-workcenter.component.scss']
})
export class ReservationsWorkCenterComponent implements OnInit {
  workCenterId: string;
  workCenterName: string;
  tariffType: number;
  tariffTypeToggleOptions: ToggleOptions;
  date: Date;
  workplaceTypeName: string;
  schedules: ApiModels.ScheduleInfo[];
  tariffs: ApiModels.TariffInfo[];
  workplaces: ApiModels.WorkplaceInfoWithRents[];
  workplacesForModal: ApiModels.Workplace[];

  areas: ApiModels.AreaWithEqupmentsTariffsWorkplacesAndRents[];
  reservations: ApiModels.RentsWithName[];

  constructor(
    private activatedRoute: ActivatedRoute,
    private tariffService: TariffService,
    private workCenterService: WorkCenterService,
    private workplaceService: WorkplaceService
  ) { }

  ngOnInit() {
    this.date = new Date();

    this.tariffType = 0;
    this.tariffTypeToggleOptions = {
      labels: {
        left: {
          text: 'По часам',
          value: 0
        },
        right: {
          text: 'По дням',
          value: 1
        },
        style: {
          color: '#7A8EA7',
          fontSize: '14px',
          lineHeight: '20px'
        }
      }
    };

    this.activatedRoute.params.subscribe(routeParams => {
      this.workCenterId = this.activatedRoute.snapshot.parent.paramMap.get('id');
      this.getData();
    });
  }

  onChangeTariffType(value: number) {
    this.tariffType = value;
    this.getData();
  }

  onChangeDate(value: Date) {
    this.date = value;
    this.getData();
  }


  private getData() {
     this.workCenterService.getWorkCenterForOrganizationById(this.workCenterId).toPromise().then(response => {
      this.schedules = response.schedules;
      this.workCenterName = response.name;

      this.areas = response.areas;
      if (this.areas && this.areas.length) {
        const areasIds = this.areas.map(area => area.id);
        this.tariffService.getTariffForOrganizationByAreaId(areasIds, this.tariffType, this.date).toPromise().then(tariffs => {
          this.areas.forEach(area => area.tariffs = []);
          tariffs.forEach(tariff => {
            this.areas.find(area => area.id === tariff.areaId).tariffs.push(tariff) ;
          });
        });

        this.workplaceService.getWorkplaceForOrganizationByAreaId(areasIds, this.tariffType, this.date).toPromise().then(workplaces => {
          this.areas.forEach(area => area.workplaces = []);
          workplaces
            .sort((prev, next) => prev.workplaceNumber - next.workplaceNumber)
            .forEach(workplace => {
              this.areas.find(area => area.id === workplace.areaId).workplaces.push(workplace);

              this.workplacesForModal = [];
              const workplaceForModal: ApiModels.Workplace = {
                id: workplace.id,
                workplaceNumber: workplace.workplaceNumber,
                areaId: workplace.areaId
              };
              this.workplacesForModal.push(workplaceForModal);

              workplace.rents
                .filter(rent => {
                  const date = formatDate(this.date, 'd.M.yyyy', 'ru');

                  if (this.tariffType === 0) {
                    return (rent.tariffType === this.tariffType) && (formatDate(new Date(rent.dateStart), 'd.M.yyyy', 'ru') === date);
                  }

                  const dateEnd = new Date(this.date);
                  dateEnd.setDate(dateEnd.getDate() + 7);
                  return (rent.tariffType === this.tariffType) &&
                        (new Date(rent.dateStart) >= this.date) &&
                        (new Date(rent.dateStart) <= dateEnd);
                })
                .forEach(rent => {
                  this.reservations.push(rent);
                });
            });
        });
      }
    });


  }

  onClickAddReservationButton() {
    $('.ui.modal.reservations-workcenter-adding-form-modal').modal('show');
  }

  rentAdded() {
    this.getData();
    $('.ui.modal.reservations-workcenter-adding-form-modal').modal('hide');
  }
}
