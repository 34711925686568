import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { RegisterUserService, OrganisationService } from '../../../api';

import { ToggleModule } from '../toggle';

import { RegistrationCodeComponent } from './registration-code/registration-code.component';
import { RegistrationComponent } from './registration.component';
import { RegistrationEndComponent } from './registration-end/registration-end.component';
import { RegistrationOrganisationComponent } from './registration-organisation/registration-organisation.component';
import { RegistrationPasswordComponent } from './registration-password/registration-password.component';
import { RegistrationStartComponent } from './registration-start/registration-start.component';
import { RegistrationUserComponent } from './registration-user/registration-user.component';

@NgModule({
  declarations: [
    RegistrationCodeComponent,
    RegistrationComponent,
    RegistrationEndComponent,
    RegistrationOrganisationComponent,
    RegistrationPasswordComponent,
    RegistrationStartComponent,
    RegistrationUserComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    ToggleModule
  ],
  exports: [
    RegistrationComponent
  ],
  providers: [
    OrganisationService,
    RegisterUserService
  ]
})
export class RegistrationModule { }
