import { Component, OnChanges, SimpleChanges, Input, Output, EventEmitter } from '@angular/core';

import * as ApiModels from '../../../../api';

declare const $: any;

@Component({
  selector: 'app-profile-organisation-info-view',
  templateUrl: './profile-organisation-info-view.component.html',
  styleUrls: ['./profile-organisation-info-view.component.scss']
})
export class ProfileOrganisationInfoViewComponent implements OnChanges {
  @Input() organisation: ApiModels.Organisation;
  @Input() organisationTypes: ApiModels.OrganisationType[];
  @Output() showEditForm: EventEmitter<void>;

  organisationType: string;

  constructor() {
    this.showEditForm = new EventEmitter<void>();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.organisation || changes.organisationTypes) {
      if (this.organisation && this.organisationTypes) {
        this.organisationType = this.organisationTypes.find(organisationType => {
          return organisationType.code === this.organisation.organisationType;
        }).name;
      }
    }
  }

  onResetPassword() {
    $('.ui.modal.reset-password-modal').modal('show');
  }

  onShowEditForm() {
    this.showEditForm.emit();
  }
}
