import { Component, OnInit, Input, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';

import * as ApiModels from '../../../api';

import { ToggleOptions } from '../../shared/toggle';

import { TariffGroupedByDateAndType } from '../tariff.model';

declare const $: any;

@Component({
  selector: 'app-tariff-view',
  templateUrl: './tariff-view.component.html',
  styleUrls: ['./tariff-view.component.scss']
})
export class TariffViewComponent implements OnInit, OnChanges {
  @Input() tariffs: TariffGroupedByDateAndType[];
  @Input() schedules: ApiModels.ScheduleInfo[];
  @Output() showAddingForm: EventEmitter<void>;
  @Output() showEditingForm: EventEmitter<TariffGroupedByDateAndType>;

  statusToggleOptions: ToggleOptions;
  status: boolean;

  visibledTariffs: TariffGroupedByDateAndType[];

  constructor() {
    this.showAddingForm = new EventEmitter();
    this.showEditingForm = new EventEmitter();
    this.visibledTariffs = [];
  }

  ngOnInit() {
    this.status = true;
    this.statusToggleOptions = {
      labels: {
        left: {
          text: 'Активные',
          value: true
        },
        right: {
          text: 'Не активные',
          value: false
        },
        style: {
          color: '#7A8EA7',
          fontSize: '14px'
        }
      }
    };

    setTimeout(() => {
      $('.ui.fluid.accordion.tariffs-list')
        .accordion({
          selector: {
            trigger: '.title'
          }
        });
    }, 0);

    this.setVisibledTariffs();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.tariffs) {
      this.setVisibledTariffs();
    }
  }

  onChangeStatus(value: boolean) {
    this.status = value;
    this.setVisibledTariffs();
  }

  onClickAddTariffButton() {
    this.showAddingForm.emit();
  }

  onClickEditTariffButton(tariffs: TariffGroupedByDateAndType) {
    this.showEditingForm.emit(tariffs);
  }

  private setVisibledTariffs() {
    if (!this.tariffs) {
      return;
    }

    this.visibledTariffs = this.tariffs.filter(tariff => {
      const today = new Date();
      if (this.status) {
        return new Date(tariff.dateStartValue) <= today && today <= new Date(tariff.dateEndValue);
      } else {
        return new Date(tariff.dateStartValue) > today || today > new Date(tariff.dateEndValue);
      }
    });
  }
}
