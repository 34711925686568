import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CarouselModule } from 'ngx-owl-carousel-o';

import { WorkplaceTypesInfoService } from '../../../api';

import { CustomCarouselComponent } from './custom-carousel.component';

@NgModule({
  declarations: [CustomCarouselComponent],
  imports: [
    CarouselModule,
    CommonModule,
  ],
  exports: [
    CustomCarouselComponent,
  ],
  providers: [
    WorkplaceTypesInfoService
  ]
})
export class CustomCarouselModule { }
