import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { formatDate } from '@angular/common';

import * as ApiModels from '../../../api';
import { RentService } from '../../../api';

import { RentsWithName } from './reservations-workcenter-list.model';

@Component({
  selector: 'app-reservations-workcenter-list',
  templateUrl: './reservations-workcenter-list.component.html',
  styleUrls: ['./reservations-workcenter-list.component.scss']
})
export class ReservationsWorkCenterListComponent implements OnInit, OnChanges {
  @Input() items: RentsWithName[];

  tempUserNotes: string;

  constructor(
    private rentService: RentService
  ) { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.items && this.items && this.items.length) {
      this.items.sort((prev, next) => new Date(prev.dateStart).getTime() - new Date(next.dateStart).getTime());

      this.items.map(item => {
        item.contactPhoneDisplay = this.getContactPhoneDisplay(item.contactPhone);
        item.dateStartDisplay = this.getDateStartDisplay(item.dateStart);
        item.timeDisplay = this.getTimeDisplay(item.dateStart, item.dateEnd);
        item.durationDisplay = this.getDurationDisplay(item.dateStart, item.dateEnd);

        return item;
      });
    }
  }

  isOldReservation(item: RentsWithName): boolean {
    const today = new Date();
    return new Date(item.dateEnd) < today;
  }

  isCurrentReservation(item: RentsWithName): boolean {
    const today = new Date();
    return new Date(item.dateStart) <= today && today <= new Date(item.dateEnd);
  }

  isNewReservation(item: RentsWithName): boolean {
    const today = new Date();
    return new Date(item.dateStart) > today;
  }

  onClickShowNoteButton(id: string) {
    this.items.map(item => {
      item.noteVisible = false;
      return item;
    });
    const findItem = this.items.find(item => item.id === id);
    this.tempUserNotes = findItem.userNotes;
    findItem.noteVisible = true;
  }

  onClickSaveNoteButton(id: string) {
    const note: ApiModels.NoteAdd = { rentId: id, userNotes: this.tempUserNotes };
    this.rentService.addNote(note).toPromise().then(response => {
    });

    this.tempUserNotes = undefined;
  }

  private getContactPhoneDisplay(contactPhone: string) {
    if (!contactPhone) {
      return '';
    }

    let contactPhoneDisplay = '';
    const firstDigit = +contactPhone[0];
    contactPhoneDisplay = (firstDigit === 7 || firstDigit === 8) ? `+7` : `${firstDigit}`;
    contactPhoneDisplay += ' ';
    contactPhoneDisplay += contactPhone.slice(1, 4);
    contactPhoneDisplay += ' ';
    contactPhoneDisplay += contactPhone.slice(4, 7);
    contactPhoneDisplay += '-';
    contactPhoneDisplay += contactPhone.slice(7, 9);
    contactPhoneDisplay += '-';
    contactPhoneDisplay += contactPhone.slice(9);

    return contactPhoneDisplay;
  }

  private getDateStartDisplay(dateStart: Date) {
    if (!dateStart) {
      return '';
    }

    const dateStartDisplay = formatDate(new Date(dateStart), 'dd MMMM yyyy', 'ru');

    return dateStartDisplay;
  }

  private getTimeDisplay(dateStart: Date, dateEnd: Date) {
    if (!dateStart) {
      return '';
    }

    const timeStart = formatDate(new Date(dateStart), 'HH:mm', 'ru');
    const timeEnd = formatDate(new Date(dateEnd), 'HH:mm', 'ru');
    const timeDisplay = `${timeStart}–${timeEnd}`;

    return timeDisplay;
  }

  private getDurationDisplay(dateStart: Date, dateEnd: Date) {
    if (!dateStart) {
      return '';
    }

    const hourStart = new Date(dateStart).getHours();
    const hourEnd = new Date(dateEnd).getHours();
    const hoursCount = hourEnd - hourStart;
    const durationDisplay = `${hoursCount} ${
      (hoursCount % 20 === 1) ? ' час' : ((hoursCount % 20 === 0 || hoursCount % 20 < 5) ? ' часа' : ' часов')
    }`;

    return durationDisplay;
  }

}
