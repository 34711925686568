import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';

import { Menu } from './menu.model';

declare const $: any;

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit, OnDestroy {

  @Input() items: Menu[];

  routerSubscription: Subscription;

  constructor(private router: Router) { }

  ngOnInit() {
    setTimeout(() => {
      $('.ui.accordion').accordion();
    }, 0);

    this.setActive(this.router.url);
    this.routerSubscription = this.router.events.subscribe(e => {
      if (e instanceof NavigationEnd) {
        this.setActive(e.url);
      }
    });
  }

  ngOnDestroy() {
    this.routerSubscription.unsubscribe();
  }

  private setActive(url: string): void {
    if (!this.items) {
      return;
    }

    const decodedUrl = decodeURIComponent(url);

    this.items = this.items.map(item => {
      item.active = item.link === decodedUrl;
      if (item.items) {
        item.items.forEach(subitem => {
          subitem.active = subitem.link === decodedUrl;
          if (subitem.active) {
            item.active = true;
          }
        });
      }

      return item;
    });
  }
}
