import { Component, OnInit } from '@angular/core';

import { ReservationCardOptions } from '../shared/reservation-card';

import * as ApiModels from '../../api';
import { RentService } from '../../api';

declare const $: any;

@Component({
  selector: 'app-my-reservations',
  templateUrl: './my-reservations.component.html',
  styleUrls: ['./my-reservations.component.scss']
})
export class MyReservationsComponent implements OnInit {

  reservations: ApiModels.RentCurrentWithWorkcenter[];

  reservationCardOptions: ReservationCardOptions;

  constructor(
    private rentService: RentService
  ) {
    const top = 5;
    this.rentService.currentWithCenter(top).toPromise().then(data => {
      this.reservations = data;
    });
  }

  ngOnInit() {
    this.reservationCardOptions = {
      mode: 'view',
      status: {
        visible: true
      },
      rules: {
        visible: true
      },
      actions: {
        message: {
          visible: false
        },
        note: {
          visible: true
        },
        footer: {
          visible: true
        }
      }
    };
  }

}
