import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { DatepickerModule } from '../datepicker';
import { TimepickerModule } from '../timepicker';
import { WorkplaceTypesSelectModule } from '../workplace-types-select';

import { SearchFormComponent } from './search-form.component';

@NgModule({
  declarations: [SearchFormComponent],
  imports: [
    CommonModule,
    DatepickerModule,
    FormsModule,
    ReactiveFormsModule,
    TimepickerModule,
    WorkplaceTypesSelectModule
  ],
  exports: [
    SearchFormComponent
  ]
})
export class SearchFormModule { }
