import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

import * as ApiModels from '../../api';
import { RentService } from '../../api';

import { AuthService } from '../auth';

import { ReservationCardOptions } from '../shared/reservation-card';

declare const $: any;

@Component({
  selector: 'app-reservation-confirmation',
  templateUrl: './reservation-confirmation.component.html',
  styleUrls: ['./reservation-confirmation.component.scss']
})
export class ReservationConfirmationComponent implements OnInit, OnDestroy {
  rentIds: string[];
  items: ApiModels.RentCurrentWithWorkcenterAndEquipment[];
  notConfirmedReservationCardOptions: ReservationCardOptions;
  confirmedReservationCardOptions: ReservationCardOptions;

  agreement: boolean;
  confirmed: boolean;

  userLoggined: boolean;
  loginSubscribe: Subscription;

  constructor(
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private rentService: RentService
  ) {
    this.confirmed = false;
  }

  ngOnInit() {
    this.userLoggined = this.authService.isLoggedIn();

    this.activatedRoute.queryParams.subscribe(params => {
      this.rentIds = (typeof(params.ids) === 'string') ? [params.ids] : params.ids;
      this.rentService.reservedWithCenterAndEquipment(this.rentIds).toPromise().then(response => {
        if (response.length) {
          this.items = response.sort((prev, next) => new Date(prev.dateStart).getTime() - new Date(next.dateStart).getTime());
        }
      });
    });


    this.notConfirmedReservationCardOptions = {
      mode: 'preview',
      rules: {
        visible: true
      },
      equipment: {
        visible: true
      }
    };

    this.confirmedReservationCardOptions = {
      mode: 'preview',
      rules: {
        visible: true
      },
      actions: {
        footer: {
          visible: true
        }
      }
    };
  }

  ngOnDestroy() {
    if (this.loginSubscribe) {
      this.loginSubscribe.unsubscribe();
    }
  }

  confirm() {
    this.rentService.rentConfirm(this.rentIds).toPromise().then(response => {
      this.confirmed = true;
    });
  }

  loginAndConfirm() {
    $('.ui.modal.login-modal').modal('show');
    this.loginSubscribe = this.authService.loggedIn.subscribe(res => {
      this.userLoggined = res;

      if (res) {
        this.confirm();
        this.loginSubscribe.unsubscribe();
      }
    });
  }
}
