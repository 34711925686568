import { Component, OnInit } from '@angular/core';

declare const $: any;

@Component({
  selector: 'app-my-reviews',
  templateUrl: './my-reviews.component.html',
  styleUrls: ['./my-reviews.component.scss']
})
export class MyReviewsComponent implements OnInit {

  reviews: any;

  constructor() { }

  ngOnInit() {

    this.reviews = [{
      status: 1,
      note: 'test-1',
      src: '../../../../assets/images/test-image-1.jpg',
      response: 'Екатерина, мы признательны вам за выбор нашего ателье и благодарим вас за высокую оценку. Очень рады что работа у нас была для вас комфортна и ждем вас снова в ателье Эталон. А вывеску мы уже заказали, скоро нас будет видно издалека :)'
    }, {
      status: 1,
      note: '',
      response: 'Это ответ объекта. бла-бла-бла'
    }, {
      status: 2,
      note: 'test-2'
    }, {
      status: 3,
      note: 'test-3'
    }, {
      status: 1,
      note: ''
    }, {
      status: 2,
      note: 'test-2'
    }, {
      status: 3,
      note: 'test-3'
    }];
  }

}
