import { Injectable } from '@angular/core';
import { Router, NavigationExtras } from '@angular/router';
import { HttpClient, HttpRequest } from '@angular/common/http';
import { Subject } from 'rxjs';
import { map } from 'rxjs/operators';

import * as ApiModels from '../../api';
import { TokenService, AccountService, OrganisationService } from '../../api';

import { CreateProfileModel } from '../shared/registration';


@Injectable()
export class AuthService {

  token: string;
  refreshToken: string;

  cachedRequests: Array<HttpRequest<any>> = [];

  loggedIn: Subject<boolean>;

  constructor(
    private http: HttpClient,
    private router: Router,
    private tokenService: TokenService,
    private accountService: AccountService,
    private organisationService: OrganisationService
  ) {
    this.loggedIn = new Subject<boolean>();
  }

  setToken(token: string) {
    this.token = token;
    window.localStorage.setItem('token', token);
  }

  getToken() {
    return this.token ? this.token : window.localStorage.getItem('token');
  }

  setRefreshToken(refreshToken: string) {
    this.refreshToken = refreshToken;
    window.localStorage.setItem('refreshToken', refreshToken);
  }

  getRefreshToken() {
    return this.refreshToken ? this.refreshToken : window.localStorage.getItem('refreshToken');
  }

  removeAllTokens() {
    window.localStorage.removeItem('token');
    window.localStorage.removeItem('refreshToken');
    this.token = undefined;
    this.refreshToken = undefined;
  }

  getNewToken() {
    const token = this.getToken();
    const refreshToken = this.getRefreshToken();
    return this.tokenService.refresh({
      token,
      refreshToken
    }).toPromise().then((res: any) => {
      this.token = res.token;
      if (this.token) {
        this.setToken(this.token);
      }
    });
  }

  isLoggedIn() {
    const token = this.getToken();
    return token !== null && token !== undefined && token !== '';
  }

  registrationUser(userProfile: CreateProfileModel) {

    return this.accountService.postCreateProfile(userProfile).toPromise().then(response => {
      this.setToken(response.token);
      this.setRefreshToken(response.refreshToken);
      this.loggedIn.next(true);
    }).catch(error => {

    });
  }

  registrationOrganisation(organisationProfile: ApiModels.OrganisationAdd) {
    return this.organisationService.createOrganisation(organisationProfile).toPromise();
  }

  login(username: string, password: string) {
    return this.tokenService.getToken({
      username,
      password
    }).pipe(map(response => {
      this.setToken(response.token);
      this.setRefreshToken(response.refreshToken);
      this.loggedIn.next(true);
      return response;
    }));
  }

  logout() {
    this.removeAllTokens();

    // if (!this.router) {
    //   window.location.href = '/login'; // #TODO use 'location.href' because the 'router' does not always exist
    //   return;
    // }

    // let queryParamsHandling: 'merge' | 'preserve' | ''; // #TODO The QueryParamsHandling type is not exported from @angular/router
    // queryParamsHandling  = 'merge';

    // const returnUrl = this.router.url;
    // if (returnUrl.indexOf('returnUrl') !== -1) {
    //   queryParamsHandling = 'preserve';
    // }

    // const navigationExtras: NavigationExtras = {
    //   queryParams: { returnUrl },
    //   queryParamsHandling,
    //   preserveFragment: true
    // };
    // this.router.navigate(['/login'], navigationExtras);
  }

  collectFailedRequest(request: any) {
    this.cachedRequests.push(request);
  }

  retryFailedRequests() {
    // retry the requests. this method can
    // be called after the token is refreshed
  }
}
