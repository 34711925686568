import { Component, OnInit, Output, EventEmitter, Input, OnChanges, SimpleChanges } from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';

import * as ApiModels from '../../../api';
import { WorkplaceTypeService } from '../../../api';

import { WorkplaceType } from './workplace-types.model';

@Component({
  selector: 'app-workplace-types-select',
  templateUrl: './workplace-types-select.component.html',
  styleUrls: ['./workplace-types-select.component.scss']
})
export class WorkplaceTypesSelectComponent implements OnInit, OnChanges {
  @Input() selectedIds: string[];

  @Output() changeSelectedWorkplaceTypes: EventEmitter<WorkplaceType[]>;

  workplaceTypeGroups: ApiModels.WorkplaceTypeByCategory[];
  workplaceTypes: WorkplaceType[];
  selectedWorkplaceTypes: WorkplaceType[];
  displayValue: string;
  popupVisible: boolean;

  constructor(
    public breakpointObserver: BreakpointObserver,
    private workplaceTypeService: WorkplaceTypeService
  ) {
    this.selectedWorkplaceTypes = [];
    this.popupVisible = false;
    this.changeSelectedWorkplaceTypes = new EventEmitter<WorkplaceType[]>();
  }

  ngOnInit() {
    this.workplaceTypeService.getGrouped().toPromise().then(workplaceTypeGroups => {
      if (workplaceTypeGroups) {
        this.workplaceTypeGroups = workplaceTypeGroups
                                    .filter(workplaceTypeGroup => workplaceTypeGroup.category)
                                    .map(group => {
                                      group.workplaceTypes.sort((a, b) => a.sortOrder - b.sortOrder);
                                      return group;
                                    });

        this.workplaceTypes = this.getWorkplaceTypes(this.workplaceTypeGroups);
      }

      if (this.selectedIds && this.selectedIds.length) {
        this.updateValuesByIds(this.selectedIds);
        this.changeSelectedWorkplaceTypes.emit(this.selectedWorkplaceTypes);
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    this.refresh();
  }

  popupOpen() {
    if (this.popupVisible) {
      return;
    }

    this.popupVisible = true;
    setTimeout(() => this.refresh(), 0);
  }

  popupClose() {
    this.popupVisible = false;

    this.changeSelectedWorkplaceTypes.emit(this.selectedWorkplaceTypes);
  }

  refresh() {
    if (!this.selectedIds) {
      return;
    }

    this.setCheckBoxes(this.selectedIds);
  }

  updateDisplayValue() {
    const ids: string[] = [];
    const checkboxes = document.getElementsByName('workplaceType') as NodeListOf<HTMLInputElement>;
    checkboxes.forEach(checkbox => {
      if (checkbox.checked) {
        ids.push(checkbox.value);
      }
    });

    this.selectedIds = ids;
    this.updateValuesByIds(this.selectedIds);
  }

  private getWorkplaceTypes(typeGroups: ApiModels.WorkplaceTypeByCategory[]): WorkplaceType[] {
    const types: WorkplaceType[] = [];
    typeGroups.forEach(typeGroup => {
      typeGroup.workplaceTypes.forEach(item => {
        types.push(item);
      });
    });

    return types;
  }

  private setCheckBoxes(ids: string[]) {
    ids.forEach(id => {
      const checkbox = document.querySelector('#workplaceType_' + id) as HTMLInputElement;

      if (!checkbox) {
        return;
      }

      checkbox.checked = true;
    });
  }

  private updateValuesByIds(ids: string[]) {
    const displayValues = [];
    const selectedWorkplaceTypes: WorkplaceType[] = [];
    ids.forEach(id => {
      const name = this.workplaceTypes.find(type => type.id === id).name;
      if (name) {
        displayValues.push(name);
        selectedWorkplaceTypes.push({ id, name });
      }
    });
    this.displayValue = displayValues.join(', ');
    this.selectedWorkplaceTypes = selectedWorkplaceTypes;
  }
}
