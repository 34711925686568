import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import * as ApiModels from '../../api';
import { RentService } from '../../api';

import { ReservationCardOptions } from '../shared/reservation-card';

@Component({
  selector: 'app-reservation-cancellation',
  templateUrl: './reservation-cancellation.component.html',
  styleUrls: ['./reservation-cancellation.component.scss']
})
export class ReservationCancellationComponent implements OnInit {
  reservationId: string;
  reservationItem: ApiModels.RentCurrentWithWorkcenterAndEquipment;
  reservationCardOptions: ReservationCardOptions;

  mode: 'canceled' | 'inProcess';
  loading: boolean;

  constructor(
    private route: ActivatedRoute,
    private rentService: RentService
  ) {
    this.mode = 'inProcess';
  }

  ngOnInit() {
    this.reservationId = this.route.snapshot.paramMap.get('id');
    this.rentService.currentWithCenterAndEquipment(Array(this.reservationId)).toPromise().then(response => {
      if (response.length) {
        this.reservationItem = response[0];
      }
    });

    this.reservationCardOptions = {
      mode: 'view',
      actions: {
        message: {
          visible: false
        }
      }
    };
  }

  cancelReservation() {
    this.loading = true;
    this.mode = 'canceled';
    this.rentService.rentCancel(Array(this.reservationId)).toPromise().then(response => {
      this.loading = false;
    });
  }
}
