import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';

import { ReservationCardOptions } from '../shared/reservation-card';

import { ToggleOptions } from '../shared/toggle';

import * as ApiModels from '../../api';
import { OrganisationService, RentService } from '../../api';

declare const $: any;

@Component({
  selector: 'app-profile-organisation',
  templateUrl: './profile-organisation.component.html',
  styleUrls: ['./profile-organisation.component.scss']
})
export class ProfileOrganisationComponent implements OnInit, OnDestroy {
  @Input() organisation: ApiModels.Organisation;
  routerSubscription: Subscription;

  cancelPaidRentToggleOptions: ToggleOptions;
  editObjectToggleOptions: ToggleOptions;
  addDiscountsToggleOptions: ToggleOptions;

  formBuilder: FormBuilder;
  formFields: FormGroup;
  formError: string;
  adminFormSubmitted: boolean;

  cities = [
    'Москва',
    'Москва',
    'Москва',
    'Москва',
    'Москва',
    'Москва',
    'Москва',
    'Москва',
    'Москва'
  ];

  themes = [
    'Массаж',
    'Массаж',
    'Массаж',
    'Массаж',
    'Массаж',
    'Массаж',
    'Массаж',
    'Массаж',
    'Массаж',
    'Массаж',
    'Массаж',
    'Массаж',
    'Массаж',
    'Массаж',
    'Массаж',
    'Массаж',
    'Массаж'
  ];

  formats = [
    'Мастер-классы',
    'Мастер-классы',
    'Мастер-классы',
    'Мастер-классы',
    'Мастер-классы',
    'Мастер-классы',
    'Мастер-классы',
    'Мастер-классы',
    'Мастер-классы'
  ];

  workplaces = [
    'Место парикмахера',
    'Место парикмахера',
    'Место парикмахера',
    'Место парикмахера',
    'Место парикмахера',
    'Место парикмахера',
    'Место парикмахера',
    'Место парикмахера',
    'Место парикмахера',
    'Место парикмахера',
    'Место парикмахера',
    'Место парикмахера',
    'Место парикмахера',
    'Место парикмахера',
    'Место парикмахера',
    'Место парикмахера',
    'Место парикмахера',
    'Место парикмахера',
    'Место парикмахера',
    'Место парикмахера',
    'Место парикмахера',
    'Место парикмахера',
    'Место парикмахера',
    'Место парикмахера',
    'Место парикмахера',
    'Место парикмахера',
    'Место парикмахера',
    'Место парикмахера',
    'Место парикмахера',
    'Место парикмахера',
    'Место парикмахера',
    'Место парикмахера',
    'Место парикмахера',
    'Место парикмахера',
    'Место парикмахера',
    'Место парикмахера',
    'Место парикмахера',
    'Место парикмахера',
    'Место парикмахера',
    'Место парикмахера'
  ];

  constructor() {
  }

  ngOnInit() {
    // this.cancelPaidRentToggleOptions = {
    //   labels: {
    //     left: {
    //       text: 'Отмена оплаченой брони',
    //       // value: 'false'
    //     },
    //     // right: {
    //     //   text: 'Ж',
    //     //   value: 'true'
    //     // }
    //   }
    // };
    // this.editObjectToggleOptions = {
    //   labels: {
    //     left: {
    //       text: 'М',
    //       value: 'M'
    //     },
    //     right: {
    //       value: 'F'
    //     }
    //   }
    // };
    // this.addDiscountsToggleOptions = {
    //   labels: {
    //     left: {
    //       text: 'М',
    //       value: 'M'
    //     },
    //     right: {
    //       value: 'F'
    //     }
    //   }
    // };

    // this.formBuilder = new FormBuilder();
    // this.formFields = this.formBuilder.group({
    //   name: new FormControl('', [Validators.required]),
    //   phone: new FormControl('', [Validators.required]),
    //   email: new FormControl('', [Validators.required]),
    //   cancelPaidRent: new FormControl()
    // });

    // $('.ui.checkbox').checkbox();

    // $('.ui.dropdown').dropdown();
  }

  ngOnDestroy() {
    // this.routerSubscription.unsubscribe();
  }

  // onChangeCancelPaidRentValue(e) {
  //   console.log(e);
  // }

  // isFormControlHasError(controlName: string) {
  //   const control = this.workCenterForm.get(controlName);
  //   if (!control) {
  //     return;
  //   }

  //   return this.workCenterForm.get(controlName).errors && this.workCenterFormSubmitted ||
  //          this.workCenterForm.get(controlName).errors && (this.workCenterForm.get(controlName).dirty || this.workCenterForm.get(controlName).touched);
  // }

  // onSubmitFormHandler() {
  //   this.workCenterFormSubmitted = true;
  //   this.validateForm();
  // }

  // private validateForm() {
  //   if (!this.workCenterForm.valid) {
  //     return false;
  //   }
  //   return true;
  // }
}
