import { Component, OnInit, HostListener, Output, EventEmitter, Input } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';

declare const $: any;

@Component({
  selector: 'app-review-modal',
  templateUrl: './review-modal.component.html',
  styleUrls: ['./review-modal.component.scss']
})
export class ReviewModalComponent implements OnInit {

  @Input() visible: boolean;
  @Input() editReview: boolean;

  formBuilder: FormBuilder;
  formFields: FormGroup;
  formError: string;

  constructor() { }

  ngOnInit() {
    $('#object-rating').slider({
      min: 1,
      max: 10,
      start: 10,
      step: 1,
      smooth: true
    });
    $('#workplace-rating').slider({
      min: 1,
      max: 10,
      start: 10,
      step: 1,
      smooth: true
    });
    $('#equipment-rating').slider({
      min: 1,
      max: 10,
      start: 10,
      step: 1,
      smooth: true
    });
    $('#personnel-rating').slider({
      min: 1,
      max: 10,
      start: 10,
      step: 1,
      smooth: true
    });

    this.formBuilder = new FormBuilder();
    this.formFields = this.formBuilder.group({
      login: new FormControl(null, Validators.required),
      password: new FormControl(null, [
        Validators.required,
        Validators.minLength(8),
        // Validators.pattern('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$')
      ]),
      rememberMe: new FormControl(null)
    }, {
      // validator: this.checkLogin
    });
  }

  close() {
    this.visible = false;
    $('.ui.modal').modal('hide');
  }

}
