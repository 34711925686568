import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';

import { UserType } from '../registration.model';

declare const $: any;

@Component({
  selector: 'app-registration-end',
  templateUrl: './registration-end.component.html',
  styleUrls: ['./registration-end.component.scss']
})
export class RegistrationEndComponent implements OnInit {
  @Input() userType: UserType['name'];
  @Input() organisationId: string;
  @Output() closed: EventEmitter<void>;

  constructor(
    private router: Router
  ) {
    this.closed = new EventEmitter();
  }

  ngOnInit() {
  }

  close() {
    this.closed.emit();
  }

  gotoSearch() {
    this.close();
    this.router.navigate(['/search']);
  }

  gotoAddObject() {
    this.close();
    if (this.organisationId) {
      this.router.navigate([`/profile/organisation/${this.organisationId}/workcenter/add`]);
    }
  }

  gotoProfile() {
    this.close();
    this.router.navigate(['/profile/user']);
  }
}
