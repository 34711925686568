import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';

import * as ApiModels from '../../../../api';
import { AccountService, OrganisationService } from '../../../../api';

import { AuthService } from '../../../auth';

import { CreateProfileModel, UserType } from '../registration.model';

declare const $: any;

@Component({
  selector: 'app-registration-password',
  templateUrl: './registration-password.component.html',
  styleUrls: ['./registration-password.component.scss']
})
export class RegistrationPasswordComponent implements OnInit {
  @Input() userType: UserType['name'];
  @Input() userProfile: CreateProfileModel;
  @Input() organisationProfile: ApiModels.OrganisationAdd;
  @Output() registrationEnded: EventEmitter<string>;

  formBuilder: FormBuilder;
  formFields: FormGroup;
  formError: string;

  visiblePassword: boolean;

  passwordRulesHtml = `
    <ul style="list-style: inside;">
      <li>хотя бы одна цифра</li>
      <li>хотя бы одна буква в верхнем регистре</li>
      <li>длина пароля не меньше 6</li>
      <li>не меньше 6 уникальных символов</li>
    </ul>
  `;

  constructor(
    private authService: AuthService,
    private accountService: AccountService,
    private organisationService: OrganisationService
  ) {
    this.registrationEnded = new EventEmitter();
    this.visiblePassword = false;
  }

  ngOnInit() {
    this.formBuilder = new FormBuilder();
    this.formFields = this.formBuilder.group({
      password: new FormControl(null, [
        Validators.required,
        Validators.pattern(/^(?=^.{6,}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/)
      ]),
      confirmPassword: new FormControl(null, [Validators.required])
    }, {
      validator: this.checkPasswords
    });

    setTimeout(() => {
      $('.form-field.form-field-password').popup();
    }, 0);
  }

  onRegisterButtonClick() {
    this.userProfile.password = this.formFields.get('password').value;

    this.authService.registrationUser(this.userProfile).then(userResponse => {
      if (this.userType === 'owner') {
        this.authService.registrationOrganisation(this.organisationProfile).then(organisationResponse => {
          this.registrationEnded.emit(organisationResponse.id);
        }).catch(errorResponse => {
          this.formError = errorResponse.error ? errorResponse.error.errorMessage : errorResponse.errorMessage;
        });
      } else {
        this.registrationEnded.emit();
      }
    }).catch(error => {

    });
  }

  changeVisiblePassword() {
    this.visiblePassword = !this.visiblePassword;
  }

  private checkPasswords(group: FormGroup) {
    const passControl = group.controls.password;
    const confirmPassControl = group.controls.confirmPassword;
    return passControl.value === confirmPassControl.value ?
      confirmPassControl.setErrors(null) : confirmPassControl.setErrors({ notEquivalent: true });
  }
}
