import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { UserService } from '../../api';

import { BreadcrumbModule   } from './breadcrumb';
import { CityPickerModule } from './city-picker';
import { CustomCarouselModule } from './custom-carousel';
import { DatepickerModule   } from './datepicker';
import { FeedbackFormModule } from './feedback-form';
import { FooterComponent    } from './footer/footer.component';
import { HeaderComponent    } from './header/header.component';
import { LoginModule        } from './login';
import { MapModule } from './map/map.module';
import { MenuModule         } from './menu';
import { NavigationModule   } from './navigation';
import { PhotoLoaderModule } from './photo-loader';
import { RatingModule } from './rating';
import { RegistrationModule } from './registration';
import { ReservationCardModule } from './reservation-card';
import { ReservationPickerModule } from './reservation-picker';
import { ResetPasswordModule } from './reset-password';
import { RestorationModule  } from './restoration';
import { ReviewsModule  } from './reviews';
import { SearchFilterModule   } from './search-filter';
import { SearchFormModule   } from './search-form';
import { SearchResultModule } from './search-result';
import { TimepickerModule } from './timepicker';
import { ToggleModule } from './toggle';
import { WorkplaceTypesSelectModule } from './workplace-types-select';

@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent
  ],
  imports: [
    BreadcrumbModule,
    DatepickerModule,
    CityPickerModule,
    CommonModule,
    CustomCarouselModule,
    FeedbackFormModule,
    LoginModule,
    NavigationModule,
    PhotoLoaderModule,
    RatingModule,
    RegistrationModule,
    ReservationPickerModule,
    ResetPasswordModule,
    RestorationModule,
    RouterModule,
    TimepickerModule,
    ToggleModule,
    WorkplaceTypesSelectModule
  ],
  exports: [
    CityPickerModule,
    CustomCarouselModule,
    DatepickerModule,
    FeedbackFormModule,
    FooterComponent,
    HeaderComponent,
    NavigationModule,
    LoginModule,
    MapModule,
    MenuModule,
    PhotoLoaderModule,
    RatingModule,
    RegistrationModule,
    ReservationCardModule,
    ReservationPickerModule,
    ResetPasswordModule,
    RestorationModule,
    ReviewsModule,
    SearchFilterModule,
    SearchFormModule,
    SearchResultModule,
    TimepickerModule,
    ToggleModule,
    WorkplaceTypesSelectModule
  ],
  providers: [
    UserService
  ]
})
export class SharedModule { }
