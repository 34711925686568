import { Component, OnInit } from '@angular/core';

import * as ApiModels from '../../../api';
import { UserService } from '../../../api';

@Component({
  selector: 'app-profile-user-info',
  templateUrl: './profile-user-info.component.html',
  styleUrls: ['./profile-user-info.component.scss']
})
export class ProfileUserInfoComponent implements OnInit {
  user: ApiModels.UserView;

  viewFormVisible: boolean;
  editFormVisible: boolean;

  constructor(
    private userService: UserService
  ) {
    this.viewFormVisible = true;
    this.editFormVisible = false;
  }

  ngOnInit() {
    this.getUserInfo();
  }

  onShowViewForm() {
    this.getUserInfo().then(res => {
      this.viewFormVisible = true;
      this.editFormVisible = false;
    });
  }

  onShowEditForm() {
    this.viewFormVisible = false;
    this.editFormVisible = true;
  }

  private getUserInfo() {
    return this.userService.get().toPromise().then(userInfo => {
      this.user = userInfo;
    });
  }

}
