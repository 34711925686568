import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { RatingModule } from '../rating';

import { ReviewsComponent } from './reviews.component';
import { ReviewCardAreaComponent } from './review-card-area/review-card-area.component';
import { ReviewCardUserComponent } from './review-card-user/review-card-user.component';
import { ResponseCardComponent } from './response-card/response-card.component';
import { ReviewModalComponent } from './review-modal/review-modal.component';

@NgModule({
  declarations: [
    ReviewsComponent,
    ReviewCardAreaComponent,
    ReviewCardUserComponent,
    ResponseCardComponent,
    ReviewModalComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    RatingModule,
    ReactiveFormsModule
  ],
  exports: [
    ReviewsComponent,
    ReviewCardAreaComponent,
    ReviewCardUserComponent,
    ResponseCardComponent,
    ReviewModalComponent
  ]
})
export class ReviewsModule { }
