import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

// import { NavigationService, ILink } from '../navigation/navigation.service';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit {

  items: any[];
  // items: ILink[];

  constructor(
    private router: Router,
    // private navigationService: NavigationService
  ) {}

  ngOnInit() {
    this.items = [
      'Главная',
      'Санкт-Петербург',
      'Результаты поиска'
    ];
    // this.navigationService.setTitle(this.router.url); // #HACK
    // this.items = this.navigationService.getBreadcrumbs(this.router.url); // #HACK
    // this.router.events
    //   .filter(e => e instanceof NavigationEnd)
    //   .subscribe((e: NavigationEnd) => {
    //     this.items = this.navigationService.getBreadcrumbs(e.url);
    //     this.navigationService.setTitle(e.url);
    //   });
  }

}
