import { Component, OnInit } from '@angular/core';

import { ReservationCardOptions } from '../shared/reservation-card';

import * as ApiModels from '../../api';
import { RentService } from '../../api';

declare const $: any;

@Component({
  selector: 'app-profile-user',
  templateUrl: './profile-user.component.html',
  styleUrls: ['./profile-user.component.scss']
})
export class ProfileUserComponent implements OnInit {

  cities = [
    'Москва',
    'Москва',
    'Москва',
    'Москва',
    'Москва',
    'Москва',
    'Москва',
    'Москва',
    'Москва'
  ];

  themes = [
    'Массаж',
    'Массаж',
    'Массаж',
    'Массаж',
    'Массаж',
    'Массаж',
    'Массаж',
    'Массаж',
    'Массаж',
    'Массаж',
    'Массаж',
    'Массаж',
    'Массаж',
    'Массаж',
    'Массаж',
    'Массаж',
    'Массаж'
  ];

  formats = [
    'Мастер-классы',
    'Мастер-классы',
    'Мастер-классы',
    'Мастер-классы',
    'Мастер-классы',
    'Мастер-классы',
    'Мастер-классы',
    'Мастер-классы',
    'Мастер-классы'
  ];

  workplaces = [
    'Место парикмахера',
    'Место парикмахера',
    'Место парикмахера',
    'Место парикмахера',
    'Место парикмахера',
    'Место парикмахера',
    'Место парикмахера',
    'Место парикмахера',
    'Место парикмахера',
    'Место парикмахера',
    'Место парикмахера',
    'Место парикмахера',
    'Место парикмахера',
    'Место парикмахера',
    'Место парикмахера',
    'Место парикмахера',
    'Место парикмахера',
    'Место парикмахера',
    'Место парикмахера',
    'Место парикмахера',
    'Место парикмахера',
    'Место парикмахера',
    'Место парикмахера',
    'Место парикмахера',
    'Место парикмахера',
    'Место парикмахера',
    'Место парикмахера',
    'Место парикмахера',
    'Место парикмахера',
    'Место парикмахера',
    'Место парикмахера',
    'Место парикмахера',
    'Место парикмахера',
    'Место парикмахера',
    'Место парикмахера',
    'Место парикмахера',
    'Место парикмахера',
    'Место парикмахера',
    'Место парикмахера',
    'Место парикмахера'
  ];

  reservations: ApiModels.RentCurrentWithWorkcenter[];
  reservationCardOptions: ReservationCardOptions;

  constructor(
    private rentService: RentService
  ) {
    const top = 5;
    this.rentService.currentWithCenter(top).toPromise().then(data => {
      this.reservations = data;
    });
  }

  ngOnInit() {
    this.reservationCardOptions = {
      mode: 'view',
      status: {
        visible: true
      },
      rules: {
        visible: true
      },
      actions: {
        message: {
          visible: false
        },
        footer: {
          visible: true
        }
      }
    };

    $('.ui.checkbox').checkbox();

    $('.ui.dropdown').dropdown();
  }

}
