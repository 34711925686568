import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { WorkCenterService } from '../../../api';

import { MapModule } from '../map';
import { RatingModule } from '../rating';

import { SearchResultCardComponent } from './search-result-card/search-result-card.component';
import { SearchResultComponent } from './search-result.component';
import { SearchResultForMapComponent } from './search-result-for-map/search-result-for-map.component';

@NgModule({
  declarations: [
    SearchResultCardComponent,
    SearchResultComponent,
    SearchResultForMapComponent
  ],
  imports: [
    CommonModule,
    MapModule,
    RatingModule,
    RouterModule
  ],
  exports: [
    SearchResultComponent,
    SearchResultCardComponent,
    SearchResultForMapComponent
  ],
  providers: [
    WorkCenterService
  ]
})
export class SearchResultModule { }
