import { Component, OnInit, Input, HostListener, Output, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';

declare const $: any;

@Component({
  selector: 'app-restoration-password',
  templateUrl: './restoration-password.component.html',
  styleUrls: ['./restoration-password.component.scss']
})
export class RestorationPasswordComponent implements OnInit {

  @Input() phoneNumber: string;
  @Input() secretCode: string;

  @Output() passwordChange: EventEmitter<boolean>;

  formBuilder: FormBuilder;
  formFields: FormGroup;
  formError: string;

  spinnerVisible: boolean;
  visiblePassword: boolean;

  passwordRulesHtml = `
    <ul style="list-style: inside;">
      <li>хотя бы одна цифра</li>
      <li>хотя бы одна буква в верхнем регистре</li>
      <li>длина пароля не меньше 6</li>
      <li>не меньше 6 уникальных символов</li>
    </ul>
  `;

  constructor(
    private http: HttpClient
  ) {
    this.passwordChange = new EventEmitter();
    this.spinnerVisible = false;
    this.visiblePassword = false;
    this.formBuilder = new FormBuilder();
    this.formFields = this.formBuilder.group({
      password:   new FormControl(null, [
        Validators.required,
        Validators.pattern(/^(?=^.{6,}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/)
        // Validators.pattern('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$')
      ]),
      confirmPassword: new FormControl(null, [Validators.required])
    }, {
      validator: this.checkPasswords
    });
  }

  ngOnInit() {
    setTimeout(() => {
      $('.form-field.form-field-password').popup();
    }, 0);
  }

  reset() {
    if (this.formFields.invalid) return;

    this.spinnerVisible = true;
    this.formError = undefined;

    this.http.post(
      '/api/Account/ResetPassword/NewPassword',
      JSON.stringify({
        phoneNumber: this.phoneNumber,
        secretCode: this.secretCode,
        password: this.formFields.get('password').value,
        confirmPassword: this.formFields.get('confirmPassword').value
      }),
      { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) }
    ).toPromise()
      .then((response: any) => {
        this.spinnerVisible = false;
        this.passwordChange.emit(true);
      })
      .catch((errorResponse: any) => {
        this.spinnerVisible = false;
        this.formError = errorResponse.error.errorMessage;
      });
  }

  changeVisiblePassword() {
    this.visiblePassword = !this.visiblePassword;
  }

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (event.keyCode === 13 && !this.spinnerVisible) this.reset();
  }

  private checkPasswords(group: FormGroup) {
    const passControl = group.controls.password;
    const confirmPassControl = group.controls.confirmPassword;
    return passControl.value === confirmPassControl.value ?
      confirmPassControl.setErrors(null) : confirmPassControl.setErrors({ notEquivalent: true });
  }
}
