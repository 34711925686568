import { Component, OnInit, Input, Output, EventEmitter, HostListener } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-restoration-code',
  templateUrl: './restoration-code.component.html',
  styleUrls: ['./restoration-code.component.scss']
})
export class RestorationCodeComponent implements OnInit {
  @Input() phoneNumber: string;
  @Output() secretCodeSet: EventEmitter<string>;

  formBuilder: FormBuilder;
  formFields: FormGroup;
  formError: string;

  spinnerVisible: boolean;

  constructor(
    private http: HttpClient
  ) {
    this.secretCodeSet = new EventEmitter();
    this.spinnerVisible = false;
  }

  ngOnInit() {
    this.formBuilder = new FormBuilder();
    this.formFields = this.formBuilder.group({
      number1: new FormControl(null, [Validators.required]),
      number2: new FormControl(null, [Validators.required]),
      number3: new FormControl(null, [Validators.required]),
      number4: new FormControl(null, [Validators.required])
    });
  }

  verifyCode() {
    if (this.formFields.invalid) return;

    this.spinnerVisible = true;
    this.formError = undefined;

    let code = '';
    code += this.formFields.get('number1').value.toString();
    code += this.formFields.get('number2').value.toString();
    code += this.formFields.get('number3').value.toString();
    code += this.formFields.get('number4').value.toString();

    this.http.post(
      '/api/Account/ResetPassword/VerifyCode',
      JSON.stringify({
        phoneNumber: this.phoneNumber,
        code: +code
      }),
      { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) }
    ).toPromise()
      .then((response: any) => {
        this.spinnerVisible = false;
        this.secretCodeSet.emit(response.secretCode);
      })
      .catch((errorResponse: any) => {
        this.spinnerVisible = false;
        this.formError = errorResponse.error ? errorResponse.error.errorMessage : errorResponse.errorMessage;
      });
  }

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (event.keyCode === 13) this.verifyCode();
  }
}
