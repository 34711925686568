import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import * as clone from 'clone';

import { PhotoInfo } from '../../shared/photo-loader';

import * as ApiModels from '../../../api';
import { AreaService, EquipmentsForWorkplaceTypeService, WorkplaceTypeService, PhotoService } from '../../../api';

import { Area } from '../area.model';

declare const $: any;

@Component({
  selector: 'app-area-upsert',
  templateUrl: './area-upsert.component.html',
  styleUrls: ['./area-upsert.component.scss']
})
export class AreaUpsertComponent implements OnInit {
  @Input() area: Area;
  @Input() equipments: ApiModels.Equipment[];
  @Input() mode: 'add' | 'edit';
  @Output() saved: EventEmitter<void>;

  organisationId: string;
  workCenterId: string;
  newAreaId: string;
  areaPhotos: ApiModels.PhotoInfo[];
  listPhotos: PhotoInfo[];

  formBuilder: FormBuilder;
  formFields: FormGroup;
  formError: string;

  workplaceTypes$: Observable<ApiModels.WorkplaceType[]>;

  gotoUrl: string;

  get equipmentsControls(): FormArray {
    return this.formFields.get('equipments') as FormArray;
  }

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private areaService: AreaService,
    private equipmentsForWorkplaceTypeService: EquipmentsForWorkplaceTypeService,
    private workplaceTypeService: WorkplaceTypeService,
    private http: HttpClient,
    private photoService: PhotoService
  ) {
    this.areaPhotos = [];
    this.listPhotos = [];
    this.mode = 'add';
    this.saved = new EventEmitter<void>();
  }

  ngOnInit() {
    if (this.mode === 'add') {
      this.organisationId = this.activatedRoute.snapshot.parent.parent.paramMap.get('id');
      this.workCenterId = this.activatedRoute.snapshot.parent.paramMap.get('id');
    }
    if (this.mode === 'edit') {
      this.areaPhotos = this.area.photos;
      this.listPhotos = this.area.photos;
      this.workCenterId = this.area.centerId;
    }

    this.formBuilder = new FormBuilder();
    this.formFields = this.formBuilder.group({
      workplaceTypeId: new FormControl(this.area ? this.area.workplaceTypeId : null, [Validators.required]),
      aboutText: new FormControl(this.area ? this.area.aboutText : null, [Validators.required]),
      timeForService: new FormControl(this.area ? this.area.timeForService : null),
      equipments: this.formBuilder.array(this.equipments ? this.equipments : [])
    });

    this.workplaceTypes$ = this.workplaceTypeService.get();

    setTimeout(() => {
      $('.ui.checkbox').checkbox();
      $('#workplaceTypeId').dropdown({
        onChange: (value, text, $choice) => {
          this.equipmentsForWorkplaceTypeService.getEquipments([value]).toPromise().then(response => {
            this.clearEquipmentsControls();
            if (response && response.length) {
              this.equipments = response[0].equipments;
              this.equipments.forEach(equipment => {
                this.addEquipmentControl(equipment.id);
              });
            }
          });
        }
      });
      $('.ui.modal.modal_workplace-adding').modal({
        onHidden: () => {
          this.router.navigateByUrl(
            this.gotoUrl ? this.gotoUrl : `/profile/organisation/${this.organisationId}/workcenter/${this.workCenterId}`
          );
        }
      });
    }, 0);
  }

  newEquipmentControl(id?: string): FormGroup {
    return this.formBuilder.group({
      include: false,
      id,
      description: '',
    });
  }

  addEquipmentControl(id: string) {
    this.equipmentsControls.push(this.newEquipmentControl(id));
  }

  removeEquipmentControl(i: number) {
    this.equipmentsControls.removeAt(i);
  }

  clearEquipmentsControls() {
    while (this.equipmentsControls.length !== 0) {
      this.equipmentsControls.removeAt(0);
    }
  }

  onChangeListPhotos(photos: PhotoInfo[]) {
    this.listPhotos = photos;
  }

  isChecked(equipmentId: string) {

  }

  onSave() {
    const formFields = clone(this.formFields.value);
    formFields.equipments = formFields.equipments.filter(equipment => equipment.include).map(equipment => {
      delete equipment.include;
      return equipment;
    });

    const newArea: ApiModels.AreaAdd = {
      centerId: this.workCenterId,
      ...formFields
    };

    const newPhotos = this.listPhotos.filter(photo => photo.base64);

    const promises: Promise<any>[] = [];

    if (this.mode === 'add') {
      this.areaService.createArea(newArea).toPromise().then(response => {
        this.newAreaId = response.id;

        if (newPhotos && newPhotos.length) {
          promises.push(this.uploadPhotos(newPhotos, this.newAreaId));
        }

        Promise.all(promises).then(() => {
          this.onShowWorkplaceAddingModal();
        });
      });
    }

    if (this.mode === 'edit') {
      const editArea: ApiModels.AreaEdit = {
        id: this.area.id,
        ...newArea
      };

      const removePhotos = this.areaPhotos.filter(oldPhoto => {
        const findPhoto = this.listPhotos.find(photo => photo.id === oldPhoto.id);
        if (findPhoto) {
          return false;
        }
        return true;
      });

      if (removePhotos && removePhotos.length) {
        promises.push(this.deletePhotos(removePhotos));
      }

      if (newPhotos && newPhotos.length) {
        promises.push(this.uploadPhotos(newPhotos, this.area.id));
      }

      promises.push(this.areaService.patchArea(editArea).toPromise());

      Promise.all(promises).then(() => {
        this.saved.emit();
      });
    }
  }

  onShowWorkplaceAddingModal() {
    $('.ui.modal.modal_workplace-adding').modal('show');
  }

  onHideWorkplaceAddingModal(url: string) {
    this.gotoUrl = url;
    $('.ui.modal.modal_workplace-adding').modal('hide');
  }

  private uploadPhotos(photos: PhotoInfo[], attachId: string): Promise<ApiModels.PhotosList> {
    const formData = new FormData();
    photos.forEach(photo => {
      formData.append('files', photo.file);
    });
    formData.append('attachId', attachId);

    // TODO Переписать на this.photoService.postUploadFiles
    return this.http.post(
      '/api/Photo/UploadFiles',
      formData
    ).toPromise();
  }

  private deletePhotos(photos: PhotoInfo[]): Promise<any[]> {
    return Promise.all(photos.map(photo => this.photoService.deleteByAttachId(photo.id).toPromise()));
  }
}
