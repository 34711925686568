import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AreaService, RentService, WorkCenterService } from '../../../api';

import { DatepickerModule } from '../datepicker';
import { ToggleModule } from '../toggle';

import { ReservationPickerBoardCardComponent } from './reservation-picker-board-card/reservation-picker-board-card.component';
import { ReservationPickerBoardComponent } from './reservation-picker-board/reservation-picker-board.component';
import { ReservationPickerComponent } from './reservation-picker.component';

@NgModule({
  declarations: [
    ReservationPickerBoardCardComponent,
    ReservationPickerBoardComponent,
    ReservationPickerComponent
  ],
  imports: [
    CommonModule,
    DatepickerModule,
    ToggleModule
  ],
  exports: [
    ReservationPickerBoardCardComponent,
    ReservationPickerBoardComponent,
    ReservationPickerComponent
  ],
  providers: [
    AreaService,
    RentService,
    WorkCenterService
  ]
})
export class ReservationPickerModule { }
