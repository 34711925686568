import { Component, OnInit, Input, OnChanges, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';

import * as ApiModels from '../../../api';

import { Utils } from '../../shared/utils';

import { WorkCenterForManager, WorkCenterStatus } from '../workcenter.model';

@Component({
  selector: 'app-workcenter-card',
  templateUrl: './workcenter-card.component.html',
  styleUrls: ['./workcenter-card.component.scss']
})
export class WorkCenterCardComponent implements OnInit, OnChanges {
  @Input() workCenter: WorkCenterForManager;
  @Output() showViewForm: EventEmitter<void>;

  schedulesDisplayValue: string[];

  basicStatuses: WorkCenterStatus[] = [
    {
      id: 0,
      name: 'Удален',
      color: '#BDBDBD',
    },
    {
      id: 1,
      name: 'Активный',
      color: '#0AB21B',
    },
    {
      id: 2,
      name: 'На модерации',
      color: '#41BBC9',
    },
    {
      id: 3,
      name: 'Отклонен',
      color: '#EB595E',
    },
    {
      id: 4,
      name: 'Изменен',
      color: '#FFDB72',
    },
    {
      id: 5,
      name: 'Не активный',
      color: '#BDBDBD',
    },
  ];
  currentStatus: WorkCenterStatus;

  constructor(
    private router: Router
  ) {
    this.showViewForm = new EventEmitter();
  }

  ngOnInit() {
  }

  ngOnChanges(changes: any) {
    if (changes.workCenter && this.workCenter) {
      this.currentStatus = this.basicStatuses.find((basicStatus) => basicStatus.id === this.workCenter.status);

      let briefText = this.workCenter.aboutText ? this.workCenter.aboutText.slice(0, 301) : '';
      briefText += (briefText.length < 301) ? '' : '...';
      this.workCenter.briefText = briefText;

      this.schedulesDisplayValue = Utils.getSchedulesDisplayValue(this.workCenter.schedules);
    }
  }

  onClickShowViewFormButton() {
    this.showViewForm.emit();
  }

  goToAreaUpsert() {
    this.router.navigateByUrl(`/profile/organisation/${this.workCenter.organisationId}/workcenter/${this.workCenter.id}/area/add`); // TODO
  }
}
