import { CarouselItem } from './custom-carousel.model';

export const advicesCarouselItems: CarouselItem[] = [
  {
    id: '1',
    imgSrc: '../../../assets/images/advice-type1.png',
    title: 'Как выбрать оборудованное место для аренды',
    description: `Требования к организации рабочего места определяются специальной
                  отраслью науки - эргономикой, одной из задач которой является разработка
                  рекомендаций по созданию оптимальных условий труда.`,
    link: '/',
  },
  {
    id: '2',
    imgSrc: '../../../assets/images/advice-type2.png',
    title: 'Как выбрать оборудованное место для аренды',
    description: `Требования к организации рабочего места определяются специальной
                  отраслью науки - эргономикой, одной из задач которой является разработка
                  рекомендаций по созданию оптимальных условий труда.`,
    link: '/',
  },
  {
    id: '3',
    imgSrc: '../../../assets/images/advice-type3.png',
    title: 'Как выбрать оборудованное место для аренды',
    description: `Требования к организации рабочего места определяются специальной
                  отраслью науки - эргономикой, одной из задач которой является разработка
                  рекомендаций по созданию оптимальных условий труда.`,
    link: '/',
  },
  {
    id: '4',
    imgSrc: '../../../assets/images/advice-type4.png',
    title: 'Как выбрать оборудованное место для аренды',
    description: `Требования к организации рабочего места определяются специальной
                  отраслью науки - эргономикой, одной из задач которой является разработка
                  рекомендаций по созданию оптимальных условий труда.`,
    link: '/',
  },
  {
    id: '5',
    imgSrc: '../../../assets/images/advice-type1.png',
    title: 'Как выбрать оборудованное место для аренды',
    description: `Требования к организации рабочего места определяются специальной
                  отраслью науки - эргономикой, одной из задач которой является разработка
                  рекомендаций по созданию оптимальных условий труда.`,
    link: '/',
  },
  {
    id: '6',
    imgSrc: '../../../assets/images/advice-type2.png',
    title: 'Как выбрать оборудованное место для аренды',
    description: `Требования к организации рабочего места определяются специальной
                  отраслью науки - эргономикой, одной из задач которой является разработка
                  рекомендаций по созданию оптимальных условий труда.`,
    link: '/',
  },
  {
    id: '7',
    imgSrc: '../../../assets/images/advice-type3.png',
    title: 'Как выбрать оборудованное место для аренды',
    description: `Требования к организации рабочего места определяются специальной
                  отраслью науки - эргономикой, одной из задач которой является разработка
                  рекомендаций по созданию оптимальных условий труда.`,
    link: '/',
  },
  {
    id: '8',
    imgSrc: '../../../assets/images/advice-type4.png',
    title: 'Как выбрать оборудованное место для аренды',
    description: `Требования к организации рабочего места определяются специальной
                  отраслью науки - эргономикой, одной из задач которой является разработка
                  рекомендаций по созданию оптимальных условий труда.`,
    link: '/',
  },
  {
    id: '9',
    imgSrc: '../../../assets/images/advice-type1.png',
    title: 'Как выбрать оборудованное место для аренды',
    description: `Требования к организации рабочего места определяются специальной
                  отраслью науки - эргономикой, одной из задач которой является разработка
                  рекомендаций по созданию оптимальных условий труда.`,
    link: '/',
  },
  {
    id: '10',
    imgSrc: '../../../assets/images/advice-type2.png',
    title: 'Как выбрать оборудованное место для аренды',
    description: `Требования к организации рабочего места определяются специальной
                  отраслью науки - эргономикой, одной из задач которой является разработка
                  рекомендаций по созданию оптимальных условий труда.`,
    link: '/',
  },
]

export const articlesCarouselItems: CarouselItem[] =  [
  {
    id: '1',
    imgSrc: '../../../assets/images/article-type1.png',
    title: 'Как увеличить прибыль салона красоты на 30% сдав места в почасовую аренду',
  },
  {
    id: '2',
    imgSrc: '../../../assets/images/article-type2.png',
    title: 'Как увеличить прибыль салона красоты на 30% сдав места в почасовую аренду',
  },
  {
    id: '3',
    imgSrc: '../../../assets/images/article-type3.png',
    title: 'Как увеличить прибыль салона красоты на 30% сдав места в почасовую аренду',
  },
  {
    id: '4',
    imgSrc: '../../../assets/images/article-type1.png',
    title: 'Как увеличить прибыль салона красоты на 30% сдав места в почасовую аренду',
  },
  {
    id: '5',
    imgSrc: '../../../assets/images/article-type2.png',
    title: 'Как увеличить прибыль салона красоты на 30% сдав места в почасовую аренду',
  },
  {
    id: '6',
    imgSrc: '../../../assets/images/article-type3.png',
    title: 'Как увеличить прибыль салона красоты на 30% сдав места в почасовую аренду',
  },
  {
    id: '7',
    imgSrc: '../../../assets/images/article-type1.png',
    title: 'Как увеличить прибыль салона красоты на 30% сдав места в почасовую аренду',
  },
  {
    id: '8',
    imgSrc: '../../../assets/images/article-type2.png',
    title: 'Как увеличить прибыль салона красоты на 30% сдав места в почасовую аренду',
  },
  {
    id: '9',
    imgSrc: '../../../assets/images/article-type3.png',
    title: 'Как увеличить прибыль салона красоты на 30% сдав места в почасовую аренду',
  },
]

export const filterCarouselItems: CarouselItem[] = [
  {
    id: '1',
    imgSrc: '../../../assets/images/workplace-type1.svg',
    title: 'Кафе',
    link: '/search',
    queryParams:  { workplaceTypeIds : '84ab1725-2be5-41a0-8710-c19bbd1c81cd'}
  },
  {
    id: '2',
    imgSrc: '../../../assets/images/workplace-type2.svg',
    title: 'Место визажиста',
    link: '/search',
    queryParams:  { workplaceTypeIds : '0b56e02b-fabe-44f0-8218-745918e234a0'}
  },
  {
    id: '3',
    imgSrc: '../../../assets/images/workplace-type3.svg',
    title: 'Салон красоты',
    link: '/search',
    queryParams:  { workplaceTypeIds : '2aa5902b-5a13-4a10-a2fb-fbc6388f4f42'}
  },
  {
    id: '4',
    imgSrc: '../../../assets/images/workplace-type4.svg',
    title: 'Кабинет',
    link: '/search',
    queryParams:  { workplaceTypeIds : 'bf6210ab-9b41-41d0-9894-d421bcbe68cf'}
  },
  {
    id: '5',
    imgSrc: '../../../assets/images/workplace-type5.svg',
    title: 'Коворкинг',
    link: '/search',
    queryParams:  { workplaceTypeIds : 'ef0d8cad-b1cb-49d7-bb37-7d47bcabbdd1'}
  },
  {
    id: '6',
    imgSrc: '../../../assets/images/workplace-type6.svg',
    title: 'Фото ателье',
    link: '/search',
    queryParams:  { workplaceTypeIds : '9161293c-57f8-4e9a-a50e-a69ea5ff8887'}
  },
  {
    id: '7',
    imgSrc: '../../../assets/images/workplace-type7.svg',
    title: 'Фитнес клуб',
    link: '/search',
    queryParams:  { workplaceTypeIds : '319f574e-bd47-4609-8467-5eda86e4e0b4'}
  },
  {
    id: '8',
    imgSrc: '../../../assets/images/workplace-type6.svg',
    title: 'Лекторий',
    link: '/search',
    queryParams:  { workplaceTypeIds : 'bafc3415-c4d5-482b-8a95-238b587fab99'}
  },
  {
    id: '9',
    imgSrc: '../../../assets/images/workplace-type6.svg',
    title: 'Мастерская для швей',
    link: '/search',
    queryParams:  { workplaceTypeIds : 'd9af4cd0-f587-42f8-a759-26f3289ebba'}
  },
  {
    id: '10',
    imgSrc: '../../../assets/images/workplace-type6.svg',
    title: 'Прилавок',
    link: '/search',
    queryParams:  { workplaceTypeIds : '20d53744-4130-49ae-8c0c-05bfbd2c7176'}
  }
];

export const masterClassesCarouselItems: CarouselItem[] = [
  {
    id: '1',
    imgSrc: '../../../assets/images/master-class-type1.png',
    title: '12 способов нанести перламутровую пудру',
    eventType: 'Мастер класс',
    date: '12-13 апреля 2020 года',
    description: `Проводит Алина Земина, школа маникюра и нейл-дизайна Vseya Rusi Nail Art,
                  учебные центры Luxio by Akzentz, Kodi, St Barth,
                  Christina Fitzgerald, La Ric, IBX `,
    link: '/',
  },
  {
    id: '2',
    imgSrc: '../../../assets/images/master-class-type2.png',
    title: '12 способов нанести перламутровую пудру',
    eventType: 'Мастер класс',
    date: '12-13 апреля 2020 года',
    description: `Проводит Алина Земина, школа маникюра и нейл-дизайна Vseya Rusi Nail Art,
                  учебные центры Luxio by Akzentz, Kodi, St Barth,
                  Christina Fitzgerald, La Ric, IBX `,
    link: '/',
  },
  {
    id: '3',
    imgSrc: '../../../assets/images/master-class-type3.png',
    title: '12 способов нанести перламутровую пудру',
    eventType: 'Мастер класс',
    date: '12-13 апреля 2020 года',
    description: `Проводит Алина Земина, школа маникюра и нейл-дизайна Vseya Rusi Nail Art,
                  учебные центры Luxio by Akzentz, Kodi, St Barth,
                  Christina Fitzgerald, La Ric, IBX `,
    link: '/',
  },
  {
    id: '4',
    imgSrc: '../../../assets/images/master-class-type1.png',
    title: '12 способов нанести перламутровую пудру',
    eventType: 'Мастер класс',
    date: '12-13 апреля 2020 года',
    description: `Проводит Алина Земина, школа маникюра и нейл-дизайна Vseya Rusi Nail Art,
                  учебные центры Luxio by Akzentz, Kodi, St Barth,
                  Christina Fitzgerald, La Ric, IBX `,
    link: '/',
  },
  {
    id: '5',
    imgSrc: '../../../assets/images/master-class-type2.png',
    title: '12 способов нанести перламутровую пудру',
    eventType: 'Мастер класс',
    date: '12-13 апреля 2020 года',
    description: `Проводит Алина Земина, школа маникюра и нейл-дизайна Vseya Rusi Nail Art,
                  учебные центры Luxio by Akzentz, Kodi, St Barth,
                  Christina Fitzgerald, La Ric, IBX `,
    link: '/',
  },
  {
    id: '6',
    imgSrc: '../../../assets/images/master-class-type3.png',
    title: '12 способов нанести перламутровую пудру',
    eventType: 'Мастер класс',
    date: '12-13 апреля 2020 года',
    description: `Проводит Алина Земина, школа маникюра и нейл-дизайна Vseya Rusi Nail Art,
                  учебные центры Luxio by Akzentz, Kodi, St Barth,
                  Christina Fitzgerald, La Ric, IBX `,
    link: '/',
  },
  {
    id: '7',
    imgSrc: '../../../assets/images/master-class-type1.png',
    title: '12 способов нанести перламутровую пудру',
    eventType: 'Мастер класс',
    date: '12-13 апреля 2020 года',
    description: `Проводит Алина Земина, школа маникюра и нейл-дизайна Vseya Rusi Nail Art,
                  учебные центры Luxio by Akzentz, Kodi, St Barth,
                  Christina Fitzgerald, La Ric, IBX `,
    link: '/',
  },
  {
    id: '8',
    imgSrc: '../../../assets/images/master-class-type2.png',
    title: '12 способов нанести перламутровую пудру',
    eventType: 'Мастер класс',
    date: '12-13 апреля 2020 года',
    description: `Проводит Алина Земина, школа маникюра и нейл-дизайна Vseya Rusi Nail Art,
                  учебные центры Luxio by Akzentz, Kodi, St Barth,
                  Christina Fitzgerald, La Ric, IBX `,
    link: '/',
  },
  {
    id: '9',
    imgSrc: '../../../assets/images/master-class-type3.png',
    title: '12 способов нанести перламутровую пудру',
    eventType: 'Мастер класс',
    date: '12-13 апреля 2020 года',
    description: `Проводит Алина Земина, школа маникюра и нейл-дизайна Vseya Rusi Nail Art,
                  учебные центры Luxio by Akzentz, Kodi, St Barth,
                  Christina Fitzgerald, La Ric, IBX `,
    link: '/',
  },
  {
    id: '10',
    imgSrc: '../../../assets/images/master-class-type1.png',
    title: '12 способов нанести перламутровую пудру',
    eventType: 'Мастер класс',
    date: '12-13 апреля 2020 года',
    description: `Проводит Алина Земина, школа маникюра и нейл-дизайна Vseya Rusi Nail Art,
                  учебные центры Luxio by Akzentz, Kodi, St Barth,
                  Christina Fitzgerald, La Ric, IBX `,
    link: '/',
  },
];

export const objectGalleryCarouselItems: string[] = [
  '65b31a3a-7c6a-4c27-bcda-4b8807500756',
  'a6e3dce2-2fc5-4caf-81fc-bc521acb11d9',
  '65b31a3a-7c6a-4c27-bcda-4b8807500756',
  'a6e3dce2-2fc5-4caf-81fc-bc521acb11d9',
  'a6e3dce2-2fc5-4caf-81fc-bc521acb11d9',
  '65b31a3a-7c6a-4c27-bcda-4b8807500756',
];

export const partnersClassesCarouselItems: CarouselItem[] = [
  {
    id: '1',
    date: '15 апреля 2020 года',
    title: '20 офисных рабочих мест',
    location: 'Москва',
    description: `Требуется 20 офисных рабочих мест для сотрудников компании
                  Лукойл. В районе третьего транспортного кольца.`,
    link: '/',
  },
  {
    id: '2',
    date: '15 апреля 2020 года',
    title: '20 офисных рабочих мест',
    location: 'Москва',
    description: `Требуется 20 офисных рабочих мест для сотрудников компании
                  Лукойл. В районе третьего транспортного кольца.`,
    link: '/',
  },
  {
    id: '3',
    date: '15 апреля 2020 года',
    title: '20 офисных рабочих мест',
    location: 'Москва',
    description: `Требуется 20 офисных рабочих мест для сотрудников компании
                  Лукойл. В районе третьего транспортного кольца.`,
    link: '/',
  },
  {
    id: '4',
    date: '15 апреля 2020 года',
    title: '20 офисных рабочих мест',
    location: 'Москва',
    description: `Требуется 20 офисных рабочих мест для сотрудников компании
                  Лукойл. В районе третьего транспортного кольца.`,
    link: '/',
  },
  {
    id: '5',
    date: '15 апреля 2020 года',
    title: '20 офисных рабочих мест',
    location: 'Москва',
    description: `Требуется 20 офисных рабочих мест для сотрудников компании
                  Лукойл. В районе третьего транспортного кольца.`,
    link: '/',
  },
  {
    id: '6',
    date: '15 апреля 2020 года',
    title: '20 офисных рабочих мест',
    location: 'Москва',
    description: `Требуется 20 офисных рабочих мест для сотрудников компании
                  Лукойл. В районе третьего транспортного кольца.`,
    link: '/',
  },
  {
    id: '7',
    date: '15 апреля 2020 года',
    title: '20 офисных рабочих мест',
    location: 'Москва',
    description: `Требуется 20 офисных рабочих мест для сотрудников компании
                  Лукойл. В районе третьего транспортного кольца.`,
    link: '/',
  },
  {
    id: '8',
    date: '15 апреля 2020 года',
    title: '20 офисных рабочих мест',
    location: 'Москва',
    description: `Требуется 20 офисных рабочих мест для сотрудников компании
                  Лукойл. В районе третьего транспортного кольца.`,
    link: '/',
  },
  {
    id: '9',
    date: '15 апреля 2020 года',
    title: '20 офисных рабочих мест',
    location: 'Москва',
    description: `Требуется 20 офисных рабочих мест для сотрудников компании
                  Лукойл. В районе третьего транспортного кольца.`,
    link: '/',
  },
  {
    id: '10',
    date: '15 апреля 2020 года',
    title: '20 офисных рабочих мест',
    location: 'Москва',
    description: `Требуется 20 офисных рабочих мест для сотрудников компании
                  Лукойл. В районе третьего транспортного кольца.`,
    link: '/',
  },
];

export const recommendationsCarouselItems: CarouselItem[] = [
  {
    id: '1',
    imgSrc: '../../../assets/images/recommendation-type1.png',
    title: 'Переговорная',
    price: '1 000 ₽ в час',
    address: 'Москва, Электрозаводская ул., 21',
    nearestMetro: 'Электрозаводская, 500 м',
    description: `Светлый лофт с большими окнами, высоким потолком,
                  фактурными кирпичными стенами и зеркалами,
                  что позволяет проводить различные виды занятий!`,
    link: '/',
  },
  {
    id: '2',
    imgSrc: '../../../assets/images/recommendation-type2.png',
    title: 'Переговорная',
    price: '1 000 ₽ в час',
    address: 'Москва, Электрозаводская ул., 21',
    nearestMetro: 'Электрозаводская, 500 м',
    description: `Светлый лофт с большими окнами, высоким потолком,
                  фактурными кирпичными стенами и зеркалами,
                  что позволяет проводить различные виды занятий!`,
    link: '/',
  },
  {
    id: '3',
    imgSrc: '../../../assets/images/recommendation-type3.png',
    title: 'Переговорная',
    price: '1 000 ₽ в час',
    address: 'Москва, Электрозаводская ул., 21',
    nearestMetro: 'Электрозаводская, 500 м',
    description: `Светлый лофт с большими окнами, высоким потолком,
                  фактурными кирпичными стенами и зеркалами,
                  что позволяет проводить различные виды занятий!`,
    link: '/',
  },
  {
    id: '4',
    imgSrc: '../../../assets/images/recommendation-type4.png',
    title: 'Переговорная',
    price: '1 000 ₽ в час',
    address: 'Москва, Электрозаводская ул., 21',
    nearestMetro: 'Электрозаводская, 500 м',
    description: `Светлый лофт с большими окнами, высоким потолком,
                  фактурными кирпичными стенами и зеркалами,
                  что позволяет проводить различные виды занятий!`,
    link: '/',
  },
  {
    id: '5',
    imgSrc: '../../../assets/images/recommendation-type1.png',
    title: 'Переговорная',
    price: '1 000 ₽ в час',
    address: 'Москва, Электрозаводская ул., 21',
    nearestMetro: 'Электрозаводская, 500 м',
    description: `Светлый лофт с большими окнами, высоким потолком,
                  фактурными кирпичными стенами и зеркалами,
                  что позволяет проводить различные виды занятий!`,
    link: '/',
  },
  {
    id: '6',
    imgSrc: '../../../assets/images/recommendation-type2.png',
    title: 'Переговорная',
    price: '1 000 ₽ в час',
    address: 'Москва, Электрозаводская ул., 21',
    nearestMetro: 'Электрозаводская, 500 м',
    description: `Светлый лофт с большими окнами, высоким потолком,
                  фактурными кирпичными стенами и зеркалами,
                  что позволяет проводить различные виды занятий!`,
    link: '/',
  },
  {
    id: '7',
    imgSrc: '../../../assets/images/recommendation-type3.png',
    title: 'Переговорная',
    price: '1 000 ₽ в час',
    address: 'Москва, Электрозаводская ул., 21',
    nearestMetro: 'Электрозаводская, 500 м',
    description: `Светлый лофт с большими окнами, высоким потолком,
                  фактурными кирпичными стенами и зеркалами,
                  что позволяет проводить различные виды занятий!`,
    link: '/',
  },
  {
    id: '8',
    imgSrc: '../../../assets/images/recommendation-type4.png',
    title: 'Переговорная',
    price: '1 000 ₽ в час',
    address: 'Москва, Электрозаводская ул., 21',
    nearestMetro: 'Электрозаводская, 500 м',
    description: `Светлый лофт с большими окнами, высоким потолком,
                  фактурными кирпичными стенами и зеркалами,
                  что позволяет проводить различные виды занятий!`,
    link: '/',
  },
  {
    id: '9',
    imgSrc: '../../../assets/images/recommendation-type1.png',
    title: 'Переговорная',
    price: '1 000 ₽ в час',
    address: 'Москва, Электрозаводская ул., 21',
    nearestMetro: 'Электрозаводская, 500 м',
    description: `Светлый лофт с большими окнами, высоким потолком,
                  фактурными кирпичными стенами и зеркалами,
                  что позволяет проводить различные виды занятий!`,
    link: '/',
  },
  {
    id: '10',
    imgSrc: '../../../assets/images/recommendation-type2.png',
    title: 'Переговорная',
    price: '1 000 ₽ в час',
    address: 'Москва, Электрозаводская ул., 21',
    nearestMetro: 'Электрозаводская, 500 м',
    description: `Светлый лофт с большими окнами, высоким потолком,
                  фактурными кирпичными стенами и зеркалами,
                  что позволяет проводить различные виды занятий!`,
    link: '/',
  },
];


