import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { formatDate } from '@angular/common';
import { BreakpointObserver } from '@angular/cdk/layout';

declare const $: any;

@Component({
  selector: 'app-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.scss']
})
export class DatepickerComponent implements OnInit, OnChanges {
  @Input() value: Date;
  @Input() setMinDate: boolean;
  @Output() changeValue: EventEmitter<Date>;

  popupVisible: boolean;
  displayValue: string;

  today: Date;
  weekDay: string;
  day: string;
  month: string;
  year: string;

  constructor(
    public breakpointObserver: BreakpointObserver
  ) {
    this.changeValue = new EventEmitter<Date>();
    this.setMinDate = true;
  }

  ngOnInit() {
    this.popupVisible = false;

    this.setToday();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.value) {
      if (changes.value.currentValue) {
        this.setValue(changes.value.currentValue);
      } else {
        this.clearValue();
      }
    }
  }

  popupOpen() {
    if (this.popupVisible) {
      return;
    }

    this.popupVisible = true;
    setTimeout(() => {
      $('#inline_calendar_left').calendar({
        type: 'date',
        firstDayOfWeek: 1,
        minDate: this.setMinDate ? new Date() : undefined,
        text: {
          days: ['вс', 'пн', 'вт', 'ср', 'чт', 'пт', 'сб'],
          months: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
          monthsShort: ['Янв', 'Фев', 'Мар', 'Апр', 'Май', 'Июн', 'Июл', 'Авг', 'Сен', 'Окт', 'Ноя', 'Дек'],
          today: 'Сегодня',
          now: 'Сейчас'
        },
        onSelect: (date: Date, mode) => {
          this.setValue(date);
        }
      });

      if (this.value) {
        $('#inline_calendar_left').calendar('set date', this.value, false, false);
      }
    }, 0);


  }

  popupClose() {
    this.popupVisible = false;

    this.changeValue.emit(this.value);
  }

  clearValue() {
    $('#inline_calendar_left').calendar('clear');
    this.value = undefined;
    this.displayValue = undefined;
  }

  private setToday() {
    this.today = new Date();
    this.weekDay = formatDate(this.today, 'EEEE', 'ru');
    this.day = formatDate(this.today, 'dd', 'ru');
    this.month = formatDate(this.today, 'MMMM', 'ru');
    this.year = formatDate(this.today, 'yyyy', 'ru');
  }

  private setValue(value: Date) {
    this.value = new Date(value.getFullYear(), value.getMonth(), value.getDate());
    this.displayValue = formatDate(this.value, 'dd.MM.yyyy', 'ru');
  }
}
