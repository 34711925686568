import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FeedbackFormModule } from '../shared/feedback-form';

import { ContactsComponent } from './contacts.component';

@NgModule({
  declarations: [ContactsComponent],
  imports: [
    CommonModule,
    FeedbackFormModule,
  ],
  exports: [
    ContactsComponent
  ],
})
export class ContactsModule { }
