import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import * as ApiModels from '../../../../api';

declare const $: any;

@Component({
  selector: 'app-profile-user-info-view',
  templateUrl: './profile-user-info-view.component.html',
  styleUrls: ['./profile-user-info-view.component.scss']
})
export class ProfileUserInfoViewComponent implements OnInit {
  @Input() user: ApiModels.UserView;
  @Output() showEditForm: EventEmitter<void>;

  constructor() {
    this.showEditForm = new EventEmitter<void>();
  }

  ngOnInit() {
  }

  onResetPassword() {
    $('.ui.modal.reset-password-modal').modal('show');
  }

  onShowEditForm() {
    this.showEditForm.emit();
  }

}
