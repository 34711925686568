import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { filter, map, mergeMap } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  navigationItems: any[];

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title
  ) { }

  ngOnInit() {
    this.overrideDate(); // HACK for timezone

    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.activatedRoute),
        map((route) => {
          while (route.firstChild) route = route.firstChild;
          return route;
        }),
        filter((route) => route.outlet === 'primary'),
        mergeMap((route) => route.data)
      ).subscribe((event) => this.titleService.setTitle(event.title));
  }

  private overrideDate() {
    Date.prototype.toISOString = function() {
      const fullYear = this.getFullYear();
      const month = this.getMonth() + 1;
      const date = this.getDate();
      const hours = this.getHours();
      const minutes = this.getMinutes();
      const seconds = this.getSeconds();

      return fullYear +
        '-' + (month < 10 ? ('0' + month) : month) +
        '-' + (date < 10 ? ('0' + date) : date) +
        'T' + (hours < 10 ? ('0' + hours) : hours) +
        ':' + (minutes < 10 ? ('0' + minutes) : minutes) +
        ':' + (seconds < 10 ? ('0' + seconds) : seconds);
    };
  }
}
