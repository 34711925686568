import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { MenuModule } from '../shared/menu';

import { ProfileUserInfoComponent } from '../profile-user';
import { ProfileOrganisationInfoComponent } from '../profile-organisation';

import { ProfileLayoutComponent } from './profile-layout.component';

@NgModule({
  declarations: [ProfileLayoutComponent],
  imports: [
    CommonModule,
    MenuModule,
    ProfileUserInfoComponent,
    ProfileOrganisationInfoComponent,
    RouterModule
  ]
})
export class ProfileLayoutModule { }
