import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';

import { OrganisationService, WorkCenterService } from '../../api';

import { Menu } from '../shared/menu';

declare const $: any;

@Component({
  selector: 'app-profile-layout',
  templateUrl: './profile-layout.component.html',
  styleUrls: ['./profile-layout.component.scss']
})
export class ProfileLayoutComponent implements OnInit, OnDestroy {

  isUserProfilePage: boolean;
  isOrganisationProfilePage: boolean;

  organisationId: string;

  routerSubscription: Subscription;

  menuItems: Menu[];
  userMenuItems: Menu[];
  organisationMenuItems: Menu[];

  constructor(
    private router: Router,
    private organisationService: OrganisationService,
    private workCenterService: WorkCenterService
  ) { }

  ngOnInit() {
    this.organisationService.getOrganisationByUserId().toPromise().then(organisationResponse => {
      if (organisationResponse[0]) {
        this.organisationId = organisationResponse[0].id;

        this.organisationMenuItems = [{
          caption: 'Профиль',
          link: `/profile/organisation/${this.organisationId}`
        }];
        this.workCenterService.getWorkCenterByOrganisationId(this.organisationId).toPromise().then(workCenterResponse => {
          const organisationWorkCenterSubMenu: Menu[] = [];
          const organisationReservationsSubMenu: Menu[] = [];
          organisationWorkCenterSubMenu.push({
            caption: 'Добавление объекта',
            link: `/profile/organisation/${this.organisationId}/workcenter/add`
          });
          workCenterResponse.forEach(workCenter => {
            organisationWorkCenterSubMenu.push({
              caption: workCenter.name,
              link: `/profile/organisation/${this.organisationId}/workcenter/${workCenter.id}`
            });
            organisationReservationsSubMenu.push({
              caption: workCenter.name,
              link: `/profile/organisation/${this.organisationId}/workcenter/${workCenter.id}/reservations`
            });
          });

          this.organisationMenuItems.push({
            caption: 'Объекты и рабочие места',
            items: organisationWorkCenterSubMenu
          }, {
            caption: 'Бронирования',
            items: organisationReservationsSubMenu
          });

          this.setMenu();
        });
      }
    });

    this.isUserProfilePage = this.router.url.indexOf('/profile/user') !== -1;
    this.isOrganisationProfilePage = this.router.url.indexOf('/profile/organisation') !== -1;
    this.routerSubscription = this.router.events.subscribe(e => {
      if (e instanceof NavigationEnd) {
        this.isUserProfilePage = e.url.indexOf('/profile/user') !== -1;
        this.isOrganisationProfilePage = e.url.indexOf('/profile/organisation') !== -1;
        this.setMenu();
      }
    });

    this.userMenuItems = [{
      caption: 'Профиль',
      link: '/profile/user'
    }, {
      caption: 'Мои бронирования',
      link: '/profile/user/my-reservations'
    }, {
      caption: 'Отзывы',
      link: '/profile/user/my-reviews'
    }];

    this.setMenu();
  }

  ngOnDestroy() {
    this.routerSubscription.unsubscribe();
  }

  private setMenu() {
    if (this.isUserProfilePage) {
      this.menuItems = this.userMenuItems;
    }

    if (this.isOrganisationProfilePage) {
      this.menuItems = this.organisationMenuItems;
    }
  }
}
