import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { PhotoLoaderModule } from '../shared/photo-loader';
import { CustomCarouselModule } from '../shared/custom-carousel';

import { AreaService, EquipmentService, EquipmentsForWorkplaceTypeService, WorkplaceService, WorkplaceTypeService } from '../../api';

import { TariffModule } from '../tariff';

import { AreaComponent } from './area.component';
import { AreaCardComponent } from './area-card/area-card.component';
import { AreaUpsertComponent } from './area-upsert/area-upsert.component';
import { AreaViewComponent } from './area-view/area-view.component';
import { WorkplaceAddingComponent } from './workplace-adding/workplace-adding.component';

@NgModule({
  declarations: [
    AreaComponent,
    AreaCardComponent,
    AreaUpsertComponent,
    AreaViewComponent,
    WorkplaceAddingComponent
  ],
  imports: [
    CommonModule,
    CustomCarouselModule,
    FormsModule,
    PhotoLoaderModule,
    ReactiveFormsModule,
    RouterModule,
    TariffModule
  ],
  exports: [
    AreaComponent,
    AreaCardComponent,
    AreaUpsertComponent
  ],
  providers: [
    AreaService,
    EquipmentService,
    EquipmentsForWorkplaceTypeService,
    WorkplaceService,
    WorkplaceTypeService
  ]
})
export class AreaModule { }
