import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { MenuModule } from '../shared/menu';
import { ReservationCardModule } from '../shared/reservation-card';
import { RestorationModule } from '../shared/restoration';
import { ToggleModule } from '../shared/toggle';

import { OrganisationTypeService } from '../../api';

import { ProfileOrganisationComponent } from './profile-organisation.component';
import { ProfileOrganisationInfoComponent } from './profile-organisation-info/profile-organisation-info.component';
import { ProfileOrganisationInfoViewComponent } from './profile-organisation-info/profile-organisation-info-view/profile-organisation-info-view.component';
import { ProfileOrganisationInfoEditComponent } from './profile-organisation-info/profile-organisation-info-edit/profile-organisation-info-edit.component';

@NgModule({
  declarations: [
    ProfileOrganisationComponent,
    ProfileOrganisationInfoComponent,
    ProfileOrganisationInfoViewComponent,
    ProfileOrganisationInfoEditComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    MenuModule,
    ReactiveFormsModule,
    ReservationCardModule,
    RestorationModule,
    RouterModule,
    ToggleModule
  ],
  exports: [
    ProfileOrganisationInfoComponent
  ],
  providers: [
    OrganisationTypeService
  ]
})
export class ProfileOrganisationModule { }
