import { Component, OnInit, Input } from '@angular/core';

import * as ApiModels from '../../../../api';

import { ReviewStatus } from './review-card-area.model';

@Component({
  selector: 'app-review-card-area',
  templateUrl: './review-card-area.component.html',
  styleUrls: ['./review-card-area.component.scss']
})
export class ReviewCardAreaComponent implements OnInit {
  @Input() item: ApiModels.ReviewInWorkCenter;

  currentStatus: ReviewStatus;
  reviewStatuses: ReviewStatus[] = [{
    id: 1,
    name: 'Опубликован',
    description: 'Ваш отзыв опубликован',
    color: '#0AB21B'
  }, {
    id: 2,
    name: 'На рассмотрении',
    description: 'Ваш отзыв отправлен и будет рассмотрен модератором в течение 5 дней',
    color: '#41BBC9'
  }, {
    id: 3,
    name: 'Не опубликован',
    description: 'Ваш отзыв нарушает п. 4.4 правил сервиса. Пожалуйста, отредактируйте Ваш отзыв и опубликуйте снова.',
    color: '#EB595E'
  }];

  constructor() { }

  ngOnInit() {
    this.currentStatus = this.reviewStatuses.find(reviewStatus => reviewStatus.id === this.item.status);
  }
}
