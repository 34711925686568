import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { formatDate } from '@angular/common';

import * as ApiModels from '../../api';
import { AreaService, ScheduleService } from '../../api';

import { TariffGroupedByDate, TariffGroupedByType, TariffGroupedByDateAndType } from './tariff.model';

@Component({
  selector: 'app-tariff',
  templateUrl: './tariff.component.html',
  styleUrls: ['./tariff.component.scss']
})
export class TariffComponent implements OnInit, OnChanges {
  @Input() areaId: string;
  @Input() tariffs: ApiModels.TariffView[];
  @Input() schedules: ApiModels.ScheduleInfo[];

  tariffsGroupedByDateAndType: TariffGroupedByDateAndType[];
  tariffsForEditing: TariffGroupedByDateAndType;

  viewFormVisible: boolean;
  addingFormVisible: boolean;
  editingFormVisible: boolean;

  constructor(
    private areaService: AreaService,
    private scheduleService: ScheduleService
  ) { }

  ngOnInit() {
    this.onShowViewForm();
  }

  ngOnChanges(changes: SimpleChanges) {
    // if (changes.areaId) {
    //   if (this.areaId) {
    //     this.getSchedules();

    //     this.getTariffs();

    //     // this.areaService.getAreaByIdExtended(this.areaId).toPromise().then(response => {
    //     //   this.schedules = response.schedules ? response.schedules : [];
    //     //   this.tariffs = response.tariffs ? response.tariffs : [];
    //     //   this.tariffsGroupedByDateAndType = this.getTariffsGroupedByDateAndType(this.tariffs);
    //     // });
    //   }
    // }

    if (changes.tariffs && this.tariffs) {
      this.tariffsGroupedByDateAndType = this.getTariffsGroupedByDateAndType(this.tariffs);
    }
  }

  onShowViewForm() {
    this.getTariffs().then(() => {
      this.viewFormVisible = true;
      this.addingFormVisible = false;
      this.editingFormVisible = false;
    });
  }

  onShowAddingForm() {
    this.viewFormVisible = false;
    this.addingFormVisible = true;
    this.editingFormVisible = false;
  }

  onShowEditingForm(tariffs: TariffGroupedByDateAndType) {
    this.tariffsForEditing = tariffs;
    this.viewFormVisible = false;
    this.addingFormVisible = false;
    this.editingFormVisible = true;
  }

  private getSchedules() {
    this.scheduleService.getScheduleByAreaId(this.areaId).toPromise().then(response => {
      this.schedules = response;
    });
  }

  private getTariffs() {
    return this.areaService.getAreaWithWorkplaceNumbersandTariffsByAreaIds([this.areaId]).toPromise().then(response => {
      this.tariffs = response[0].tariffs ? response[0].tariffs : [];
      this.tariffsGroupedByDateAndType = this.getTariffsGroupedByDateAndType(this.tariffs);
    });
  }

  private getTariffsGroupedByDateAndType(tariffs: ApiModels.TariffInfo[]): TariffGroupedByDateAndType[] {
    const tariffsGroupedByDateAndType: TariffGroupedByDateAndType[] = [];

    this.getTariffsGroupedByDate(tariffs).forEach(tariffGroupedByDate => {
      this.getTariffsGroupedByType(tariffGroupedByDate.tariffs).forEach(tariffGroupedByType => {
        tariffsGroupedByDateAndType.push({
          dateStart: tariffGroupedByDate.dateStart,
          dateStartValue: tariffGroupedByDate.dateStartValue,
          dateEnd: tariffGroupedByDate.dateEnd,
          dateEndValue: tariffGroupedByDate.dateEndValue,
          tariffType: tariffGroupedByType.tariffType,
          tariffs: tariffGroupedByType.tariffs
        });
      });
    });

    return tariffsGroupedByDateAndType;
  }

  private getTariffsGroupedByDate(tariffs: ApiModels.TariffInfo[]): TariffGroupedByDate[] {
    const tariffsGroupedByDate = [];

    let tariffsGrouped = tariffs.reduce((groups, tariff) => {
      const dateStart = formatDate(new Date(tariff.dateStart), 'dd.MM.yyyy', 'ru');
      if (!groups[dateStart]) {
        groups[dateStart] = [];
      }
      groups[dateStart].push(tariff);
      return groups;
    }, {});

    const tariffsGroupedByDateStart: TariffGroupedByDate[] = Object.keys(tariffsGrouped).map((dateStart) => {
      const tariff = tariffsGrouped[dateStart][0] as ApiModels.TariffView;
      const dateEnd = formatDate(new Date(tariff.dateEnd), 'dd.MM.yyyy', 'ru');

      return {
        dateStart,
        dateStartValue: tariff.dateStart,
        dateEnd,
        dateEndValue: tariff.dateEnd,
        tariffs: tariffsGrouped[dateStart]
      };
    });

    tariffsGroupedByDateStart.forEach(groupByDate => {
      tariffsGrouped = groupByDate.tariffs.reduce((groups, tariff) => {
        const dateEnd = formatDate(new Date(tariff.dateEnd), 'dd.MM.yyyy', 'ru');
        if (!groups[dateEnd]) {
          groups[dateEnd] = [];
        }
        groups[dateEnd].push(tariff);
        return groups;
      }, {});

      const tariffsGroupedByDateEnd: TariffGroupedByDate[] = Object.keys(tariffsGrouped).map((dateEnd) => {
        const tariff = tariffsGrouped[dateEnd][0] as ApiModels.TariffView;
        const dateStart = formatDate(new Date(tariff.dateStart), 'dd.MM.yyyy', 'ru');

        return {
          dateStart,
          dateStartValue: tariff.dateStart,
          dateEnd,
          dateEndValue: tariff.dateEnd,
          tariffs: tariffsGrouped[dateEnd]
        };
      });

      tariffsGroupedByDate.push(...tariffsGroupedByDateEnd);
    });

    return tariffsGroupedByDate;
  }

  private getTariffsGroupedByType(tariffs: ApiModels.TariffInfo[]): TariffGroupedByType[] {
    const tariffsGrouped = tariffs.reduce((groups, tariff) => {
      const tariffType = tariff.tariffType;
      if (!groups[tariffType]) {
        groups[tariffType] = [];
      }
      groups[tariffType].push(tariff);
      return groups;
    }, {});

    const tariffsGroupedByType: TariffGroupedByType[] = Object.keys(tariffsGrouped).map((tariffType) => {
      return {
        tariffType: +tariffType,
        tariffs: tariffsGrouped[tariffType]
      };
    });

    return tariffsGroupedByType;
  }

}
