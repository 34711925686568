import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { BreakpointObserver } from '@angular/cdk/layout';

import * as clone from 'clone';

import * as ApiModels from '../../../api';
import { CityService, EquipmentsForWorkplaceTypeService } from '../../../api';

import { WorkplaceType } from '../workplace-types-select/workplace-types.model';

declare const $: any;

@Component({
  selector: 'app-search-filter',
  templateUrl: './search-filter.component.html',
  styleUrls: ['./search-filter.component.scss']
})
export class SearchFilterComponent implements AfterViewInit, OnChanges, OnInit {
  @Input() id: string;
  @Input() searchFormVisible: boolean;
  @Input() locationSectionVisible: boolean;
  @Input() workplaceTypes: WorkplaceType[];
  @Input() selectedFilters: ApiModels.SearchParamsForWC;
  @Output() сhangeFilters: EventEmitter<ApiModels.SearchParamsForWC>;
  @Output() changeTempFilters: EventEmitter<ApiModels.SearchParamsForWC>;
  @Output() changeWorkplaceTypes: EventEmitter<WorkplaceType[]>;

  selectedWorkplaceTypeIds: string[];
  date: Date;
  times: number[] = [];
  timeFrom: number;
  timeTo: number;
  name: string;
  tariffType: number;
  city: string;
  radiusStart: number;
  radiusEnd: number;
  ratingStart: number;
  ratingEnd: number;

  location: string;
  cities: ApiModels.CityItem[];

  form: any;
  defaultFilters: ApiModels.SearchParamsForWC;

  formBuilder: FormBuilder;
  formFields: FormGroup;

  get workplaceTypesControls(): FormArray {
    return this.formFields.get('workplaceTypes') as FormArray;
  }

  equipmentsControls(i): FormArray {
    return this.workplaceTypesControls.at(i).get('equipments') as FormArray;
  }

  constructor(
    public breakpointObserver: BreakpointObserver,
    private activatedRoute: ActivatedRoute,
    private cityService: CityService,
    private equipmentsForWorkplaceTypeService: EquipmentsForWorkplaceTypeService
  ) {
    this.сhangeFilters = new EventEmitter();
    this.changeTempFilters = new EventEmitter();
    this.changeWorkplaceTypes = new EventEmitter();
    this.selectedFilters = {};
  }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(params => {
      if (params.workplaceTypeIds instanceof Array) {
        this.selectedWorkplaceTypeIds = params.workplaceTypeIds;
      } else if (typeof(params.workplaceTypeIds) === 'string') {
        this.selectedWorkplaceTypeIds = [params.workplaceTypeIds];
      } else {
        this.selectedWorkplaceTypeIds = [];
      }
      this.workplaceTypes = this.selectedWorkplaceTypeIds.map(id => {
        return { id };
      });

      this.date = params.date ? new Date(params.date) : null;
      this.timeFrom = params.timeFrom ? +params.timeFrom : null;
      this.timeTo = params.timeTo ? +params.timeTo : null;

      this.tariffType = params.tariffType;
      this.name = params.name;
      this.city = params.city || this.location;
      this.radiusStart = params.radiusStart;
      this.radiusEnd = params.radiusEnd;
      this.ratingStart = params.ratingStart;
      this.ratingEnd = params.ratingEnd;

      this.initWorkplaceTypeAccordion();

      this.defaultFilters = {
        name: '',
        tariffType: 0,
        cityId: this.cities ? this.cities[0].id : undefined,
        radius: 0,
        ratingFrom: 1,
        ratingTo: 10
      };

      this.formBuilder = new FormBuilder();
      this.formFields = this.formBuilder.group({
        date: new FormControl(this.date ? this.date : null),
        timeFrom: new FormControl(this.timeFrom ? this.timeFrom : null),
        timeTo: new FormControl(this.timeTo ? this.timeTo : null),
        name: new FormControl(this.defaultFilters ? this.defaultFilters.name : null),
        ratingFrom: new FormControl(this.defaultFilters ? this.defaultFilters.ratingFrom : null),
        ratingTo: new FormControl(this.defaultFilters ? this.defaultFilters.ratingTo : null),
        cityId: new FormControl(this.defaultFilters ? this.defaultFilters.cityId : null),
        radius: new FormControl(this.defaultFilters ? this.defaultFilters.radius : null),
        longFrom: new FormControl(this.defaultFilters ? this.defaultFilters.longFrom : null),
        longTo: new FormControl(this.defaultFilters ? this.defaultFilters.longTo : null),
        latFrom: new FormControl(this.defaultFilters ? this.defaultFilters.latFrom : null),
        latTo: new FormControl(this.defaultFilters ? this.defaultFilters.latTo : null),
        workplaceTypes: this.formBuilder.array(this.defaultFilters && this.defaultFilters.workplaceTypes ? this.defaultFilters.workplaceTypes : []),
        sortBy: new FormControl(this.defaultFilters ? this.defaultFilters.sortBy : null),
        startFrom: new FormControl(this.defaultFilters ? this.defaultFilters.startFrom : null),
        top: new FormControl(this.defaultFilters ? this.defaultFilters.top : null),
        tariffType: new FormControl(this.defaultFilters ? this.defaultFilters.tariffType : 0)
      });

      this.getEquipments();
      this.initFormAndControls();
    });

    this.cityService.get().toPromise().then(cites => {
      this.cities = cites;
      this.defaultFilters.cityId = this.cities[0].id;
      this.formFields.get('cityId').setValue(this.defaultFilters.cityId);
      $(`.ui.dropdown.city`).dropdown('set selected', this.defaultFilters.cityId); // TODO переделать на Angular Forms
      this.applyFilters();
    });
  }

  ngAfterViewInit() {

  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.selectedFilters) {
      if (this.selectedFilters && this.selectedFilters.workplaceTypes) {
        this.updateFilters();
      }
    }

    if (changes.workplaceTypes) {
      if (this.workplaceTypes) {
        this.buildFilters();
        this.getEquipments();
        this.updateEquipmentsCheckbox();
      }
    }
  }

  onChangeWorkplaceTypes(workplaceTypes: WorkplaceType[]) {
    this.workplaceTypes = workplaceTypes;
    this.selectedWorkplaceTypeIds = workplaceTypes.map(workplaceType => workplaceType.id);
    this.getEquipments();
    this.initWorkplaceTypeAccordion();
    this.initEquipmentsCheckbox();
    this.buildFilters();
    this.changeWorkplaceTypes.emit(this.workplaceTypes);
  }

  changeDate(newDate: Date) {
    this.date = newDate;
    this.buildFilters();
  }

  changeTimes(newTime: number[]) {
    this.timeFrom = newTime[0];
    this.timeTo = newTime[1];
    this.buildFilters();
  }

  newWorkplaceTypeControl(workplaceType?: WorkplaceType): FormGroup {
    return this.formBuilder.group({
      id: workplaceType.id,
      name: workplaceType.name,
      priceFrom: 0,
      priceTo: 10000, // TODO: get max price
      equipments: this.formBuilder.array(workplaceType.equipments ? workplaceType.equipments : [])
    });
  }

  addWorkplaceTypeControl(workplaceType: WorkplaceType) {
    this.workplaceTypesControls.push(this.newWorkplaceTypeControl(workplaceType));
  }

  clearWorkplaceTypesControls() {
    while (this.workplaceTypesControls.length !== 0) {
      this.workplaceTypesControls.removeAt(0);
    }
  }

  newEquipmentControl(equipment?: ApiModels.Equipment): FormGroup {
    return this.formBuilder.group({
      include: false,
      id: equipment.id,
      name: equipment.name
    });
  }

  addEquipmentControl(i: number, equipment: ApiModels.Equipment) {
    this.equipmentsControls(i).push(this.newEquipmentControl(equipment));
  }

  removeEquipmentControl(i: number, j: number) {
    this.equipmentsControls(i).removeAt(j);
  }

  clearEquipmentsControls(i: number) {
    while (this.equipmentsControls(i).length !== 0) {
      this.equipmentsControls(i).removeAt(0);
    }
  }

  updateFilters() {
    if (this.formFields) {
      this.formFields.patchValue(this.selectedFilters);

      $(`#${this.id} .ui.dropdown.city`).dropdown('set selected', this.selectedFilters.cityId); // TODO переделать на Angular Forms

      this.updateSliders(); // TODO переделать на Angular Forms
      this.updateEquipmentsCheckbox(); // TODO
    }
  }

  applyFilters() {
    this.buildFilters();
    this.сhangeFilters.emit(this.selectedFilters);
  }

  updateTempFilters() {
    this.buildFilters();
    this.changeTempFilters.emit(this.selectedFilters);
  }

  setDefaultFilters() {
    this.formFields.patchValue(this.defaultFilters);

    $(`#${this.id} .ui.dropdown.city`).dropdown('set selected', this.defaultFilters.cityId); // TODO переделать на Angular Forms

    this.initRadiusSlider();
    this.initRatingSlider();

    this.initEquipmentsCheckbox();
    this.updateWorkplaceTypesPriceSliders();
    this.workplaceTypesControls.controls.forEach(workplaceTypeControl => {
      workplaceTypeControl.get('priceFrom').setValue(0);
      workplaceTypeControl.get('priceTo').setValue(10000);

      const equipmentsControls = (workplaceTypeControl.get('equipments') as FormArray).controls;
      if (equipmentsControls && equipmentsControls.length) {
        equipmentsControls.forEach(equipmentControl => {
          equipmentControl.get('include').setValue(false);
        });
      }
    });
  }

  // TODO Скорее всего не нужно
  updateWorkplaceTypeForSelectedFilters(event: any, workplaceTypeId: string, equipmentId: string) {
    // const isChecked = event.target.checked;
    // const workplaceTypes = this.selectedFilters.workplaceTypes;
    // const updateWorkplaceType = workplaceTypes.find(workplaceType => workplaceType.id === workplaceTypeId);
    // if (updateWorkplaceType) {
    //   if (isChecked) {
    //     if (!updateWorkplaceType.equipment) {
    //       updateWorkplaceType.equipment = [equipmentId];
    //     } else if (!updateWorkplaceType.equipment.includes(equipmentId)) {
    //       updateWorkplaceType.equipment.push(equipmentId);
    //     }
    //   } else {
    //     if (updateWorkplaceType.equipment) {
    //       const index = updateWorkplaceType.equipment.indexOf(equipmentId);
    //       if (index > -1) {
    //         updateWorkplaceType.equipment.splice(index, 1);
    //       }
    //     }
    //   }
    // }
    // this.selectedFilters.workplaceTypes = workplaceTypes;
    // this.changeTempFilters.emit(this.selectedFilters);
  }

  isChecked(workplaceTypeId: string, equipmentId: string) {
    // let isChecked = false;
    // const findWorkplaceType = this.selectedFilters.workplaceTypes.find(workplaceType => workplaceType.id === workplaceTypeId);
    // if (findWorkplaceType && findWorkplaceType.equipment) {
    //   const findEquipmentIndex = findWorkplaceType.equipment.findIndex(equipment => equipment === equipmentId);
    //   isChecked = findEquipmentIndex !== -1;
    // }

    // return isChecked;
  }

  private initRadiusSlider() {
    setTimeout(() => {
      $(`#${this.id} .ui.slider.slider-radius`).slider({
        min: 0,
        max: 50,
        start: this.defaultFilters.radius,
        step: 1,
        onMove: (value: number, start: number) => {
          this.formFields.get('radius').setValue(start);
        }
      });
    }, 0);
  }

  private initRatingSlider() {
    setTimeout(() => {
      $(`#${this.id} .ui.range.slider.slider-rating`).slider({
        min: 1,
        max: 10,
        start: this.defaultFilters.ratingFrom,
        end: this.defaultFilters.ratingTo,
        step: 1,
        labelDistance: 1,
        onMove: (value: number, start: number, end: number) => {
          this.formFields.get('ratingFrom').setValue(start);
          this.formFields.get('ratingTo').setValue(end);
          // this.updateTempFilters();
        }
      });
    }, 100);
  }

  private initWorkplaceTypeAccordion() {
    setTimeout(() => {
      $(`#${this.id} .ui.fluid.accordion.workplace-type`).accordion({
        selector: {
          trigger: '.title'
        }
      });
    }, 0);

  }

  private initEquipmentsCheckbox() {
    setTimeout(() => {
      $(`#${this.id} .ui.checkbox`).checkbox();
    }, 0);
  }

  private updateEquipmentsCheckbox() {
    this.selectedFilters.workplaceTypes.forEach(workplaceType => {
      const findWorkplaceTypeControl = this.workplaceTypesControls.controls.find(workplaceTypeControl => workplaceTypeControl.value.id === workplaceType.id);
      if (findWorkplaceTypeControl) {
        const equipmentsControls = (findWorkplaceTypeControl.get('equipments') as FormArray).controls;
        if (equipmentsControls && equipmentsControls.length) {
          equipmentsControls.forEach(equipmentControl => equipmentControl.get('include').setValue(false));

          if (!workplaceType.equipments || !workplaceType.equipments.length) {
            return;
          }

          workplaceType.equipments.forEach(equipment => {
            const findEquipmentControl = equipmentsControls.find(equipmentControl => {
              return equipmentControl.value.id === equipment;
            });
            if (findEquipmentControl) {
              findEquipmentControl.get('include').setValue(true);
            }
          });
        }
      }
    });
  }

  private initFormAndControls() {
    this.initRadiusSlider();
    this.initRatingSlider();

    $(`#${this.id} .ui.checkbox`).checkbox();
    $(`#${this.id} .ui.dropdown`).dropdown();
  }

  private getEquipments() {
    if (this.workplaceTypes && this.formFields) {
      const workplaceTypeIds = this.workplaceTypes.map(workplaceType => workplaceType.id);
      this.equipmentsForWorkplaceTypeService.getEquipments(workplaceTypeIds).toPromise().then(equipmentGroups => {
        this.clearWorkplaceTypesControls();
        this.workplaceTypes.forEach((workplaceType, index) => {
          this.addWorkplaceTypeControl(workplaceType);

          if (equipmentGroups && equipmentGroups.length) {
            const findEquipmentGroup = equipmentGroups.find(equipmentGroup => {
              return equipmentGroup.workplaceTypeId === workplaceType.id;
            });

            workplaceType.equipments = findEquipmentGroup ? findEquipmentGroup.equipments : [];
            this.clearEquipmentsControls(index);
            workplaceType.equipments.forEach(equipment => {
              this.addEquipmentControl(index, equipment);
            });
          }
        });

        setTimeout(() => {
          this.updateWorkplaceTypesPriceSliders();

          // TODO
          // this.setEquipmentCheckboxes();
          // this.updateWorkplaceTypeForSelectedFilters();
        }, 0);
      });
    }
  }

  private buildFilters() {
    const formValues: any = clone(this.formFields.value);

    formValues.workplaceTypes.map(workplaceType => {
      workplaceType.equipments = workplaceType.equipments
        .filter(equipment => equipment.include)
        .map(equipment => equipment.id);

      return workplaceType;
    });

    this.selectedFilters = {
      ...this.selectedFilters,
      ...{
        workplaceTypes: this.workplaceTypes,
        date: this.date || undefined,
        timeFrom: this.timeFrom || undefined,
        timeTo: this.timeTo || undefined,
      },
      ...formValues
    };
  }

  private updateSliders() {
    $(`#${this.id} .ui.range.slider.slider-rating`).slider(
      'set rangeValue',
      this.selectedFilters.ratingFrom ? this.selectedFilters.ratingFrom : this.defaultFilters.ratingFrom,
      this.selectedFilters.ratingTo ? this.selectedFilters.ratingTo : this.defaultFilters.ratingTo
    );

    this.workplaceTypes.forEach((workplaceType) => {
      const findWorkplaceTypeOfSelectedFilters = this.selectedFilters.workplaceTypes.find(item => item.id === workplaceType.id);
      if (findWorkplaceTypeOfSelectedFilters) {
        $(`#${this.id} .ui.range.slider.slider-price#${ workplaceType.id }`).slider(
          'set rangeValue',
          findWorkplaceTypeOfSelectedFilters.priceFrom ? findWorkplaceTypeOfSelectedFilters.priceFrom : 0,
          findWorkplaceTypeOfSelectedFilters.priceTo ? findWorkplaceTypeOfSelectedFilters.priceTo : 10000 // TODO: get max price
        );
      }
    });
  }

  private updateWorkplaceTypesPriceSliders() {
    const workplaceTypes = this.workplaceTypes;

    if (!workplaceTypes) {
      return;
    }

    workplaceTypes.forEach((workplaceType) => {
      $(`#${this.id} .ui.range.slider.slider-price#${ workplaceType.id }`).slider({
        min: 0,
        max: 10000, // TODO: get max price
        start: 0, // workplaceType.priceFrom || 0,
        end: 10000, // workplaceType.priceTo || 10000,  // TODO: get max price
        step: 1,
        onMove: (value: number, start: number, end: number) => {
          const workplaceTypeControl = this.workplaceTypesControls.controls
            .find(workplaceTypesControl => workplaceTypesControl.value.id === workplaceType.id);

          workplaceTypeControl.get('priceFrom').setValue(start);
          workplaceTypeControl.get('priceTo').setValue(end);
          // this.updateTempFilters();
        }
      });
    });
  }
}
