import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TimepickerComponent } from './timepicker.component';



@NgModule({
  declarations: [TimepickerComponent],
  imports: [
    CommonModule
  ],
  exports: [
    TimepickerComponent
  ]
})
export class TimepickerModule { }
