import { Component, OnInit, Input } from '@angular/core';

import * as ApiModels from '../../../api';

@Component({
  selector: 'app-reviews',
  templateUrl: './reviews.component.html',
  styleUrls: ['./reviews.component.scss']
})
export class ReviewsComponent implements OnInit {
  @Input() type: 'area' | 'user';
  @Input() items: ApiModels.ReviewInWorkCenter[];
  @Input() width: number | string;

  constructor() { }

  ngOnInit() {
    if (!this.width) {
      this.width = '100%';
    }
  }

}
